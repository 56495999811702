import React from 'react'

import Head from '../layout/head/Head'

const Dashboard = () => {
    return (
        <React.Fragment>
            <Head title="QR" />
        </React.Fragment>
    )
}

export default Dashboard