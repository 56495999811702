import * as React from "react";
const AlertDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    x={0}
    y={0}
    viewBox="0 0 267 267"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    fillRule="evenodd"
    className=""
    {...props}
  >
    <circle r={133.5} cx={133.5} cy={133.5} fill="#ffd699" shape="circle" />
    <g transform="matrix(0.7,0,0,0.7,40.00004653930665,40.00011005401612)">
      <path
        fill="#ef6d22"
        d="m108.033 25.063-82.97 82.97a35.78 35.78 0 0 0 0 50.601l82.97 82.97a35.78 35.78 0 0 0 50.601 0l82.97-82.97a35.78 35.78 0 0 0 0-50.601l-82.97-82.97a35.781 35.781 0 0 0-50.601 0z"
        opacity={1}
        data-original="#ef6d22"
        className=""
      />
      <path
        fill="#faa629"
        d="m127.655 44.686-82.969 82.969a8.03 8.03 0 0 0 0 11.356l82.969 82.97a8.03 8.03 0 0 0 11.356 0l82.97-82.97a8.03 8.03 0 0 0 0-11.356l-82.97-82.969a8.03 8.03 0 0 0-11.356 0z"
        opacity={1}
        data-original="#faa629"
        className=""
      />
      <path
        fill="#fff5e1"
        d="M133.333 161.95c-5.491 0-9.95 4.459-9.95 9.951 0 5.491 4.459 9.95 9.95 9.95 5.492 0 9.951-4.459 9.951-9.95 0-5.492-4.459-9.951-9.951-9.951zm-12.5-68.801.003.106 2.555 50.48c.032 5.459 4.475 9.882 9.942 9.882 5.477 0 9.925-4.437 9.943-9.91 0 .028 2.555-50.452 2.555-50.452l.002-.106c0-6.899-5.601-12.5-12.5-12.5s-12.5 5.601-12.5 12.5z"
        opacity={1}
        data-original="#fff5e1"
      />
    </g>
  </svg>
);
export default AlertDelete;
