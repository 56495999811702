import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { toPng, toSvg } from "html-to-image";
import { QRCode } from "@jackybaby/react-custom-qrcode";
import { SketchPicker } from "react-color";
import { Block, Icon, PreviewCard, BlockHead, BlockBetween, BlockHeadContent, Button } from "../components/Component";
import { useQuill } from "react-quilljs";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Tags from "@yaireo/tagify/dist/react.tagify";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  UncontrolledAlert,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
// import { ButtonGroup } from "reactstrap";
import classnames from "classnames";
import "./css/Qrcode.css";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import Classic from "./QrImages/Classic.svg";
import Rounded from "./QrImages/Rounded.svg";
import Smooth from "./QrImages/Smooth.svg";
import Thin from "./QrImages/Thin.svg";
import Circles from "./QrImages/Circles.svg";
import Nouislider from "nouislider-react";
import wifiimage from "./logoImages/wifi-circle.svg";
import gmailimage from "./logoImages/gmail.svg";
import appstoreimage from "./logoImages/appstore.svg";
import CloseButton from "./logoImages/CloseButton.svg";
import { formatFileSize, getCookie } from "../utils/Utils";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import animationData from "../images/lottie/qranimationloader.json";
import uploadanimationfile from "../images/lottie/uploadanimation.json";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import Dropzone from "react-dropzone";
import FileUploads from "../images/FileUploads";
import PDFImage from "../images/PDFImage";
import { SubscriptionContext } from "./SubscriptionContext";
import UpgradePackage from "../images/package_type/UpgradePackage";
import FourteenDaysCountDown from "./hooks/FourteenDaysCountDown";
import Skeleton from "react-loading-skeleton";
import AlertDelete from "../images/package_type/AlertDelete";
import appscreesnshot from "../images/appScreenshot.png";
import SubscriptionEnds from "../images/package_type/SubscriptionEnds";
import useAuth from "./hooks/useAuth";
import ImageContainer from "../components/partials/gallery/GalleryImage";
import Cookies from "js-cookie";
const QrCodePage = () => {
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [modal, setModalTop] = useState(false);
  const [modalcust, setModalTopcust] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [selectedFormat, setSelectedFormat] = useState("svg");
  // const [file, setFile] = useState(null);
  const isAuthenticated = useAuth();
  const [isLogin, setIsLogin] = useState(isAuthenticated);
  const [subscriptionModal, setSubscriptionModal] = useState(true);
  console.log("Is User Login" + isLogin);
  const [loading, setLoading] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("classic");
  const [color1, setColor1] = useState("#ffffff");
  const [showPicker1, setShowPicker1] = useState(false);
  const [color2, setColor2] = useState("#000000");
  const [showPicker2, setShowPicker2] = useState(false);
  const [color3, setColor3] = useState("#000000");
  const [showPicker3, setShowPicker3] = useState(false);
  const [color4, setColor4] = useState("#000000");
  const [showPicker4, setShowPicker4] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState("closeButton");
  const [margin, setMargin] = useState(172);
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [networkType, setNetworkType] = useState("WPA");
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("bottom");
  const [body, setBody] = useState("");
  const [selectedSize, setSelectedSize] = useState("14px");
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [showCcField, setShowCcField] = useState(false);
  const [showBccField, setShowBccField] = useState(false);
  const [passState, setPassState] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeIconTabText, setActiveIconTabText] = useState("URL");
  const [codeloading, setCodeloading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState({
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
    12: "",
    13: "",
    14: "",
  });
  useEffect(() => {
    setIsLogin(isAuthenticated);
    let subsmodal = Cookies.get("isModalOpen");
    if (subsmodal) {
      setSubscriptionModal(false);
    }
  }, [isAuthenticated]);
  // const modaltoggle = () => setModalTop(!modal);
  const modaltoggle = () => {
    if (!isLogin) {
      toast.error("Please login to copy code.");
      return;
    } else {
      setModalTop(!modal);
    }
  };
  const customizemodel = () => setModalTopcust(!modalcust);
  const downloadContainerRef = useRef(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  // ************** changed this code to change qr type in file type ****************************
  const toggleIconTab = (icontab, text) => {
    if (activeIconTab !== icontab) {
      setActiveIconTab(icontab);
      setActiveIconTabText(text);
    }
  };

  const generateURLcode = async (event) => {
    const value = event.target.value;
    setQrCodeData((prevState) => ({ ...prevState, 6: value }));
    console.log("URL QR:", value);
    if (!validateUrl(value)) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("url", value);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/short-url-qr`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok && data.status) {
        setUrl(process.env.REACT_APP_PUBLIC_URL + "website/" + data.data.shortened_url);
        setQrCodeData((prevState) => ({
          ...prevState,
          6: process.env.REACT_APP_PUBLIC_URL + "website/" + data.data.shortened_url,
        }));
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const validateUrl = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!urlPattern.test(value)) {
      setUrlError("Invalid URL. Please enter a valid URL.");
      return false;
    } else {
      setUrlError("");
      return true;
    }
  };

  const modules = {
    toolbar: [
      ["bold", "underline", "strike", "italic"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ align: [] }],
      ["clean"],
    ],
    // maxlength: { maxLength: 50 },
  };

  const formats = [
    "bold",
    "underline",
    "italic",
    "strike",
    "blockquote",
    "code-block",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "color",
    "background",
    "script",
  ];
  const { quill, quillRef } = useQuill({ modules, formats });
  useEffect(() => {
    if (quill) {
      quill.on("text-change", handleUrlChange);
    }
    return () => {
      if (quill) {
        quill.off("text-change", handleUrlChange);
      }
    };
  }, [quill]);

  let toastTimeout;

  const handleUrlChange = (event) => {
    let newValue;
    const MAX_LENGTH = 50;

    if (quill) {
      const text = quill.getText();
      newValue = text.trim();
    } else {
      newValue = event.target.value;
    }

    const textLength = quill.getText().length;

    if (textLength > MAX_LENGTH) {
      quill.deleteText(MAX_LENGTH, textLength - MAX_LENGTH);

      // Debounce the toast error
      if (toastTimeout) {
        clearTimeout(toastTimeout);
      }

      toastTimeout = setTimeout(() => {
        toast.error("Only 50 characters allowed");
      }, 300); // Adjust the debounce delay as needed
    }

    setUrl(newValue);
    setQrCodeData((prevState) => ({
      ...prevState,
      5: newValue,
    }));
  };

  useEffect(() => {
    if (activeTab === "5") {
      quillRef.current.focus();
    }
  }, [activeTab]);

  const [iframeCode, setIframeCode] = useState("");
  const [codes, setGeneratedCode] = useState("");
  useEffect(() => {
    const generateIframeCode = async () => {
      const container = downloadContainerRef.current;
      if (!container) {
        console.error("Container not found");
        return;
      }
      setCodeloading(true);
      const pngDataUrlPromise = toPng(container);
      try {
        const pngDataUrl = await pngDataUrlPromise;
        const code = `<iframe 
        title="QR Code" 
        src="${pngDataUrl}" 
        width="300" 
        height="300" 
        frameBorder="0" 
        scrolling="no">
        <iframe>`;
        setIframeCode(code);
        setGeneratedCode(pngDataUrl);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
      setCodeloading(false);
    };

    const debouncedGenerateIframeCode = debounce(generateIframeCode, 500);

    if (modal) {
      debouncedGenerateIframeCode();
    }

    return () => {
      debouncedGenerateIframeCode.cancel();
    };
  }, [modal, downloadContainerRef, url]);
  const [buttonText, setButtonText] = useState("Copy");
  const [copyicon, setCopyIcon] = useState("copy");

  const copyIframeCode = () => {
    if (!isLogin) {
      toast.error("Please log in to copy the iframe code.");
      return;
    } else {
      navigator.clipboard
        .writeText(iframeCode)
        .then(() => {
          setButtonText("Copied");
          setCopyIcon("check-thick");
          setTimeout(() => {
            setButtonText("Copy");
            setCopyIcon("copy");
          }, 2000);
        })
        .catch((err) => {
          console.error("Failed to copy iframe code: ", err);
        });
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };
  const [activeButton, setActiveButton] = useState("html");
  const changeCode = (language) => {
    setActiveButton(language);
    let newIframeCode = "";
    switch (language) {
      case "react":
        newIframeCode = `
import React from 'react';
  const QRCodeComponent = ({ qrImageSrc }) => {
      return (
      <iframe
        title="QR Code"
        srcDoc={&lt;img src="${codes}" alt="qr_image" style="width: 100%; height: 100%;" />}
        width="300"
        height="300"
        frameBorder="0"
        scrolling="no"
      ></iframe>
      );
    };
    export default QRCodeComponent;`;
        break;
      case "angular":
        newIframeCode = `
import base64
from io import BytesIO
from PIL import Image
base64_string = ${codes}
decoded_data = base64.b64decode(base64_string)
image_data = BytesIO(decoded_data)
image = Image.open(image_data)
image.show()`;
        break;
      case "php":
        newIframeCode = `
<?php
    $iframeCode = '
    <iframe
    title="QR Code"
    srcdoc=\'<img src="'.${codes}.'" alt="qr_image" style="width: 100%; height: 100%;" />\'
    width="300"
    height="300"
    frameborder="0"
    scrolling="no"
    ></iframe>';
    echo $iframeCode;
    ?>
    `;
        break;
      default:
        newIframeCode = `
        <iframe
            title="QR Code"
            srcDoc='<img src="${codes}" alt="qr_image" style="width: 100%; height: 100%;" />'
            width="300"
            height="300"
            frameBorder="0"
            scrolling="no"
        ></iframe>`;
    }
    setIframeCode(newIframeCode);
  };
  const buttonClasses = (language) => {
    return `${activeButton === language ? "shadow" : ""}`;
  };

  const fetchProfileAPI = async (formData, route, qrcode_data) => {
    formData.append("qrcode_data", JSON.stringify(qrcode_data));
    let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/${route}`, {
      method: "POST",
      cache: "no-store",
      body: formData,
      headers: {
        authToken: getCookie("authToken", null),
      },
    });
    return await res.json();
  };

  const handleSave = async () => {
    const container = downloadContainerRef.current;
    if (!container) {
      console.error("Container not found");
      return;
    }
    try {
      let formData = new FormData();
      const pngDataUrl = await toPng(container);
      formData.append("base64_data", pngDataUrl);

      const qrcode_data = {
        title: "Dummy QR Code",
        description: "This is a dummy QR code data.",
        type: "test",
        value: "12345",
        metadata: {
          created_at: "2024-01-30T12:00:00Z",
          author: "John Doe",
        },
      };
      formData.append("qrcode_data", JSON.stringify(qrcode_data));
      const response = await fetchProfileAPI(formData, "qr-code/add-qr", qrcode_data);
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error saving QR code data:", error);
    }
  };

  const handleDownload = async () => {
    const container = downloadContainerRef.current;
    if (!container) {
      console.error("Container not found");
      return;
    }
    try {
      let formData = new FormData();
      const pngDataUrl = await toPng(container);
      formData.append("base64_data", pngDataUrl);
      if (selectedFormat === "svg") {
        const svgDataUrl = await toSvg(container);
        saveAs(svgDataUrl, "Image.svg");
      } else if (selectedFormat === "png") {
        const pngDataUrl = await toPng(container);
        saveAs(pngDataUrl, "Image.png");
      }
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  const handleShare = async () => {
    const container = downloadContainerRef.current;

    if (!container) {
      console.error("Container not found");
      return;
    }
    if (!isLogin) {
      toast.error("Please log in to share the image.");
      return;
    } else {
      try {
        const pngDataUrl = await toPng(container);
        if (
          navigator.canShare &&
          navigator.canShare({
            files: [new File([], "dummy")],
          })
        ) {
          const blob = await dataURItoBlob(pngDataUrl);
          const file = new File([blob], "image.png", { type: "image/png" });
          await navigator.share({
            title: "Share Image",
            text: "Check out this image!",
            files: [file],
          });
        } else {
          console.warn("Sharing image directly not supported. Sharing link instead.");
          const imageUrl = pngDataUrl;
          await navigator.share({
            title: "Share Image",
            text: "Check out this image! (Link may require saving the image)",
            url: imageUrl,
          });
        }
      } catch (error) {
        console.error("Error sharing:", error.message);
      }
    }
  };

  //product
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [buyLink, setBuyLink] = useState("");

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: mimeString });
  };

  const handleCopyPngToClipboard = async () => {
    const container = downloadContainerRef.current;
    if (!container) {
      console.error("Container not found");
      return;
    }
    if (!isLogin) {
      toast.error("Please login to use this feature");
      return;
    } else {
      try {
        const pngDataUrl = await toPng(container);
        const blob = dataURItoBlob(pngDataUrl);
        navigator.clipboard.write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ]);
        setCopied(true);
        toast.success("Copied to clipboard");
        console.log("PNG image copied to clipboard");
      } catch (error) {
        console.error("Error copying PNG image to clipboard:", error);
      }
    }
  };

  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [copied]);

  const handleInput = useCallback(() => {
    const firstnameInput = document.getElementById("firstnamecontact")?.value || "";
    const lastnameInput = document.getElementById("lastnamecontact")?.value || "";
    const phoneInput = document.getElementById("phonecontact")?.value || "";
    const companyInput = document.getElementById("companycontact")?.value || "";
    const mailInput = document.getElementById("contactmail")?.value || "";
    const addresscontact = document.getElementById("addresscontact")?.value || "";
    const countryInput = document.getElementById("countrycontact")?.value || "";
    const websiteInput = document.getElementById("websitecontact")?.value || "";

    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:${lastnameInput};${firstnameInput};;;
FN:${firstnameInput} ${lastnameInput}
ORG:${companyInput}
TEL:${phoneInput}
EMAIL:${mailInput}
ADR:${addresscontact}
CTRY:${countryInput}
URL:${websiteInput}
END:VCARD`;
    setUrl(vCardData);
    setQrCodeData((prevState) => ({
      ...prevState,
      7: vCardData,
    }));
  }, [setQrCodeData]);
  useEffect(() => {
    const inputs = document.querySelectorAll("#tab-7 input[type=text], #tab-7 input[type=number]");
    inputs.forEach((input) => {
      input.addEventListener("input", handleInput);
    });
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handleInput);
      });
    };
  }, [handleInput]);

  useEffect(() => {
    if (address) {
      const mapsUrl = "https://www.google.com/maps?q=" + encodeURIComponent(address);
      setQrCodeData((prevState) => ({
        ...prevState,
        8: mapsUrl,
      }));
      setUrl(mapsUrl);
    } else {
      if ((latitude && !longitude) || (!latitude && longitude)) {
      } else if (latitude && longitude) {
        const mapsUrl = "https://www.google.com/maps?q=" + encodeURIComponent(`${latitude},${longitude}`);
        setQrCodeData((prevState) => ({
          ...prevState,
          8: mapsUrl,
        }));
        setUrl(mapsUrl);
      } else {
      }
    }
  }, [address, latitude, longitude]);

  const handleAddressChange = (event) => {
    const value = event.target.value;
    setAddress(value);
    setQrCodeData((prevState) => ({
      ...prevState,
      8: value,
    }));
  };

  const handleLatitudeChange = (event) => {
    const value = event.target.value;
    setLatitude(value);
    setQrCodeData((prevState) => ({
      ...prevState,
      8: { ...(prevState["8"] || {}), latitude: value },
    }));
  };

  const handleLongitudeChange = (event) => {
    const value = event.target.value;
    setLongitude(value);
    setQrCodeData((prevState) => ({
      ...prevState,
      8: { ...(prevState["8"] || {}), longitude: value },
    }));
  };

  const handleNetworkTypeChange = (event) => {
    setNetworkType(event.target.value);
    generateWiFiQRCode(event);
  };
  const generateWiFiQRCode = (event) => {
    const { id, value } = event.target;

    setQrCodeData((prevState) => {
      const prevWifiData = prevState["9"] || {};
      return {
        ...prevState,
        9: {
          ...prevWifiData,
          [id]: value,
        },
      };
    });
    const ssidInput = document.getElementById("ssidwifi").value;
    const networkTypeInput = document.getElementById("networktypewifi").value;
    const passwordInput = document.getElementById("passwordwifi").value;

    const wifiQRCodeData = `WIFI:T:${networkTypeInput};S:${ssidInput};P:${passwordInput};;`;
    setQrCodeData((prevState) => ({
      ...prevState,
      9: wifiQRCodeData,
    }));
    setUrl(wifiQRCodeData);
  };

  const generateAppQRCode = (event) => {
    const { value } = event.target;
    setQrCodeData((prevState) => ({
      ...prevState,
      10: value,
    }));
    const appStoreIdInput = document.getElementById("appstoreid")?.value;
    if (appStoreIdInput) {
      const appStoreUrl = `https://apps.apple.com/app/id${appStoreIdInput}`;
      setQrCodeData((prevState) => ({
        ...prevState,
        10: appStoreUrl,
      }));
      setUrl(appStoreUrl);
    }
  };
  const [emailValue, setEmailValue] = useState("");
  const [tagifyOptions, setTagifyOptions] = useState([]);
  const tagifySettings = {
    blacklist: ["xxx", "yyy", "zzz"],
    maxTags: 6,
    backspace: "edit",
    addTagOnBlur: false,
    placeholder: "",
    dropdown: {
      enabled: 0,
    },
  };
  const onTagifyChange = (e) => {
    if (e.detail.value !== "") {
      let arrayVal = JSON.parse(e.detail.value);
      setTagifyOptions(arrayVal);
    } else {
      setTagifyOptions([""]);
    }
  };
  const settings = {
    ...tagifySettings,
    addTagOnBlur: true,
  };
  const generateEmailQRCode = () => {
    const emailsString = emailValue && emailValue.map((email) => email.value).join(",");
    console.log(emailsString);
    const ccEmailsString = ccEmails.length > 0 ? ccEmails.map((email) => email.value).join(",") : "";
    const bccEmailsString = bccEmails.length > 0 ? bccEmails.map((email) => email.value).join(",") : "";
    const mailtoUrl = `mailto:${encodeURIComponent(emailsString)}${
      ccEmailsString ? `?cc=${encodeURIComponent(ccEmailsString)}` : ""
    }${bccEmailsString ? `${ccEmailsString ? "&" : "?"}bcc=${encodeURIComponent(bccEmailsString)}` : ""}${
      subject ? `${ccEmailsString || bccEmailsString ? "&" : "?"}subject=${encodeURIComponent(subject)}` : ""
    }${body ? `${ccEmailsString || bccEmailsString || subject ? "&" : "?"}body=${encodeURIComponent(body)}` : ""}`;
    console.log(mailtoUrl);
    setQrCodeData((prevState) => ({
      ...prevState,
      11: mailtoUrl,
    }));
    setUrl(mailtoUrl);
  };
  useEffect(() => {
    generateEmailQRCode();
  }, [emailValue, ccEmails, bccEmails, subject, body]);
  const handleEmailChange = (selectedEmails) => {
    setEmails(selectedEmails);
    setQrCodeData((prevState) => ({
      ...prevState,
      11: { to: selectedEmails.map((email) => email.value) },
    }));
  };
  const emailOptions = [];

  const handleStyleChange = (style) => {
    setSelectedStyle(style);
  };
  const handleColorChange1 = (newColor) => {
    setColor1(newColor.hex);
  };
  const handleColorChange2 = (newColor) => {
    setColor2(newColor.hex);
  };
  const handleColorChange3 = (newColor) => {
    setColor3(newColor.hex);
  };
  const handleColorChange4 = (newColor) => {
    setColor4(newColor.hex);
  };
  const handleButtonClick1 = () => {
    setShowPicker1(!showPicker1);
  };
  const handleButtonClick2 = () => {
    setShowPicker2(!showPicker2);
  };
  const handleButtonClick3 = () => {
    setShowPicker3(!showPicker3);
  };
  const handleButtonClick4 = () => {
    setShowPicker4(!showPicker4);
  };
  const pickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker1(false);
      setShowPicker2(false);
      setShowPicker3(false);
      setShowPicker4(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSliderChange = (values) => {
    const newMargin = values[0];
    setMargin(newMargin);
  };

  const getQrCodeStyle = () => {
    switch (selectedStyle) {
      case "classic":
        return { eyeRadius: [0, 0, 0, 0], eyeColor: color2, fgColor: color3, bgColor: color1, size: margin };
      case "rounded":
        return { eyeRadius: [3, 3, 3, 3], eyeColor: color2, fgColor: color3, bgColor: color1, size: margin };
      case "smooth":
        return {
          qrStyle: "squares",
          eyeRadius: [5, 5, 5, 5],
          eyeColor: color2,
          fgColor: color3,
          bgColor: color1,
          size: margin,
        };
      case "thin":
        return { qrStyle: "dots", eyeColor: color2, fgColor: color3, bgColor: color1, size: margin };
      case "circles":
        return {
          qrStyle: "squares",
          eyeRadius: [50, 50, 50, 50],
          eyeColor: color2,
          fgColor: color3,
          bgColor: color1,
          size: margin,
        };
      default:
        return {};
    }
  };

  const qrCodeStyle = getQrCodeStyle();

  const handleLogoChange = (logo) => {
    setSelectedLogo(logo);
  };
  const getLogoSrc = () => {
    switch (selectedLogo) {
      case "wifi":
        return wifiimage;
      case "gmail":
        return gmailimage;
      case "appstore":
        return appstoreimage;

      case "closeButton":
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fileInputRef.current && !fileInputRef.current.contains(event.target)) {
        setLogo(null);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [logo, setLogo] = useState(null);
  const fileInputRef = useRef(null);

  const handleAddButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogo(null);
    }

    // removelogo
    if (file) {
      // Perform logic to handle the selected file (e.g., upload it)
      // For now, let's just log the file object
      console.log(file);

      // Update selectedLogo to null to deselect existing logo
      setSelectedLogo(null);

      // Clear file input to allow re-selection of the same file
      fileInputRef.current.value = "";
    }
  };

  //code to handle custom logo upload on 19-04
  // const [customLogoBlob, setCustomLogoBlob] = useState(null);
  // const fileInputRef2 = useRef(null);
  // const handleCustomLogoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const blob = new Blob([reader.result], { type: file.type });
  //       setCustomLogoBlob(blob);
  //     };
  //     reader.onerror = (error) => {
  //       console.error("Error reading the file:", error);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.error("Error: Invalid file type. Please select an image file.");
  //   }
  // };

  // const handleCiCirclePlusClick = () => {
  //   if (fileInputRef2.current) {
  //     fileInputRef2.current.click();
  //   }
  // };

  // useEffect(() => {
  //   if (fileInputRef2.current) {
  //     fileInputRef2.current.addEventListener("change", handleCustomLogoChange);
  //   }

  //   return () => {
  //     if (fileInputRef2.current) {
  //       fileInputRef2.current.removeEventListener("change", handleCustomLogoChange);
  //     }
  //   };
  // }, []);
  // const customLogoUrl = customLogoBlob ? URL.createObjectURL(customLogoBlob) : null;
  // console.log(customLogoUrl);
  // *******************

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const getTextSizeStyle = () => {
    switch (selectedSize) {
      case "1":
        return { fontSize: "14px" };
      case "2":
        return { fontSize: "18px" };
      case "3":
        return { fontSize: "22px" };
      case "4":
        return { fontSize: "26px" };
      case "5":
        return { fontSize: "30px" };
      default:
        return { fontSize: "14px" };
    }
  };

  const [selectedFont, setSelectedFont] = useState("");

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
  };

  const getFontWeightStyle = () => {
    switch (selectedFont) {
      case "normal":
        return { fontWeight: "normal" };
      case "bold":
        return { fontWeight: "bold" };
      case "light":
        return { fontWeight: "light" };
      case "lighter":
        return { fontWeight: "lighter" };
      default:
        return { fontWeight: "normal" };
    }
  };

  const handlePosition = (e) => {
    setSelectedPosition(e.target.value);
  };

  const getTextPositionStyle = () => {
    const isTop = selectedPosition === "top";
    const isBottom = selectedPosition === "bottom";

    return {
      id: isTop ? "caption1" : isBottom ? "caption2" : "",
      position: isTop ? "absolute" : "relative",
      top: isTop ? "0px" : "auto",
      bottom: isBottom ? "0px" : "auto",
    };
  };

  const combinedStyles = {
    color: color4,
    ...getFontWeightStyle(),
    ...getTextSizeStyle(),
    ...getTextPositionStyle(),
  };

  //PDF Logic Here
  // const [files, setFiles] = useState({
  //   pdfFile: [],
  // });

  const handleFileChange = (inputFileName, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: newFiles,
    }));
  };

  const handleRemoveFile = (inputFileName, index) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: prevFiles[inputFileName].filter((_, i) => i !== index),
    }));
  };

  const handleContactValidation = (event) => {
    const { id, value } = event.target;
    if (value.trim() !== "") {
      switch (id) {
        case "phonecontact":
          if (!/^\d{10}$/.test(value)) {
            toast.error("Invalid phone number format");
          }
          break;
        case "contactmail":
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            toast.error("Invalid email format");
          }
          break;
        case "companycontact":
          if (value.trim() === "") {
            toast.error("Company name is required");
          }
          break;
        case "websitecontact":
          if (!/^((https?:\/\/)?(www\.)?|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/.*)?$/.test(value)) {
            toast.error("Invalid website URL format");
          }
          break;
        default:
          break;
      }
    }
  };
  //To fetch countries
  const [country, setCountry] = useState([]);
  // console.log(country);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.countries); // Log the structure

        // Assuming data.data.countries is an object with country names as keys
        const countriesArray = Object.values(data.data.countries);
        setCountry(countriesArray);

        console.log(Array.isArray(countriesArray)); // Should log true
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const countryOptions = country.map((country) => ({
    value: country.name, // Adjust this based on your data structure
    label: country.name, // Adjust this based on your data structure
  }));

  // focus-in-firstbox

  const inputRefs = useRef({}); // Ref to store references to input fields
  // Function to toggle active tab

  useEffect(() => {
    // Focus on the first input field when active tab changes
    inputRefs.current[activeIconTab]?.focus();
  }, [activeIconTab]); // Run this effect whenever activeIconTab changes

  //tooltips
  // embeded tooltip
  const [tooltipOpenEmbeded, setOpenEmbeded] = useState(false);
  const toggleEmbeded = () => {
    setOpenEmbeded(!tooltipOpenEmbeded);
  };

  const [tooltipShare, setOpenShare] = useState(false);
  const toggleShare = () => {
    setOpenShare(!tooltipShare);
  };

  const [tooltipCopy, setCopyTooltip] = useState(false);
  const toogleCopyButton = () => {
    setCopyTooltip(!tooltipCopy);
  };

  // imageupload
  const handleFileExplorerOpen = () => {
    // Programmatically trigger the click event of the file input element
    document.getElementById("fileInput").click();
  };

  const handleFileSelect = (event) => {
    // Handle file selection here
    const selectedFile = event.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  // CC and BCC validation

  const [emailError, setEmailError] = useState("");
  const [ccError, setCcError] = useState("");
  const [bccError, setBccError] = useState("");
  const ccRef = useRef(null);
  const bccRef = useRef(null);
  // console.log(emailValue);

  const isValidEmail = (email) => {
    // Basic email validation regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleEmailInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change" && inputValue) {
      const lastTypedEmail = inputValue.trim().split(",").pop(); // Get the last typed email
      if (!isValidEmail(lastTypedEmail)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }
  };

  const handleCcInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change" && inputValue) {
      const lastTypedEmail = inputValue.trim().split(",").pop(); // Get the last typed email
      if (!isValidEmail(lastTypedEmail)) {
        setCcError("Please enter a valid email address for CC.");
      } else {
        setCcError("");
      }
    }
  };

  const handleBccInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change" && inputValue) {
      const lastTypedEmail = inputValue.trim().split(",").pop(); // Get the last typed email
      if (!isValidEmail(lastTypedEmail)) {
        setBccError("Please enter a valid email address for BCC.");
      } else {
        setBccError("");
      }
    }
  };

  const handleEmailKeyPress = (e) => {
    if (e.key === "Enter" && isValidEmail(e.target.value)) {
      ccRef.current && ccRef.current.focus();
    }
  };

  const handleCcKeyPress = (e) => {
    if (e.key === "Enter" && isValidEmail(e.target.value)) {
      bccRef.current && bccRef.current.focus();
    }
  };

  const handleBccKeyPress = (e) => {
    if (e.key === "Enter" && isValidEmail(e.target.value)) {
      // Move focus to the next input field or perform any other action
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission behavior

      // Move focus to the next input field
      const emailBody = document.getElementById("emailBody");
      if (emailBody) {
        emailBody.focus();
      }
    }
  };
  const loadingoptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const uploadanimation = {
    loop: true,
    autoplay: true,
    animationData: uploadanimationfile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();
  // all api would be here
  //Limit Model
  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => setModalZoom(!modalZoom);
  const [modalZoom1, setModalZoom1] = useState(false);
  const toggleZoom1 = () => setModalZoom1(!modalZoom1);
  const [modalZoom2, setModalZoom2] = useState(false);
  const toggleZoom2 = () => {
    setModalZoom2(!modalZoom2);
    // if (!modalZoom2) {
    const now = new Date();
    const expires = new Date(now.getTime() + 1 * 60 * 60 * 1000);
    Cookies.set("isModalOpen", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    // }
  };
  const [modalZoom4, setModalZoom4] = useState(false);
  const toggleZoom4 = () => setModalZoom4(!modalZoom4);
  const [modalZoom5, setModalZoom5] = useState(false);
  const toggleZoom5 = () => setModalZoom5(!modalZoom5);
  const saveTocollection = async (pdfPath, file_key) => {
    if (!isLogin) {
      toast.info("Please login first to save your QR code to collection");
      return;
    } else {
      Swal.fire({
        title: "Save as",
        input: "text",
        inputPlaceholder: "Enter your QR name",
        inputAttributes: {
          maxlength: 30,
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const userInput = result.value;
          console.log(userInput);

          const container = downloadContainerRef.current;
          if (!container) {
            console.error("Container not found");
            return;
          }
          if (userInput === undefined || userInput === null || userInput === "") {
            toast.error("Please enter a valid name");
            return;
          }
          try {
            setLoading(true);
            let formData = new FormData();
            const pngDataUrl = await toPng(container);
            formData.append("qrcode_type", activeIconTabText);
            formData.append("base64_data", pngDataUrl);
            formData.append("qrcode_data", url);
            formData.append("qr_name", userInput);
            formData.append("pdf_path", pdfPath ? pdfPath : null);
            formData.append("file_key", file_key ? file_key : null);
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/add-qr`, {
              headers: {
                authToken: getCookie("authToken"),
              },
              method: "POST",
              body: formData,
            });
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            const responseData = await response.json();
            if (responseData.status) {
              toast.success(responseData.message);
              navigate("collections");
            }
            if (responseData.data.limit) {
              // setModalZoom(true);
              toast.error("You have exceeded the limit");
            } else {
              toast.error(responseData.message);
              console.error(responseData.message);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      });
    }
  };

  const [files, setFiles] = useState([]);
  const [filesupload, setFilesupload] = useState(false);
  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const uploadPDF = async () => {
    try {
      if (files.length === 0) {
        toast.error("No file selected for upload.");
        return;
      }
      if (!isLogin) {
        toast.info("Please login first to save your QR code to collection");
        return;
      }
      const maxSize = 50 * 1024 * 1024;
      if (files[0].size > maxSize) {
        toast.error("File size limit exceeded (50 MB).");
        return;
      }

      setFilesupload(true);
      const formData = new FormData();
      formData.append("file", files[0]);

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/file-qr`, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status) {
        toast.success("File uploaded successfully!");
        // toast.info("Scan Your QR Now!");
        const pdflink = `${process.env.REACT_APP_PUBLIC_URL}` + "pdf/" + responseData.data.key;
        const file_key = responseData.data.key;
        const pdfPath = responseData.data.filePath;
        setQrCodeData((prevState) => ({ ...prevState, 12: pdflink }));
        setUrl(pdflink);
        saveTocollection(pdfPath, file_key);
      } else if (responseData.data.limit) {
        setModalZoom1(true);
      } else if (responseData.message === "Token is invalid or broken") {
        toast.error("Please login to use this feature");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error(error);
    } finally {
      setFilesupload(false);
    }
  };

  // const generateQRText = () => {
  //   return `Title: ${title}\nName: ${name}\nInformation: ${info}\nBuy Link: ${buyLink}`;
  // };

  //combineQR
  const combinedQRData = `${qrCodeData[activeIconTab]}`;
  const { contextData, apiData } = useContext(SubscriptionContext);
  const [subscription] = contextData;
  const [apiWhat] = apiData;
  // console.log("Sunscription API", apiWhat);
  const [limitexceed, setLimitExceeded] = useState(false);
  const [subscriptionended, setSubscriptionEnds] = useState(false);
  const checkifLimit = () => {
    if (apiWhat?.data?.upgradeModel) {
      setLimitExceeded(true);
      setModalZoom(true);
    }
  };
  const checkifEnds = () => {
    if (apiWhat?.data?.subscription_end) {
      setSubscriptionEnds(true);
      if (subscriptionModal) {
        setModalZoom2(true);
      }
    }
  };
  useEffect(() => {
    checkifLimit();
  }, [apiWhat?.data?.upgradeModel]);
  useEffect(() => {
    checkifEnds();
  }, [apiWhat?.data?.subscription_end]);
  const [traildate, setTrialdate] = useState();
  const [countdownloader, setcountdownloader] = useState(false);
  const fourteenDayTrial = () => {
    if (apiWhat?.data?.trial_end_date) {
      setTrialdate(apiWhat?.data?.trial_end_date);
    }
  };
  useEffect(() => {
    fourteenDayTrial();
    setcountdownloader(true);
    setTimeout(() => {
      setcountdownloader(false);
    }, 1000);
  }, [apiWhat?.data?.trial_end_date]);

  //show Modal on Trial end
  const TrialEndModel = () => {
    if (apiWhat?.data?.trial_end) {
      setModalZoom4(true);
    }
  };
  useEffect(() => {
    TrialEndModel();
  }, [apiWhat?.data?.trial_end]);
  const [deleteSome, setdeleteSome] = useState("");
  const DeleteSome = () => {
    if (apiWhat?.data?.deleteSome) {
      setModalZoom5(true);
      setdeleteSome(apiWhat?.message);
    }
  };
  useEffect(() => {
    DeleteSome();
  }, [apiWhat?.data?.deleteSome]);

  const endDate = new Date(traildate); // Replace with your desired end date
  const { days, hours, minutes, seconds, expired } = FourteenDaysCountDown(endDate);
  const [tooltipOpenDashboard, settooltipOpenDashboard] = useState(false);
  const toggleToolTip = () => settooltipOpenDashboard(!tooltipOpenDashboard);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef();
  const popoverContentRef = useRef();
  const popovertoggle = () => setPopoverOpen(!popoverOpen);

  const handleClickOutsidepopover = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverContentRef.current &&
      !popoverContentRef.current.contains(event.target)
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutsidepopover);
    } else {
      document.removeEventListener("mousedown", handleClickOutsidepopover);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsidepopover);
    };
  }, [popoverOpen]);
  return (
    <React.Fragment>
      <Head title="QR code" />
      {loading && (
        <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
          <Lottie options={loadingoptions} height={200} width={200} />
        </div>
      )}
      {filesupload && (
        <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
          <Lottie options={uploadanimation} height={200} width={200} />
        </div>
      )}
      {!subscription && traildate && (
        <div className="countdownalert w-25">
          <UncontrolledAlert className="alert-pro" color={`${expired ? "danger" : "warning"}`} fade={false}>
            <div className="alert-text">
              {countdownloader ? (
                <>
                  <h6 className="customfont">
                    <Skeleton width={200} />
                  </h6>
                  <p className="customfont">
                    <Skeleton width={100} />
                  </p>
                </>
              ) : expired ? (
                <>
                  <h6 className="customfont">Your Trial Ends</h6>
                  <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
                    <p className="customfont">Upgrade Your Package</p>
                  </Link>
                </>
              ) : (
                <>
                  <h6 className="customfont">Your Trial Will end in</h6>
                  <p className="customfont">{`${days}d ${hours}h ${minutes}m ${seconds}s`}</p>
                </>
              )}
            </div>
          </UncontrolledAlert>
        </div>
      )}
      {/* Limit Model */}
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" size="md">
        <ModalBody>
          <div className="text-center">
            <UpgradePackage />
            <h4 className="customfont mt-1">UPGRADE TO ACCESS FULL POTENTIAL OF SILOQR</h4>
            <small className="text-justifys customfont fw-bold">
              Upgrading to SiloQR premium lets you generate
              <mark> more QR codes, access dynamic options, and easily add product-specific QR codes.</mark> This
              ensures enhanced flexibility, functionality, and customer engagement. Maximize your business's digital
              potential with these advanced features, making every product instantly accessible with just a scan.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      {/* File modal */}
      <Modal isOpen={modalZoom1} toggle={toggleZoom1} modalClassName="zoom" size="md">
        <ModalBody>
          <div className="text-center">
            <UpgradePackage />
            <h4 className="customfont mt-1">UPGRADE TO MAKE MORE DYNAMIC QR</h4>
            <small className="text-justifys customfont fw-bold">
              By upgrading, you can create multiple dynamic QR codes, enhancing your flexibility and functionality. This
              allows for real-time updates and customized user experiences, ensuring your business stays adaptable and
              responsive to customer needs. Maximize your digital strategy with the power of dynamic QR codes.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      {/* subscription ends */}
      <Modal isOpen={modalZoom2} toggle={toggleZoom2} modalClassName="zoom" size="md">
        <ModalHeader
          className="d-flex justify-end border-0 "
          toggle={toggleZoom2}
          close={
            <div>
              <button className="close" onClick={toggleZoom2}>
                <Icon name="cross" />
              </button>
            </div>
          }
        >
          {" "}
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <SubscriptionEnds />
            <h4 className="customfont mt-1">Subscription Expiration Notice</h4>
            <small className="text-justifys customfont fw-bold">
              Your subscription has expired. To continue accessing our QR code services, please renew your subscription
              promptly. Thank you for your continued support.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>

      {/* model delete */}

      {/* delete some  */}
      <Modal isOpen={modalZoom5} toggle={toggleZoom5} modalClassName="zoom" size="md">
        <ModalBody>
          <div className="text-center">
            <AlertDelete />
            <h4 className="customfont mt-1">Managing QR Code Limits: Tips for Deleting Unused Codes</h4>
            <small className="text-justifys customfont fw-bold">{deleteSome}</small>
          </div>
          <div className="d-flex justify-content-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
            <Link to="collections" className="ms-1">
              <Button color="danger" id="TooltipExample">
                <span className="customfont">Go To dashboard</span>
              </Button>
            </Link>
            <Tooltip isOpen={tooltipOpenDashboard} target="TooltipExample" toggle={toggleToolTip} placement="right">
              Delete unused QR Codes
            </Tooltip>
          </div>
        </ModalBody>
      </Modal>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>{/* <h3 className="fs-1 customfont">My QR Codes</h3> */}</BlockHeadContent>
            <BlockHeadContent>
              <Link to="/collections">
                <Button color="primary" className=" p-2 fs-6 rounded " size="sm">
                  <Icon name="folder"></Icon>
                  <span className="">Go To Collections</span>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row>
            <Col md="8">
              <Card className="card-bordered p-2 shadow h-100 ">
                <div className="border-bottom border-2 mb-2">
                  <ul className="nk-menu nk-menu-main">
                    <li className={`nk-menu-item ${activeIconTab === "5" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("5", "text");
                        }}
                      >
                        <Icon name="file-text" className="fs-4" />
                        <span>Text</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "6" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("6", "url");
                        }}
                      >
                        <Icon name="link-alt" className="fs-4" /> <span>URL</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "7" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("7", "contact");
                        }}
                      >
                        <Icon name="contact" className="fs-4" /> <span>Contact</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "8" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("8", "map");
                        }}
                      >
                        <Icon name="map-pin" className="fs-4" /> <span>Map</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item  ${activeIconTab === "9" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("9", "wifi");
                        }}
                      >
                        <Icon name="wifi" className="fs-4" /> <span>WIFI</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "10" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("10", "appstore");
                        }}
                      >
                        <Icon name="menu-circled" className="fs-4" /> <span>App Store</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "11" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("11", "email");
                        }}
                      >
                        <Icon name="mail" className="fs-4" /> <span>Email</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "12" ? "active current-page" : ""}`}>
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("12", "pdf");
                        }}
                      >
                        <Icon name="file-pdf" className="fs-4" /> <span>PDF</span>
                      </Link>
                    </li>
                    {/* <li className={`nk-menu-item ${activeIconTab === "13" ? "active current-page" : ""}`} >
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("13");
                        }}
                      >
                        <Icon name="img" className="fs-4" />
                        <span>Image</span>
                      </Link>
                    </li>
                    <li className={`nk-menu-item ${activeIconTab === "14" ? "active current-page" : ""}`} >
                      <Link
                        to="#tab"
                        id="texttab"
                        className={`nk-menu-link `}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("14");
                        }}
                      >
                        <Icon name="bag" className="fs-4" /> <span>Products</span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <Nav tabs>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      id="texttab"
                      className={classnames({ active: activeIconTab === "5" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("5", "text");
                      }}
                    >
                      <Icon name="file-text" />
                      <span>Text</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      id="urltab"
                      className={classnames({ active: activeIconTab === "6" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("6", "url");
                      }}
                    >
                      <Icon name="link-alt" /> <span>URL</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "7" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("7", "contact");
                      }}
                    >
                      <Icon name="contact" /> <span>Contact</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "8" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("8", "map");
                      }}
                    >
                      <Icon name="map-pin" /> <span>Map</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "9" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("9", "wifi");
                      }}
                    >
                      <Icon name="wifi" /> <span>WIFI</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "10" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("10", "appstore");
                      }}
                    >
                      <Icon name="menu-circled" /> <span>App Store</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "11" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("11", "email");
                      }}
                    >
                      <Icon name="mail" /> <span>Email</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "12" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("12", "pdf");
                      }}
                    >
                      <Icon name="file-pdf" /> <span>PDF</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "13" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("13");
                      }}
                    >
                      <Icon name="img" />
                      <span>Image</span>
                    </NavLink>
                  </NavItem> */}

                {/* <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "14" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("14");
                      }}
                    >
                      <Icon name="bag" /> <span>Products</span>
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={activeIconTab}>
                  <TabPane tabId="5">
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} onChange={handleUrlChange} className="text-dark" />
                      </div>
                      <span className="urltextareahelper "></span>
                    </div>
                  </TabPane>
                  <TabPane tabId="6">
                    <div className="form-control-wrap">
                      <Label htmlFor="namecontact" className="form-label">
                        URL
                      </Label>
                      <input
                        ref={(input) => (inputRefs.current["6"] = input)}
                        className="form-control bg-transparent"
                        placeholder={"Enter Your Website URL"}
                        type="text"
                        id="default-textarea"
                        onChange={(e) => generateURLcode(e)}
                        autoComplete="off"
                      ></input>
                      {urlError && <div className="form-note text-danger">{urlError}</div>}
                      <span className="urltextareahelper"> </span>
                    </div>
                    {/* {
                      <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary" onClick={handleSave}>
                          Save
                        </button>
                      </div>
                    } */}
                    {/* <PDFViewer /> */}
                  </TabPane>

                  <TabPane tabId="7">
                    <Row>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="namecontact" className="form-label">
                            First Name
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={(input) => (inputRefs.current["7"] = input)}
                              className="form-control bg-transparent"
                              type="text"
                              id="firstnamecontact"
                              onChange={handleInput}
                              maxLength="20"
                              autoComplete="off"
                              placeholder="Enter first name"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="namecontact" className="form-label">
                            Last Name
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="lastnamecontact"
                              placeholder="Enter last name"
                              onChange={handleInput}
                              maxLength="20"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="countrycontact" className="form-label">
                            Country
                          </Label>
                          <div className="form-control-wrap">
                            {/* <input
                              className="form-control"
                              type="text"
                              id="countrycontact"
                              placeholder="Enter country"
                              onChange={handleInput}
                            /> */}
                            {country.length > 0 && (
                              <Select
                                options={countryOptions}
                                name="state"
                                id="countrycontact"
                                onChange={(e) => handleInput(e)}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="phonecontact" className="form-label">
                            Phone
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="number"
                              id="phonecontact"
                              onChange={handleInput}
                              onBlur={handleContactValidation}
                              autoComplete="off"
                              max="20"
                              placeholder="Enter phone number"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="contactmail" className="form-label">
                            Mail
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="contactmail"
                              onChange={handleInput}
                              onBlur={handleContactValidation}
                              autoComplete="off"
                              maxLength="100"
                              placeholder="Enter mail"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="companycontact" className="form-label">
                            Company
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="companycontact"
                              placeholder="Enter company name"
                              onChange={handleInput}
                              maxLength="30"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="statecontact" className="form-label">
                            Address
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="addresscontact"
                              placeholder="Enter your address"
                              onChange={handleInput}
                              maxLength="30"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <Label htmlFor="websitecontact" className="form-label">
                            Website
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="websitecontact"
                              onChange={handleInput}
                              placeholder="Enter your website"
                              onBlur={handleContactValidation}
                              maxLength="30"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <span className="urltextareahelper"> </span>
                  </TabPane>
                  <TabPane tabId="8">
                    <div className="form-group">
                      <Label htmlFor="default-2" className="form-label">
                        Enter Your Address
                      </Label>
                      <div className="form-control-wrap">
                        {/* <div className="form-icon form-icon-left">
                          <Icon name="map-pin" />
                        </div> */}
                        <input
                          ref={(input) => (inputRefs.current["8"] = input)}
                          className="form-control bg-transparent mb-2"
                          type="text"
                          id="mapaddress"
                          onChange={handleAddressChange}
                          value={address}
                          placeholder="Enter your address"
                          maxLength="50"
                        />
                      </div>
                    </div>
                    <div className="text-center pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Latitude
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="latinput"
                              onChange={handleLatitudeChange}
                              value={latitude}
                              maxLength="8"
                              placeholder="Enter Latitude"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Longitude
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control bg-transparent"
                              type="text"
                              id="loninput"
                              onChange={handleLongitudeChange}
                              value={longitude}
                              maxLength="8"
                              placeholder="Enter Longitude"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <span className="urltextareahelper"> </span>
                  </TabPane>
                  <TabPane tabId="9">
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Network Name
                          </Label>
                          <div className="form-control-wrap mb-2">
                            <input
                              ref={(input) => (inputRefs.current["9"] = input)}
                              className="form-control bg-transparent"
                              type="text"
                              id="ssidwifi"
                              onChange={generateWiFiQRCode}
                              autoComplete="off"
                              placeholder="Enter network name"
                              maxLength="20"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Nework Type
                          </Label>
                          <div className="form-control-wrap mb-2">
                            <select
                              id="networktypewifi"
                              className="form-control bg-transparent"
                              onChange={handleNetworkTypeChange}
                            >
                              <option value="WPA">WPA/WPA2</option>
                              <option value="WEP">WEP</option>
                              <option value="nopass">No Password</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Password
                          </Label>
                          {/* <div className="form-control-wrap">
                            <input
                              className="form-control mb-2"
                              type="password"
                              id="passwordwifi"
                              placeholder="Password"
                              onChange={generateWiFiQRCode}
                              disabled={networkType === "nopass"}
                            />
                          </div> */}
                          <div className="form-control-wrap">
                            <a
                              href="#passwordwifi"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setPassState(!passState);
                              }}
                              className={`form-icon form-icon-right passcode-switch ${
                                passState ? "is-hidden" : "is-shown"
                              }`}
                            >
                              <Icon name="eye" className="passcode-icon icon-show"></Icon>
                              <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                            </a>
                            <input
                              type={passState ? "text" : "password"}
                              id="passwordwifi"
                              placeholder="Enter your password"
                              className={` bg-transparent form-control ${passState ? "is-hidden" : "is-shown"}`}
                              onChange={generateWiFiQRCode}
                              disabled={networkType === "nopass"}
                              maxLength="20"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <span className="urltextareahelper"> </span>
                  </TabPane>
                  <TabPane tabId="10">
                    <div className="form-control-wrap">
                      <Label htmlFor="default-0" className="form-label">
                        App ID
                      </Label>
                      <input
                        ref={(input) => (inputRefs.current["10"] = input)}
                        className=" form-control bg-transparent"
                        placeholder={"Add app ID"}
                        type="text"
                        id="appstoreid"
                        onChange={generateAppQRCode}
                        autoComplete="off"
                      ></input>
                      <span className="urltextareahelper"></span>
                    </div>
                    <div className="form-text">
                      * Enter App Store App ID{" "}
                      <span ref={popoverRef}>
                        <Icon name="info" id="Popover1" onClick={popovertoggle} />
                      </span>
                    </div>
                    <Popover isOpen={popoverOpen} target="Popover1" toggle={popovertoggle}>
                      <div ref={popoverContentRef}>
                        <PopoverHeader className="customfont">Example</PopoverHeader>
                        <PopoverBody className="border border-1">
                          {/* <img src={appscreesnshot} alt="App Screenshot" className="img-fluid" /> */}
                          <ImageContainer img={appscreesnshot} />
                        </PopoverBody>
                      </div>
                    </Popover>
                  </TabPane>
                  <TabPane tabId="11">
                    <Row className="emailQrsCreate">
                      <Col xs="12" md="2" className="mb-md-2">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label ms-lg-4">
                            To
                          </Label>
                          {[showCcField, showBccField] && <div className="form-group d-none  mt-5 pt-4"></div>}
                        </div>
                      </Col>
                      <Col xs="12" md="10" className="mb-2">
                        <div className="form-control-wrap">
                          <CreatableSelect
                            isMulti
                            id="emailcontact"
                            options={emailOptions}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            formatCreateLabel={(userInput) => `${userInput}`}
                            onChange={(selectedEmails) => setEmailValue(selectedEmails)}
                            onInputChange={handleEmailInputChange}
                            placeholder="Enter recipient email address"
                            onKeyDown={handleEmailKeyPress} // Attach handleEmailKeyPress to handle Enter key press
                          />
                          {/* <Tags
                            className="input-mail"
                            mode="textarea"
                            value={tagifyOptions}
                            onChange={(e) => onTagifyChange(e)}
                            settings={settings}
                            showDropdown={false}
                          /> */}
                          {emailError && <div className="text-danger">{emailError}</div>}
                        </div>
                        <div className="d-flex justify-content-end ">
                          <div className="form-control-wrap mx-1 mt-2 d-flex justify-content-end">
                            <p
                              className="d-flex justify-content-end text-dark fw-bold cursor-pointer "
                              color="primary"
                              size="sm"
                              onClick={() => setShowCcField(!showCcField)}
                            >
                              CC
                            </p>
                          </div>
                          <div className="form-control-wrap mx-1 mt-2 d-flex justify-content-end">
                            <p
                              className="d-flex justify-content-end text-dark fw-bold cursor-pointer "
                              size="sm"
                              onClick={() => setShowBccField(!showBccField)}
                            >
                              BCC
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" className="mb-2">
                        {showCcField && (
                          <div className="row mt-2">
                            <Col xs="12" md="2" className="mb-md-2">
                              <div className="form-group">
                                <Label htmlFor="emailcc" className="form-label ms-lg-4">
                                  CC
                                </Label>
                              </div>
                            </Col>

                            <Col xs="12" md="10" className="mb-2 pl-3">
                              <div className="form-control-wrap">
                                <CreatableSelect
                                  ref={ccRef}
                                  isMulti
                                  id="emailcc"
                                  options={emailOptions}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  formatCreateLabel={(userInput) => `${userInput}`}
                                  onChange={(selectedEmails) => setCcEmails(selectedEmails)}
                                  onInputChange={handleCcInputChange}
                                  onKeyDown={handleCcKeyPress}
                                  isClearable
                                  className="mr-2"
                                  autoComplete="off"
                                  placeholder=""
                                />
                                {ccError && <div className="text-danger">{ccError}</div>}
                              </div>
                            </Col>
                          </div>
                        )}

                        {showBccField && (
                          <div className="row mt-2">
                            <Col xs="12" md="2" className="mb-md-2">
                              <div className="form-group">
                                <Label htmlFor="emailbcc" className="form-label ms-lg-4">
                                  BCC
                                </Label>
                              </div>
                            </Col>
                            <Col xs="12" md="10" className="mb-2">
                              <div className="form-control-wrap">
                                <CreatableSelect
                                  ref={bccRef}
                                  isMulti
                                  id="emailbcc"
                                  options={emailOptions}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  formatCreateLabel={(userInput) => `${userInput}`}
                                  onChange={(selectedEmails) => setBccEmails(selectedEmails)}
                                  onInputChange={handleBccInputChange}
                                  onKeyDown={handleBccKeyPress}
                                  isClearable
                                  autoComplete="off"
                                  placeholder=""
                                />
                                {bccError && <div className="text-danger">{bccError}</div>}
                              </div>
                            </Col>
                          </div>
                        )}
                      </Col>
                      <Col xs="12" md="2" className="mb-md-2">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label ms-lg-4">
                            Subject
                          </Label>
                        </div>
                      </Col>
                      <Col xs="12" md="10" className="mb-2">
                        <div className="form-control-wrap">
                          <input
                            className="form-control bg-transparent placeholdertext"
                            placeholder="Subject"
                            type="text"
                            id="emailSubject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            onKeyDown={handleKeyPress} // Call handleKeyPress function when a key is pressed
                            autoComplete="off"
                            maxLength="30"
                          />
                        </div>
                      </Col>
                      <Col xs="12" md="2" className="mb-md-2">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label ms-lg-4">
                            Content
                          </Label>
                        </div>
                      </Col>
                      <Col xs="12" md="10" className="mb-2">
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control bg-transparent placeholdertext"
                            placeholder="Message"
                            type="email"
                            id="emailBody"
                            maxLength="250"
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <span className="urltextareahelper"> </span>
                  </TabPane>
                  <TabPane tabId="12">
                    {/* file upload */}
                    {/* <div id="uppy-dashboard"></div> */}
                    <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)} maxFiles={1} multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()} className="dropzone upload-zone border-danger dz-clickable">
                            <input {...getInputProps()} accept=".pdf" />
                            {files.length === 0 && (
                              <div className="dz-message">
                                <FileUploads />
                                <span>
                                  Drag and drop file <span className="text-underline fw-bold">Choose File</span>
                                </span>
                              </div>
                            )}
                            {files.map((file, index) => (
                              <div
                                key={file.name}
                                className="dz-preview dz-processing bg-white dz-image-preview dz-error dz-complete w-100"
                              >
                                <div className="dz-image w-100 ">
                                  <div className="card d-flex justify-content-between card-bordered flex-row">
                                    <div className="d-flex align-items-center ">
                                      <PDFImage />
                                      <div className="ms-2">
                                        <small className="fw-bold ">{file.name}</small> <br />
                                        <small>{formatFileSize(file.size)}</small>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center ">
                                      <button
                                        type="button"
                                        className="btn-icon btn-round btn bg-danger text-white btn-xs me-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteFile(index);
                                        }}
                                      >
                                        <Icon name="cross" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {files.length > 0 && (
                            <div className="mt-1">
                              <button className="btn btn-block btn-success" onClick={uploadPDF}>
                                Upload 1 File
                              </button>
                            </div>
                          )}
                        </section>
                      )}
                    </Dropzone>
                    <div className="form-text">
                      * Upload Your PDF file upto <strong>50MB</strong> to generate QR
                    </div>
                  </TabPane>
                  <TabPane tabId="13">
                    <div className="m-3 p-5 dashed-border">
                      <input type="file" id="fileInput" style={{ display: "none" }} onChange={handleFileSelect} />
                      {/* Content of the "Image" tab pane */}
                      <div className="d-flex justify-content-center ">
                        <Button color="primary" className="fs-6 rounded " size="sm" onClick={handleFileExplorerOpen}>
                          Select Image
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  {/* <TabPane tabId="14">
                    <Row className="p-4">
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="namecontact" className="form-label">
                            Product Title
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={(input) => (inputRefs.current["14"] = input)}
                              className="form-control"
                              type="text"
                              placeholder="Enter Product Title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="namecontact" className="form-label">
                            Product Name
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control"
                              type="text"
                              id="lastnamecontact"
                              placeholder="Enter Product name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md="12" className="mb-2">
                        <div className="form-group">
                          <Label htmlFor="phonecontact" className="form-label">
                            Product Description
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              value={info}
                              onChange={(e) => setInfo(e.target.value)}
                              placeholder="Enter product Description"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <div>
                            <Label htmlFor="contactmail" className="form-label">
                              Product Image
                            </Label>
                          </div>
                          <div>
                            <Button
                              color="primary"
                              className="fs-6 rounded "
                              size="sm"
                              onClick={handleFileExplorerOpen}>
                              Select Image
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mb-2">
                        <div className="form-group">
                          <div>
                            <Label htmlFor="contactmail" className="form-label">
                              Product Video
                            </Label>
                          </div>
                          <div>
                            <Button
                              color="primary"
                              className="fs-6 rounded "
                              size="sm"
                              onClick={handleFileExplorerOpen}>
                              Select Video
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col md="12" className="mb-2 mt-2 ">
                        <div className="form-group">
                          <Label htmlFor="statecontact" className="form-label">
                            Buy Link
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control"
                              type="text"
                              value={buyLink}
                              onChange={(e) => setBuyLink(e.target.value)}
                              placeholder="Enter product buy link"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <span className="urltextareahelper"> </span>
                  </TabPane> */}
                </TabContent>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordered p-4 shadow border border-2">
                <div className="p-3">
                  <div className="d-flex gap-3 align-items-center" style={{ height: "10px" }}>
                    {/* <Button
                      // className="btn btn-icon text-black-50 shadow"
                      className="shadow ms-2 btn-outline-primary headerbuttoncms"
                      color="white"
                      id="codebutton"
                      size="sm"
                      onClick={modaltoggle}
                    > */}
                    <Icon
                      name="code"
                      id="codebutton"
                      onClick={modaltoggle}
                      className="fs-4 text-primary qrcodeactionstop"
                    />
                    {/* </Button> */}
                    {/* <Tooltip placement="bottom" isOpen={tooltipOpenEmbeded} target="codebutton" toggle={toggleEmbeded}>
                    Embed Code
                  </Tooltip> */}
                    {/* <Button
                      // className="btn btn-icon text-black-50 shadow ms-2"
                      className=" shadow ms-2 headerbuttoncms"
                      color="white"
                      size="sm"
                      onClick={handleShare}
                      id="sharebutton"
                    > */}
                    <Icon
                      name="share"
                      id="sharebutton"
                      onClick={handleShare}
                      className="fs-4 text-primary qrcodeactionstop"
                    />
                    {/* </Button> */}
                    {/* <Tooltip placement="bottom" isOpen={tooltipShare} target="sharebutton" toggle={toggleShare}>
                    Share QR code
                  </Tooltip> */}
                    {/* <Button
                      // className="btn btn-icon text-black-50 shadow ms-2"
                      className="shadow ms-2  headerbuttoncms"
                      id="copybutton"
                      color="white"
                      size="sm"
                      onClick={handleCopyPngToClipboard}
                    > */}
                    {copied ? (
                      <Icon
                        name="check"
                        id="copybutton"
                        onClick={handleCopyPngToClipboard}
                        className="fs-4 text-primary qrcodeactionstop"
                      />
                    ) : (
                      <Icon
                        name="copy"
                        id="copybutton"
                        onClick={handleCopyPngToClipboard}
                        className="fs-4 text-primary qrcodeactionstop"
                      />
                    )}
                    {/* </Button> */}
                    {/* <Tooltip placement="bottom" isOpen={tooltipCopy} target="copybutton" toggle={toogleCopyButton}>
                    Copy QR code
                  </Tooltip> */}
                  </div>
                </div>
                <div className="QrCodeDiv text-center p-2">
                  <div className="d-flex justify-content-center align-items-center ">
                    <div ref={downloadContainerRef}>
                      <QRCode value={combinedQRData} size={174} {...qrCodeStyle} logoImage={getLogoSrc()} />
                      <div className="d-flex justify-content-center align-items-center ">
                        {caption && (
                          <p className="caption" style={combinedStyles}>
                            {caption}
                          </p>
                        )}
                      </div>
                    </div>
                    {logo && (
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          position: "absolute",
                          top: "45%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="formatselect">
                  <div className="text-center mb-3">
                    {/* <ul className="custom-control-group">
                      <li>
                        <div className="custom-control custom-control-sm custom-radio checked">
                          <input
                            type="radio"
                            id="btnRadio1"
                            name="btnRadio"
                            defaultChecked
                            className="custom-control-input "
                            onChange={() => handleFormatChange("svg")}
                          />
                          <label className="custom-control-label" htmlFor="btnRadio1">
                            <span className="fw-bolder fs-6">SVG</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-control-sm custom-radio checked">
                          <input
                            type="radio"
                            id="btnRadio2"
                            name="btnRadio"
                            className="custom-control-input"
                            onChange={() => handleFormatChange("png")}
                          />
                          <label className="custom-control-label" htmlFor="btnRadio2">
                            <span className="fw-bolder fs-6">PNG</span>
                          </label>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                  <div className="m-auto mt-1 row justify-content-center">
                    {/* <Button
                      className="btn btn-lg btn-primary col-lg-5  col-md-12 d-flex justify-content-center mb-2 me-lg-1"
                      color="primary"
                      size="lg"
                      onClick={handleDownload}
                    >
                      <Icon name="download" />
                      <span>Download</span>
                    </Button> */}
                    <Button
                      className="btn-lg col-lg-6  col-md-12  d-flex justify-content-center mb-2 me-lg-1 btn-outline-primary headerbuttoncms"
                      size="lg"
                      onClick={saveTocollection}
                    >
                      <Icon name="save" />
                      <span>Save To Collection</span>
                    </Button>
                    <Button
                      className="btn-lg col-lg-5 col-md-12 d-flex justify-content-center mb-2 ms-lg-1 btn-outline-primary headerbuttoncms "
                      size="lg"
                      onClick={customizemodel}
                    >
                      <Icon name="color-palette" />
                      <span>Customize</span>
                    </Button>
                  </div>
                  <div className="d-flex justify-center">
                    {/* <Button
                      className="btn btn-lg bg-white text-danger d-flex justify-content-center mb-2 me-lg-1 btn-outline-primary"
                      color="white"
                      size="lg"
                      onClick={saveTocollection}
                    >
                      <Icon name="folder" />
                      <span>Save To Collection</span>
                    </Button> */}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal size="lg" isOpen={modal} toggle={modaltoggle} className="">
            <ModalHeader
              className="border-0"
              toggle={modaltoggle}
              close={
                <button className="close" onClick={modaltoggle}>
                  <Icon name="cross" />
                </button>
              }
            >
              <span className="customfont">
                <Icon name="code" className="me-1" /> Embed
              </span>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between">
                <Button
                  className={`btn bg-white text-dark border-white changecodenav ${buttonClasses("html")}`}
                  color="primary"
                  onClick={() => changeCode("html")}
                >
                  <Icon name="html5" />
                  <span className="customfont text-orange">HTML 5</span>
                </Button>
              </div>
              <div className="w-100">
                <div
                  className="mt-2 p-2 customcodeblock embededcodescroll"
                  style={{ height: 150, overflow: "scroll", overflowX: "hidden" }}
                >
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn bg-white shadow text-dark border-white btn-sm position-fixed"
                      color="primary"
                      onClick={copyIframeCode}
                    >
                      <Icon name={copyicon} className={copyicon === "check-thick" ? "text-success" : ""} />
                      <span className="customfont text-orange">{buttonText}</span>
                    </Button>
                  </div>
                  <code className="fw-bold">{iframeCode}</code>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-1">
                <h6 className="fw-bold customfont">
                  <Icon name="heart-fill" className="text-danger me-1" />
                  <abbr title="Save To Collection Now!">Want Minified Version?</abbr>
                </h6>
              </div>
            </ModalBody>
          </Modal>
          <Modal size="lg" isOpen={modalcust} toggle={customizemodel} className="modal-dialog-top">
            <ModalHeader
              toggle={customizemodel}
              close={
                <button className="close" onClick={customizemodel}>
                  <Icon name="cross" />
                </button>
              }
            >
              {" "}
              Customize your QR
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-center align-items-center ">
                {/* value={qrCodeData[activeIconTab]} value={generateQRText()} */}
                <QRCode value={combinedQRData} size={174} {...qrCodeStyle} logoImage={getLogoSrc()} />
                {logo && (
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      position: "absolute",
                      top: "23%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                {caption && (
                  <p className="caption" style={combinedStyles}>
                    {caption}
                  </p>
                )}
              </div>
              <Nav tabs className="mt-3 nav-tabs-s2">
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("1");
                    }}
                  >
                    {" "}
                    Style
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("2");
                    }}
                  >
                    {" "}
                    Color
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("3");
                    }}
                  >
                    {" "}
                    Logo
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "4" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("4");
                    }}
                  >
                    {" "}
                    QR Size
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "5" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("5");
                    }}
                  >
                    {" "}
                    Additional Text
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" style={{ height: "200px" }}>
                  <div className="d-flex justify-center">
                    <ul className="custom-control-group">
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio5"
                            checked={selectedStyle === "classic"}
                            onChange={() => handleStyleChange("classic")}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio5">
                            <img src={Classic} alt="Classic Qr" className="qrstyleimage" />
                            <span>Classic</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio6"
                            checked={selectedStyle === "rounded"}
                            onChange={() => handleStyleChange("rounded")}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio6">
                            <img src={Rounded} alt="Classic Qr" className="qrstyleimage" />
                            <span>Rounded</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio7"
                            checked={selectedStyle === "smooth"}
                            onChange={() => handleStyleChange("smooth")}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio7">
                            <img src={Smooth} alt="Classic Qr" className="qrstyleimage" />
                            <span>Smooth</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio3"
                            checked={selectedStyle === "thin"}
                            onChange={() => handleStyleChange("thin")}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio3">
                            <img src={Thin} alt="Classic Qr" className="qrstyleimage" />
                            <span>Thin</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio4"
                            checked={selectedStyle === "circles"}
                            onChange={() => handleStyleChange("circles")}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio4">
                            <img src={Circles} alt="Classic Qr" className="qrstyleimage" />
                            <span>Circles</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </TabPane>
                <TabPane tabId="2" style={{ height: "200px" }}>
                  <div className="d-md-flex mx-4 my-5">
                    <div className="form-group">
                      <Label htmlFor="default-0" className="form-label custom-opacity">
                        Background Color
                      </Label>
                      <div className="form-control-wrap d-flex ms-2 mb-2">
                        <Button
                          outline
                          color="primary"
                          className="btn-dim colrpickrbutton"
                          style={{ backgroundColor: color1 }}
                          onClick={handleButtonClick1}
                        ></Button>
                        <input type="text" className="form-control ms-1" value={color1} readOnly />
                        {showPicker1 && (
                          <div ref={pickerRef} style={{ position: "absolute", zIndex: 2, top: "2.5rem" }}>
                            <SketchPicker color={color1} onChange={handleColorChange1} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Label htmlFor="default-0" className="form-label custom-opacity">
                        Square Color
                      </Label>
                      <div className="form-control-wrap d-flex ms-2 mb-2 ">
                        <Button
                          outline
                          color="primary"
                          className="btn-dim colrpickrbutton"
                          style={{ backgroundColor: color2 }}
                          onClick={handleButtonClick2}
                        ></Button>
                        <input type="text" className="form-control ms-1" value={color2} readOnly />
                        {showPicker2 && (
                          <div ref={pickerRef} style={{ position: "absolute", zIndex: 2, top: "2.5rem" }}>
                            <SketchPicker color={color2} onChange={handleColorChange2} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Label htmlFor="default-0" className="form-label custom-opacity">
                        Pixel Color
                      </Label>
                      <div className="form-control-wrap d-flex ms-2 mb-2 ">
                        <Button
                          outline
                          color="primary"
                          className="btn-dim colrpickrbutton"
                          style={{ backgroundColor: color3 }}
                          onClick={handleButtonClick3}
                        ></Button>
                        <input type="text" className="form-control ms-1" value={color3} readOnly />
                        {showPicker3 && (
                          <div ref={pickerRef} style={{ position: "absolute", zIndex: 2, top: "2.5rem" }}>
                            <SketchPicker color={color3} onChange={handleColorChange3} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3" style={{ height: "200px" }}>
                  <div className="d-flex justify-content-center ">
                    <ul className="custom-control-group">
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="logoradio"
                            id="btnRadio20"
                            onChange={() => handleLogoChange("closeButton")}
                            checked={selectedLogo === "closeButton"}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio20">
                            <img src={CloseButton} alt="Close Button" className="logostyleimage" />
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="logoradio"
                            id="btnRadio21"
                            onChange={() => handleLogoChange("wifi")}
                            checked={selectedLogo === "wifi"}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio21">
                            <img src={wifiimage} alt="WiFi Image" className="logostyleimage" />
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="logoradio"
                            id="btnRadio22"
                            onChange={() => handleLogoChange("gmail")}
                            checked={selectedLogo === "gmail"}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio22">
                            <img src={gmailimage} alt="Gmail Image" className="logostyleimage" />
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="logoradio"
                            id="btnRadio23"
                            onChange={() => handleLogoChange("appstore")}
                            checked={selectedLogo === "appstore"}
                          />
                          <label className="custom-control-label d-flex flex-column" htmlFor="btnRadio23">
                            <img src={appstoreimage} alt="App Store Image" className="logostyleimage" />
                          </label>
                        </div>
                      </li>
                      {/* <li>
                        <div className="">
                          <CiCirclePlus
                            style={{ fontSize: "60", cursor: "pointer" }}
                            onClick={handleCiCirclePlusClick}
                          />
                          <input type="file" style={{ display: "none" }} accept="image/*" ref={fileInputRef2} />
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </TabPane>
                <TabPane tabId="4" style={{ height: "200px" }}>
                  <PreviewCard className={"border-0 "}>
                    <Row className="justify-content-center ">
                      <Col sm="8">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <Nouislider
                              className="form-range-slider"
                              accessibility
                              connect={[true, false]}
                              start={170}
                              range={{
                                min: 170,
                                max: 400,
                              }}
                              onChange={handleSliderChange}
                            ></Nouislider>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PreviewCard>
                </TabPane>
                <TabPane tabId="5" style={{ minHeight: "150px" }}>
                  <Row className="mb-md-3">
                    <Col xs="12" md="6">
                      <Label htmlFor="captionInput" className="form-label">
                        Caption
                      </Label>
                      <div className="form-control-wrap mb-2 ">
                        <input
                          className="form-control"
                          type="text"
                          id="captionInput"
                          placeholder="Enter caption"
                          value={caption}
                          onChange={(e) => setCaption(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="form-control-wrap mb-2">
                        <Label htmlFor="captionInput" className="form-label">
                          Text Color
                        </Label>
                        <div className="form-group d-flex ">
                          <Button
                            outline
                            color="primary"
                            className="btn-dim colrpickrbutton"
                            style={{ backgroundColor: color4 }}
                            onClick={handleButtonClick4}
                          ></Button>
                          <input type="text" className="form-control ms-1" value={color4} readOnly />
                          {showPicker4 && (
                            <div ref={pickerRef} style={{ position: "absolute", zIndex: 2, top: "2.5rem" }}>
                              <SketchPicker color={color4} onChange={handleColorChange4} />
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col xs="12" md="4" className="d-flex justify-content-center">
                      <div className="form-group">
                        <Label htmlFor="default-4" className="form-label">
                          Text Position
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" name="select" id="default-4" value={selectedPosition}
                              onChange={handlePosition}>
                              <option value="top">Top</option>
                              <option value="bottom">Bottom</option>
                            </Input>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    <Col xs="12" md="6" className="d-flex ">
                      <div className="form-group">
                        <Label htmlFor="default-4" className="form-label">
                          Text Size
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input
                              type="select"
                              name="select"
                              id="default-4"
                              value={selectedSize}
                              onChange={handleSizeChange}
                            >
                              <option value="1">14</option>
                              <option value="2">18</option>
                              <option value="3">22</option>
                              <option value="4">26</option>
                              <option value="5">30</option>
                            </Input>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" md="6" className="d-flex">
                      <div className="form-group">
                        <Label htmlFor="default-4" className="form-label">
                          Text Weight
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input
                              type="select"
                              name="select"
                              id="default-4"
                              value={selectedFont}
                              onChange={handleFontChange}
                            >
                              <option value="normal">normal</option>
                              <option value="bold">bold</option>
                            </Input>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
          </Modal>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default QrCodePage;
