import * as React from "react";
const ProductsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <circle r={256} cx={256} cy={256} fill="#f9e7ab" shape="circle" />
    <g transform="matrix(0.7,0,0,0.7,76.79999856948854,76.8001121878275)">
      <path
        d="M248.768 1.654 19.499 112.336a8.316 8.316 0 0 0-4.701 7.49v272.348a8.318 8.318 0 0 0 4.701 7.49l229.269 110.682a16.633 16.633 0 0 0 14.464 0l229.269-110.682a8.316 8.316 0 0 0 4.701-7.49V119.826a8.318 8.318 0 0 0-4.701-7.49L263.232 1.654a16.64 16.64 0 0 0-14.464 0z"
        style={{}}
        fill="#ffe17d"
        data-original="#ffe17d"
        className=""
        opacity={1}
      />
      <path
        d="M16.181 115.273a8.286 8.286 0 0 0-1.383 4.553v272.348a8.318 8.318 0 0 0 4.701 7.49l229.269 110.681a16.624 16.624 0 0 0 7.232 1.654V231.048L16.181 115.273z"
        style={{}}
        fill="#ffc350"
        data-original="#ffc350"
        className=""
      />
      <path
        d="M495.819 115.273a8.286 8.286 0 0 1 1.383 4.553v272.348a8.318 8.318 0 0 1-4.701 7.49L263.232 510.345A16.625 16.625 0 0 1 256 512V231.048l239.819-115.775z"
        style={{}}
        fill="#ffd164"
        data-original="#ffd164"
        className=""
      />
      <path
        d="M83.407 377.657a8.298 8.298 0 0 1-3.606-.828L44.404 359.74a8.31 8.31 0 0 1-3.874-11.103c1.99-4.134 6.977-5.856 11.103-3.874l35.397 17.09a8.31 8.31 0 0 1 3.874 11.103c-1.429 2.971-4.402 4.701-7.497 4.701z"
        style={{}}
        fill="#ffe17d"
        data-original="#ffe17d"
        className=""
        opacity={1}
      />
      <path
        d="M115.986 356.45a8.298 8.298 0 0 1-3.606-.828l-67.928-32.79c-4.134-1.998-5.872-6.969-3.874-11.103 1.99-4.126 6.985-5.856 11.103-3.874l67.927 32.79c4.134 1.998 5.872 6.969 3.874 11.103-1.428 2.971-4.401 4.702-7.496 4.702z"
        style={{}}
        fill="#fff6d8"
        data-original="#fff6d8"
        className=""
      />
      <path
        d="M414.549 154.373 173.887 38.11l-63.44 30.75 241.202 116.443z"
        style={{}}
        fill="#ff8087"
        data-original="#ff8087"
        className=""
      />
      <path
        d="M351.649 185.303v90.207c0 3.066 3.205 5.078 5.967 3.745l52.232-25.215a8.316 8.316 0 0 0 4.701-7.49v-92.176l-62.9 30.929z"
        style={{}}
        fill="#e6646e"
        data-original="#e6646e"
      />
    </g>
  </svg>
);
export default ProductsIcon;
