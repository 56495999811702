import { useState, useEffect } from "react";

const FourteenDaysCountDown = (endDate) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [expired, setExpired] = useState(false); // Add expired state

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const targetTime = new Date(endDate);
      const difference = targetTime.getTime() - now.getTime();

      if (difference <= 0) {
        clearInterval(intervalId);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setExpired(true); // Set expired to true when countdown ends
        return;
      }

      const remaining = difference;
      setDays(Math.floor(remaining / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      setMinutes(Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((remaining % (1000 * 60)) / 1000));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  return { days, hours, minutes, seconds, expired }; // Return expired state
};

export default FourteenDaysCountDown;
