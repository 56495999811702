export const GenericQrCode = ({ FormComponent, CommonQrComponent, qrText, BarCodeText, setToggle }) => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className=" shadow p-3 h-100 bg-white rounded">
          <div className="custom-control custom-radio m-4">
            <input type="radio" id="customRadio1" name="customRadio" defaultChecked className="custom-control-input" />{" "}
            <label
              className="custom-control-label text-dark fw-bold"
              htmlFor="customRadio1"
              onClick={() => setToggle(1)}
            >
              Existing Products
            </label>
            {/* <div className="custom-control custom-radio mx-4">
              <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" />{""}
              <label
                className="custom-control-label text-dark fw-bold"
                htmlFor="customRadio2"
                onClick={() => setToggle(2)}
              >
                New Products
              </label>{""}
            </div> */}
          </div>
          {FormComponent}
        </div>
      </div>
      <div className="col-lg-6">{CommonQrComponent}</div>
    </div>
  );
};
