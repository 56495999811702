import * as React from "react";
const BronzeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={18}
    height={17.6}
    x={0}
    y={0}
    viewBox="0 0 431.6 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className="hovered-paths"
    {...props}
  >
    <g>
      <g data-name="Shield Bronze">
        <path
          fill="#dba87b"
          d="M420.9 60.9 220.1.6a15.71 15.71 0 0 0-8.6 0L10.7 60.9A15.12 15.12 0 0 0 0 75.3v220.9c0 28.7 11.6 58.1 34.6 87.4 17.5 22.4 41.8 44.8 72 66.7a657.25 657.25 0 0 0 103.1 60.4 14.56 14.56 0 0 0 6.1 1.28 14.24 14.24 0 0 0 6.1-1.3c2.1-.9 52.2-23.5 103.1-60.4 30.3-21.9 54.5-44.4 72-66.7 22.9-29.3 34.6-58.7 34.6-87.4v-221a14.88 14.88 0 0 0-10.7-14.28zm-19.1 235.3h-.2c0 31.2-19.2 61.2-43.9 86.9-15.6 16.2-33.4 30.7-49.9 42.7-15.29 11.07-30.65 20.87-44.54 29.1l-.85.5c-20.61 12.19-37.88 20.88-46.61 25.1h-.1c-14.8-7.1-53.6-26.9-91.9-54.6-41.49-30.05-90.69-75.8-93.65-124.92-.1-1.55-.15-3.1-.15-4.66V86.4l185.8-55.7h.1l59.9 18 126 37.8z"
          opacity={1}
          data-original="#dba87b"
          className=""
        />
        <path
          fill="#d57e22"
          d="M404.34 84.1v212.68h-.1c0 31.64-19.48 62.07-44.53 88.13-15.82 16.43-33.87 31.14-50.6 43.31-15.51 11.23-31.09 21.16-45.18 29.51l-.86.51c-20.93 12.3-38.46 21.08-47.37 25.35-15-7.2-54.36-27.28-93.21-55.37-42-30.46-91.9-76.82-95-126.61-.1-1.58-.15-3.15-.15-4.73V83.98L215.8 27.51l60.75 18.26z"
          opacity={1}
          data-original="#d57e22"
          className=""
        />
        <path
          fill="#b96d1f"
          d="M404.34 83.77v212.68h-.09c0 31.64-19.48 62.07-44.54 88.13-1.2 1.26-2.44 2.51-3.68 3.75-.63.65-1.28 1.29-1.94 1.93l-.77.76c-.91.88-1.8 1.75-2.72 2.61s-2 1.93-3 2.88l-3.22 2.94q-1.59 1.44-3.18 2.84t-3.16 2.69q-4.87 4.23-9.81 8.21c-1 .85-2.1 1.7-3.15 2.53-1.87 1.49-3.74 2.94-5.58 4.37l-4.32.67-4.13-10.7a712.72 712.72 0 0 1-39.17-366.03l4.66 1.4z"
          opacity={1}
          data-original="#b96d1f"
          className=""
        />
      </g>
      <g data-name="Circle Bronze">
        <circle
          cx={215.9}
          cy={234.15}
          r={115.53}
          fill="#e7b484"
          opacity={1}
          data-original="#e7b484"
          className="hovered-path"
        />
        <path
          fill="#d79d6d"
          d="M297.05 316.38a115.51 115.51 0 0 1-162.9-.59 115.51 115.51 0 0 1 162.9.59z"
          opacity={1}
          data-original="#d79d6d"
        />
      </g>
      <g data-name="Leaves 1">
        <g fill="#76ca00">
          <path
            d="M82.97 347.98c-4.55 12.79-14.68 20.88-22.62 18.06s-10.7-15.49-6.15-28.29c3.68-10.34 18.55-21.51 24.11-25.41a1.84 1.84 0 0 1 2.77 1c1.65 6.47 5.56 24.26 1.89 34.64zM40.29 332.58c8.54 6.5 11.45 17 6.51 23.52s-15.87 6.49-24.41 0c-7-5.3-8.88-23-9.35-29.33a2 2 0 0 1 2-2.08c5.13.62 18.45 2.71 25.25 7.89zM101.38 400.77c-4.47 12.57-13.77 20.74-20.77 18.25s-9.07-14.7-4.6-27.27c3.51-9.86 12-21.82 15.43-26.5a1.53 1.53 0 0 1 2.62.25c3.02 6.48 10.97 24.99 7.32 35.27zM142.61 429.16c2.56 13-2.31 24.94-10.87 26.62s-17.58-7.5-20.14-20.52c-2.07-10.53 5.44-27.44 8.4-33.53a2 2 0 0 1 3-.55c4.8 4.59 17.54 17.48 19.61 27.98zM45.56 382.67c10.15 4.5 15.7 14.17 12.41 21.59s-14.17 9.79-24.34 5.29c-8.27-3.67-14.63-20.57-16.71-26.65a2 2 0 0 1 1.45-2.47c5.3-.45 19.11-1.34 27.19 2.24zM80.55 430.92c11.54 1 20.31 8.62 19.57 17s-10.67 14.37-22.21 13.36c-9.4-.82-21.4-15.21-25.53-20.53a2 2 0 0 1 .59-2.9c5.07-2.26 18.4-7.73 27.58-6.93zM134 463.98c12.84 1.12 22.62 9.1 21.86 17.83s-11.78 14.88-24.61 13.76c-10.46-.91-23.88-16-28.5-21.52a2 2 0 0 1 .63-3c5.62-2.38 20.42-8 30.62-7.07zM206.87 446.88c9.7 12.61 10.11 28.57.92 35.65s-24.53 2.59-34.23-10c-7.85-10.2-7.71-33.22-7.37-41.66a2.81 2.81 0 0 1 3.24-2.45c8.04 2.02 29.61 8.28 37.44 18.46zM62.15 298.84c-2.28 14.8-11.26 25.69-20.06 24.34S28 308.73 30.28 293.98c1.9-12.37 16.08-28.23 20.69-33.11a1.4 1.4 0 0 1 2.31.39c2.73 6 10.77 25.24 8.87 37.58z"
            fill="#76ca00"
            opacity={1}
            data-original="#76ca00"
            className=""
          />
          <path
            d="M82.97 347.98c-4.55 12.79-14.68 20.88-22.62 18.06s-10.7-15.49-6.15-28.29c3.68-10.34 18.55-21.51 24.11-25.41a1.84 1.84 0 0 1 2.77 1c1.65 6.47 5.56 24.26 1.89 34.64zM40.29 332.58c8.54 6.5 11.45 17 6.51 23.52s-15.87 6.49-24.41 0c-7-5.3-8.88-23-9.35-29.33a2 2 0 0 1 2-2.08c5.13.62 18.45 2.71 25.25 7.89zM101.38 400.77c-4.47 12.57-13.77 20.74-20.77 18.25s-9.07-14.7-4.6-27.27c3.51-9.86 12-21.82 15.43-26.5a1.53 1.53 0 0 1 2.62.25c3.02 6.48 10.97 24.99 7.32 35.27zM142.61 429.16c2.56 13-2.31 24.94-10.87 26.62s-17.58-7.5-20.14-20.52c-2.07-10.53 5.44-27.44 8.4-33.53a2 2 0 0 1 3-.55c4.8 4.59 17.54 17.48 19.61 27.98zM45.56 382.67c10.15 4.5 15.7 14.17 12.41 21.59s-14.17 9.79-24.34 5.29c-8.27-3.67-14.63-20.57-16.71-26.65a2 2 0 0 1 1.45-2.47c5.3-.45 19.11-1.34 27.19 2.24zM80.55 430.92c11.54 1 20.31 8.62 19.57 17s-10.67 14.37-22.21 13.36c-9.4-.82-21.4-15.21-25.53-20.53a2 2 0 0 1 .59-2.9c5.07-2.26 18.4-7.73 27.58-6.93zM134 463.98c12.84 1.12 22.62 9.1 21.86 17.83s-11.78 14.88-24.61 13.76c-10.46-.91-23.88-16-28.5-21.52a2 2 0 0 1 .63-3c5.62-2.38 20.42-8 30.62-7.07zM206.87 446.88c9.7 12.61 10.11 28.57.92 35.65s-24.53 2.59-34.23-10c-7.85-10.2-7.71-33.22-7.37-41.66a2.81 2.81 0 0 1 3.24-2.45c8.04 2.02 29.61 8.28 37.44 18.46zM62.15 298.84c-2.28 14.8-11.26 25.69-20.06 24.34S28 308.73 30.28 293.98c1.9-12.37 16.08-28.23 20.69-33.11a1.4 1.4 0 0 1 2.31.39c2.73 6 10.77 25.24 8.87 37.58z"
            fill="#76ca00"
            opacity={1}
            data-original="#76ca00"
            className=""
          />
          <path
            d="M82.97 347.98c-4.55 12.79-14.68 20.88-22.62 18.06s-10.7-15.49-6.15-28.29c3.68-10.34 18.55-21.51 24.11-25.41a1.84 1.84 0 0 1 2.77 1c1.65 6.47 5.56 24.26 1.89 34.64zM40.29 332.58c8.54 6.5 11.45 17 6.51 23.52s-15.87 6.49-24.41 0c-7-5.3-8.88-23-9.35-29.33a2 2 0 0 1 2-2.08c5.13.62 18.45 2.71 25.25 7.89zM101.38 400.77c-4.47 12.57-13.77 20.74-20.77 18.25s-9.07-14.7-4.6-27.27c3.51-9.86 12-21.82 15.43-26.5a1.53 1.53 0 0 1 2.62.25c3.02 6.48 10.97 24.99 7.32 35.27zM142.61 429.16c2.56 13-2.31 24.94-10.87 26.62s-17.58-7.5-20.14-20.52c-2.07-10.53 5.44-27.44 8.4-33.53a2 2 0 0 1 3-.55c4.8 4.59 17.54 17.48 19.61 27.98zM45.56 382.67c10.15 4.5 15.7 14.17 12.41 21.59s-14.17 9.79-24.34 5.29c-8.27-3.67-14.63-20.57-16.71-26.65a2 2 0 0 1 1.45-2.47c5.3-.45 19.11-1.34 27.19 2.24zM80.55 430.92c11.54 1 20.31 8.62 19.57 17s-10.67 14.37-22.21 13.36c-9.4-.82-21.4-15.21-25.53-20.53a2 2 0 0 1 .59-2.9c5.07-2.26 18.4-7.73 27.58-6.93zM134 463.98c12.84 1.12 22.62 9.1 21.86 17.83s-11.78 14.88-24.61 13.76c-10.46-.91-23.88-16-28.5-21.52a2 2 0 0 1 .63-3c5.62-2.38 20.42-8 30.62-7.07zM206.87 446.88c9.7 12.61 10.11 28.57.92 35.65s-24.53 2.59-34.23-10c-7.85-10.2-7.71-33.22-7.37-41.66a2.81 2.81 0 0 1 3.24-2.45c8.04 2.02 29.61 8.28 37.44 18.46z"
            fill="#76ca00"
            opacity={1}
            data-original="#76ca00"
            className=""
          />
        </g>
        <path
          fill="#68b401"
          d="M216.85 491.12q-5.62 0-11.14-.26c-30.14-1.42-55.28-8.44-72.7-20.31-1.47-1-3.7-2.33-6.29-3.87-12.22-7.25-32.68-19.4-51.12-41.88-22.43-27.34-34.8-61.74-36.65-102.23a7.008 7.008 0 1 1 14-.65c4.05 87.06 57.92 119.06 80.92 132.73 2.78 1.65 5.19 3.08 7 4.33 30.52 20.78 91.93 24.15 145.93 8a7 7 0 1 1 4 13.41 263.64 263.64 0 0 1-73.95 10.73z"
          opacity={1}
          data-original="#68b401"
        />
        <path
          fill="#76ca00"
          d="M62.15 298.84c-2.28 14.8-11.26 25.69-20.06 24.34S28 308.73 30.28 293.98c1.9-12.37 16.08-28.23 20.69-33.11a1.4 1.4 0 0 1 2.31.39c2.73 6 10.77 25.24 8.87 37.58z"
          opacity={1}
          data-original="#76ca00"
          className=""
        />
      </g>
      <g data-name="Leaves 2">
        <g fill="#76ca00">
          <path
            d="M348.8 346.98c4.55 12.79 14.67 20.88 22.62 18.06s10.69-15.49 6.14-28.29c-3.67-10.34-18.55-21.51-24.11-25.41a1.84 1.84 0 0 0-2.77 1c-1.66 6.47-5.57 24.26-1.88 34.64zM391.46 331.58c-8.54 6.5-11.46 17-6.51 23.52s15.87 6.49 24.41 0c6.95-5.3 8.88-23 9.35-29.33a2 2 0 0 0-2-2.08c-5.14.62-18.46 2.71-25.25 7.89zM330.36 399.77c4.47 12.57 13.77 20.74 20.78 18.25s9.06-14.7 4.6-27.27c-3.51-9.86-12-21.82-15.43-26.5a1.53 1.53 0 0 0-2.62.25c-3.03 6.48-10.98 24.99-7.33 35.27zM289.14 428.16c-2.56 13 2.31 24.94 10.87 26.62s17.58-7.5 20.14-20.52c2.07-10.53-5.45-27.44-8.4-33.53a2 2 0 0 0-3-.55c-4.84 4.59-17.54 17.48-19.61 27.98zM386.19 381.67c-10.15 4.5-15.71 14.17-12.41 21.59s14.19 9.79 24.33 5.29c8.27-3.67 14.69-20.57 16.69-26.65a2 2 0 0 0-1.44-2.47c-5.29-.45-19.1-1.34-27.17 2.24zM351.19 429.92c-11.54 1-20.3 8.62-19.57 17s10.68 14.38 22.18 13.37c9.4-.82 21.4-15.21 25.52-20.53a2 2 0 0 0-.59-2.9c-5.02-2.27-18.36-7.74-27.54-6.94zM297.74 462.98c-12.83 1.12-22.62 9.1-21.86 17.83s11.78 14.88 24.62 13.76c10.45-.91 23.88-16 28.5-21.52a2 2 0 0 0-.64-3c-5.62-2.38-20.4-8-30.62-7.07zM224.88 445.88c-9.71 12.61-10.12 28.57-.92 35.65s24.52 2.59 34.22-10c7.85-10.2 7.72-33.22 7.38-41.66a2.81 2.81 0 0 0-3.25-2.45c-8.04 2.02-29.6 8.28-37.43 18.46zM369.59 297.84c2.28 14.8 11.27 25.69 20.07 24.34s14.14-14.45 11.81-29.2c-1.91-12.37-16.08-28.23-20.69-33.11a1.41 1.41 0 0 0-2.32.39c-2.72 6-10.77 25.24-8.87 37.58z"
            fill="#76ca00"
            opacity={1}
            data-original="#76ca00"
            className=""
          />
          <path
            d="M348.8 346.98c4.55 12.79 14.67 20.88 22.62 18.06s10.69-15.49 6.14-28.29c-3.67-10.34-18.55-21.51-24.11-25.41a1.84 1.84 0 0 0-2.77 1c-1.66 6.47-5.57 24.26-1.88 34.64zM391.46 331.58c-8.54 6.5-11.46 17-6.51 23.52s15.87 6.49 24.41 0c6.95-5.3 8.88-23 9.35-29.33a2 2 0 0 0-2-2.08c-5.14.62-18.46 2.71-25.25 7.89zM330.36 399.77c4.47 12.57 13.77 20.74 20.78 18.25s9.06-14.7 4.6-27.27c-3.51-9.86-12-21.82-15.43-26.5a1.53 1.53 0 0 0-2.62.25c-3.03 6.48-10.98 24.99-7.33 35.27zM289.14 428.16c-2.56 13 2.31 24.94 10.87 26.62s17.58-7.5 20.14-20.52c2.07-10.53-5.45-27.44-8.4-33.53a2 2 0 0 0-3-.55c-4.84 4.59-17.54 17.48-19.61 27.98zM386.19 381.67c-10.15 4.5-15.71 14.17-12.41 21.59s14.19 9.79 24.33 5.29c8.27-3.67 14.69-20.57 16.69-26.65a2 2 0 0 0-1.44-2.47c-5.29-.45-19.1-1.34-27.17 2.24zM351.19 429.92c-11.54 1-20.3 8.62-19.57 17s10.68 14.38 22.18 13.37c9.4-.82 21.4-15.21 25.52-20.53a2 2 0 0 0-.59-2.9c-5.02-2.27-18.36-7.74-27.54-6.94zM297.74 462.98c-12.83 1.12-22.62 9.1-21.86 17.83s11.78 14.88 24.62 13.76c10.45-.91 23.88-16 28.5-21.52a2 2 0 0 0-.64-3c-5.62-2.38-20.4-8-30.62-7.07zM224.88 445.88c-9.71 12.61-10.12 28.57-.92 35.65s24.52 2.59 34.22-10c7.85-10.2 7.72-33.22 7.38-41.66a2.81 2.81 0 0 0-3.25-2.45c-8.04 2.02-29.6 8.28-37.43 18.46z"
            fill="#76ca00"
            opacity={1}
            data-original="#76ca00"
            className=""
          />
        </g>
        <path
          fill="#76ca00"
          d="M369.59 297.84c2.28 14.8 11.27 25.69 20.07 24.34s14.14-14.45 11.81-29.2c-1.91-12.37-16.08-28.23-20.69-33.11a1.41 1.41 0 0 0-2.32.39c-2.72 6-10.77 25.24-8.87 37.58zM348.8 346.98c4.55 12.79 14.67 20.88 22.62 18.06s10.69-15.49 6.14-28.29c-3.67-10.34-18.55-21.51-24.11-25.41a1.84 1.84 0 0 0-2.77 1c-1.66 6.47-5.57 24.26-1.88 34.64zM391.46 331.58c-8.54 6.5-11.46 17-6.51 23.52s15.87 6.49 24.41 0c6.95-5.3 8.88-23 9.35-29.33a2 2 0 0 0-2-2.08c-5.14.62-18.46 2.71-25.25 7.89zM330.36 399.77c4.47 12.57 13.77 20.74 20.78 18.25s9.06-14.7 4.6-27.27c-3.51-9.86-12-21.82-15.43-26.5a1.53 1.53 0 0 0-2.62.25c-3.03 6.48-10.98 24.99-7.33 35.27zM289.14 428.16c-2.56 13 2.31 24.94 10.87 26.62s17.58-7.5 20.14-20.52c2.07-10.53-5.45-27.44-8.4-33.53a2 2 0 0 0-3-.55c-4.84 4.59-17.54 17.48-19.61 27.98zM386.19 381.67c-10.15 4.5-15.71 14.17-12.41 21.59s14.19 9.79 24.33 5.29c8.27-3.67 14.69-20.57 16.69-26.65a2 2 0 0 0-1.44-2.47c-5.29-.45-19.1-1.34-27.17 2.24zM351.19 429.92c-11.54 1-20.3 8.62-19.57 17s10.68 14.38 22.18 13.37c9.4-.82 21.4-15.21 25.52-20.53a2 2 0 0 0-.59-2.9c-5.02-2.27-18.36-7.74-27.54-6.94zM297.74 462.98c-12.83 1.12-22.62 9.1-21.86 17.83s11.78 14.88 24.62 13.76c10.45-.91 23.88-16 28.5-21.52a2 2 0 0 0-.64-3c-5.62-2.38-20.4-8-30.62-7.07zM224.88 445.88c-9.71 12.61-10.12 28.57-.92 35.65s24.52 2.59 34.22-10c7.85-10.2 7.72-33.22 7.38-41.66a2.81 2.81 0 0 0-3.25-2.45c-8.04 2.02-29.6 8.28-37.43 18.46z"
          opacity={1}
          data-original="#76ca00"
          className=""
        />
        <path
          fill="#68b401"
          d="M214.89 490.12a263.64 263.64 0 0 1-74-10.71 7 7 0 0 1 4-13.41c54.05 16.13 115.41 12.76 145.91-8 1.84-1.25 4.24-2.68 7-4.33 23.08-13.69 77-45.69 81-132.75a7.008 7.008 0 1 1 14 .65c-1.89 40.49-14.22 74.89-36.65 102.23-18.44 22.48-38.9 34.63-51.13 41.88-2.58 1.54-4.81 2.86-6.29 3.87-17.42 11.87-42.56 18.89-72.69 20.31q-5.52.26-11.15.26z"
          opacity={1}
          data-original="#68b401"
        />
        <path
          fill="#76ca00"
          d="M369.59 297.84c2.28 14.8 11.27 25.69 20.07 24.34s14.14-14.45 11.81-29.2c-1.91-12.37-16.08-28.23-20.69-33.11a1.41 1.41 0 0 0-2.32.39c-2.72 6-10.77 25.24-8.87 37.58z"
          opacity={1}
          data-original="#76ca00"
          className=""
        />
      </g>
      <path
        fill="#ffcd20"
        d="m222.8 146.33 22.13 44.84a9.29 9.29 0 0 0 7 5.07l49.48 7.19a7.75 7.75 0 0 1 4.3 13.21l-36.39 35.47a7.76 7.76 0 0 0-2.23 6.85l8.59 50.08a7.74 7.74 0 0 1-11.23 8.17l-45-23.65a7.76 7.76 0 0 0-7.2 0l-45 23.65a7.74 7.74 0 0 1-11.23-8.17l8.66-50.06a7.76 7.76 0 0 0-2.23-6.85l-36.39-35.47a7.75 7.75 0 0 1 4.3-13.21l50.28-7.3a7.75 7.75 0 0 0 5.83-4.24l22.49-45.56a7.73 7.73 0 0 1 13.84-.02z"
        opacity={1}
        data-original="#ffcd20"
        className=""
      />
      <g fill="#fdfbfa">
        <path
          d="m352.12 49.27 1.53 18.53a1.41 1.41 0 0 0 .78 1.15l16.59 8.41a1.41 1.41 0 0 1-.52 2.67l-18.54 1.54a1.41 1.41 0 0 0-1.15.77l-8.4 16.6a1.42 1.42 0 0 1-2.68-.53l-1.53-18.54a1.44 1.44 0 0 0-.78-1.15l-16.62-8.4a1.42 1.42 0 0 1 .52-2.68l18.54-1.53a1.41 1.41 0 0 0 1.15-.77l8.4-16.6a1.42 1.42 0 0 1 2.71.53z"
          fill="#fdfbfa"
          opacity={1}
          data-original="#fdfbfa"
        />
        <path
          d="m153.52 314.16 2.81 9.93a.77.77 0 0 0 .54.54l9.93 2.81a.79.79 0 0 1 0 1.52l-9.93 2.81a.77.77 0 0 0-.54.54l-2.81 9.93a.79.79 0 0 1-1.52 0l-2.81-9.93a.77.77 0 0 0-.54-.54l-9.93-2.79a.79.79 0 0 1 0-1.52l9.93-2.81a.77.77 0 0 0 .54-.54l2.81-9.93a.79.79 0 0 1 1.52-.02z"
          data-name="Star central"
          fill="#fdfbfa"
          opacity={1}
          data-original="#fdfbfa"
        />
        <path
          d="m158.94 122.53-.64 7.76a.6.6 0 0 1-.33.48l-6.94 3.52a.59.59 0 0 0 .22 1.12l7.76.64a.6.6 0 0 1 .48.32l3.51 6.94a.59.59 0 0 0 1.12-.22l.64-7.75a.58.58 0 0 1 .33-.48l6.94-3.52a.59.59 0 0 0-.22-1.12l-7.76-.64a.62.62 0 0 1-.48-.32l-3.51-6.95a.59.59 0 0 0-1.12.22z"
          fill="#fdfbfa"
          opacity={1}
          data-original="#fdfbfa"
        />
      </g>
    </g>
  </svg>
);
export default BronzeIcon;
