import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { Icon } from "../components/Component";

import Silomarketplace from "./Silomarketplace";
import Others from "./Others";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Head from "../layout/head/Head";
import Barcodes from "./Barcodes";
import SKU from "./SKU";

export default function ProductQRCode() {
  const [toggle, setToggle] = useState(1);
  console.log(toggle);

  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  return (
    <>
      <Head title="Add Products"></Head>
      <div className="container-fluid">
        <div className="d-flex justify-content-between my-4">
          <h3 className="fs-1 customfont">Add Products</h3>
          <Link to="/products">
            {" "}
            <Button color="" className="bg-primary text-white productheadercms">
              <Icon name="arrow-long-left" />
              <span> My Products</span>
            </Button>{" "}
          </Link>
        </div>
        <Nav tabs className="p-3 bg-white rounded">
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeIconTab === "1" })}
              onClick={(ev) => {
                setToggle(1);
                ev.preventDefault();
                toggleIconTab("1");
              }}
            >
              <span>Silo Marketplace</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeIconTab === "2" })}
              onClick={(ev) => {
                setToggle(2);
                ev.preventDefault();
                toggleIconTab("2");
              }}
            >
              <span>Others</span>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeIconTab === "3" })}
              onClick={(ev) => {
                setToggle(3);
                ev.preventDefault();
                toggleIconTab("3");
              }}
            >
              <span>Barcodes</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeIconTab === "4" })}
              onClick={(ev) => {
                setToggle(4);
                ev.preventDefault();
                toggleIconTab("4");
              }}
            >
              <span>SKU</span>
            </NavLink>
          </NavItem> */}
        </Nav>

        {toggle === 1 ? <Silomarketplace /> : toggle === 2 ? <Others /> : toggle === 3 ? <Barcodes /> : <SKU />}
      </div>
    </>
  );
}
