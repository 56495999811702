import * as React from "react";
const FourteenDays = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    x={0}
    y={0}
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <circle r={16} cx={16} cy={16} fill="#ff8683" shape="circle" />
    <g transform="matrix(0.7,0,0,0.7,4.800000000000001,4.800000000000001)">
      <path
        fill="#f5f5f5"
        d="M2 10v17a3.003 3.003 0 0 0 3 3h22a3.003 3.003 0 0 0 3-3V10z"
        opacity={1}
        data-original="#f5f5f5"
        className=""
      />
      <path
        fill="#e53935"
        d="M27 4H5a3.003 3.003 0 0 0-3 3v3h28V7a3.003 3.003 0 0 0-3-3z"
        opacity={1}
        data-original="#e53935"
        className=""
      />
      <g fill="#424242">
        <path
          d="M23 8a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zM9 8a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zM16 8a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zM11.863 13.632a1.177 1.177 0 0 0-.613.116l-2.603 1.559a.817.817 0 0 0-.191 1.302.789.789 0 0 0 1.036.14l1.377-.817v8.46a.836.836 0 0 0 .878.847.852.852 0 0 0 .912-.846v-9.915a.795.795 0 0 0-.796-.846zM23.56 20.637a.755.755 0 0 0-.555-.223h-.829v-5.936a.84.84 0 0 0-1.509-.514l-5.273 6.599a.834.834 0 0 0 .614 1.376h4.51v2.454a.808.808 0 0 0 .812.846.835.835 0 0 0 .846-.846v-2.454h.83a.762.762 0 0 0 .555-1.302zm-3.042-.223h-3.114l3.114-3.98z"
          fill="#424242"
          opacity={1}
          data-original="#424242"
          className=""
        />
      </g>
    </g>
  </svg>
);
export default FourteenDays;
