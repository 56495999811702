// QrCodeContext.js
import React, { createContext, useState } from 'react';

export const QrCodeContext = createContext();

export const QrCodeProvider = ({ children }) => {
  const [qrState, setQrState] = useState(false);

  const updateQrState = () => {
    setQrState((prev) => !prev); // Toggle state to notify change
  };

  return (
    <QrCodeContext.Provider value={{ qrState, updateQrState }}>
      {children}
    </QrCodeContext.Provider>
  );
};
