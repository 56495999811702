import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Block, BlockTitle, Button, Col, Icon, Row } from "../components/Component";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { base64ToBlob, getCookie } from "../utils/Utils";
import "./css/Qrcode.css";
import Lottie from "react-lottie";
import animationData from "../images/lottie/qranimationloader.json";
const QrCodeCollection = () => {
  const [qrdata, setQrData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/fetch-qr`, {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken", null),
          },
          body: JSON.stringify({ page: currentPage }),
        });
        const data = await response.json();
        console.log(data);
        if (data.status) {
          setQrData(data.data.filesData);
          const total = data.data.recordsTotal;
          setTotalPages(Math.ceil(total / 3));
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };
    fetchData();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 3;
    const pageCount = Math.min(totalPages, maxVisiblePages);
    if (pageCount > 0) {
      items.push(
        <PaginationItem key="prev" disabled={currentPage === 1}>
          <PaginationLink className="custom-prev-link" onClick={() => handlePageChange(currentPage - 1)}>
            <Icon name="chevrons-left" />
            <span>Prev</span>
          </PaginationLink>
        </PaginationItem>
      );

      for (let i = 1; i <= pageCount; i++) {
        items.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink className="custom-page-link" onClick={() => handlePageChange(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
      items.push(
        <PaginationItem key="next" disabled={currentPage === totalPages}>
          <PaginationLink className="custom-next-link" onClick={() => handlePageChange(currentPage + 1)}>
            <span>Next</span>
            <Icon name="chevrons-right" />
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  const loadingoptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  //doenload option
  const handleDownload = (base64Data, fileName) => {
    const blob = base64ToBlob(base64Data, "image/png");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <React.Fragment>
      <Head title="Qr Collections" />
      {loader && (
        <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
          <Lottie options={loadingoptions} height={200} width={200} />
          {/* <p>Please Wait</p> */}
        </div>
      )}
      <Content>
        <Block>
          {/* <Row>
            <Col md="12">
              <UncontrolledAlert className="alert-fill alert-icon" color="danger" fade={false}>
                <Icon name="cross-circle" />
                <strong>YOUR 14-DAY TRIAL HAS ENDED</strong>
                <span className="ms-3">
                  To continue using SiloQr, <span className="text-decoration-underline">Upgrade your account</span>
                </span>
              </UncontrolledAlert>
            </Col>
          </Row> */}
          <Row className="mt-2">
            <Col md="12">
              <div className="d-flex justify-content-between">
                <div>
                  <BlockTitle tag="h6" className="d-flex align-center text-black-50">
                    <span>
                      <Icon name="view-list-wd" className="fs-2 icons-color" />
                    </span>
                    <span className="ms-2">All QR Codes (1)</span>
                  </BlockTitle>
                </div>
                <div>
                  <Button
                    className="btn btn-icon me-2 bg-lighter text-dark border-1 border-light shadow"
                    color="primary"
                  >
                    <Icon name="filter" />
                  </Button>
                  <Link to="/">
                    <Button color="primary">
                      <Icon name="plus" />
                      <span>CREATE QR CODE</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="12">
              <div> 
                {qrdata &&
                  qrdata.map((item, index) => (
                    <div
                      key={item.id}
                      className="card card-bordered w-100 d-flex flex-row justify-content-between align-items-center row m-auto mt-2"
                    >
                      <Col md="6" className="d-flex justify-content-around">
                        <div className="d-flex align-items-center">
                          <span className="badge-dim badge bg-secondary">{(currentPage - 1) * 3 + index + 1}</span>
                        </div>
                        <div>
                          <table className="align-middle">
                            <tbody>
                              <tr>
                                <td></td>
                                <td className="icons-color">
                                  <small className="text-uppercase ">{item.type}</small>
                                </td>
                              </tr>
                              <tr className="icons-color2">
                                <td>
                                  {/* Assuming Icon is a custom component */}
                                  <Icon name="file-text" className="fs-1" />
                                </td>
                                <td>
                                  <h4 className="fw-light icons-color2">{item.qr_name}</h4>
                                </td>
                              </tr>
                              <tr className="icons-color">
                                <td>
                                  {/* Assuming Icon is a custom component */}
                                  <Icon name="clock" className="fs-2" />
                                </td>
                                <td>
                                  {new Date(item.timestamp).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric",
                                  })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="icons-color2">
                            <span className="ms-1">Static/Non-trackable</span>
                          </p>
                        </div>
                      </Col>
                      <Col md="6" className="d-flex justify-content-around">
                        <div className="d-flex flex-column justify-content-center text-center invisible">
                          <h4 className="icons-color2 m-0">???</h4>
                          <p>scans</p>
                        </div>
                        <div>
                          {/* Assuming sampleqr is the path to your QR code image */}
                          <img src={item.path} alt="" className="qrimagesize" />
                        </div>
                        <div className="d-flex align-center">
                          <button className="qrdownloadlist" onClick={() => handleDownload(item.downloadFile, item.qr_name)}>
                            Download
                          </button>
                        </div>
                      </Col>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-2">
            <Pagination aria-label="Page navigation example">{renderPaginationItems()}</Pagination>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default QrCodeCollection;
