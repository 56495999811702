import React, { useEffect, useState, useContext } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Button, Col, Icon, Row } from "../components/Component";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Badge,
  ModalBody,
  Modal,
  UncontrolledAlert,
  ModalHeader,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { base64ToBlob, getCookie, getDateAfterTwoWeeks } from "../utils/Utils";
import "./css/Qrcode.css";
import "./css/QrCodecms.css";
import Lottie from "react-lottie";
import animationData from "../images/lottie/qranimationloader.json";
// import trashBinanimation from "../images/trashBin.json";
import { RSelect } from "../components/Component";
import silologo from "../images/silologo.svg";
import NoResultFound from "../images/NoResultsPNG.png";
import Swal from "sweetalert2";
import debounce from "lodash.debounce";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import EmbededCode from "./EmbededCode";
import LoadingBar from "react-top-loading-bar";
import { SubscriptionContext } from "./SubscriptionContext";
import Fancybox from "./components/FancyBox";
import Skeleton from "react-loading-skeleton";
import SubscriptionEnds from "../images/package_type/SubscriptionEnds";
import FourteenDaysCountDown from "./hooks/FourteenDaysCountDown";
import Cookies from "js-cookie";
import { QrCodeContext } from "./QrCodeContext";
// import { SubscriptionContext } from "./SubscriptionContext";
const QrCodeCmsNew = () => {
  //check subscription start
  //check if limit exceed
  const { contextData, apiData } = useContext(SubscriptionContext);
  const [subscription] = contextData;
  const [apiWhat] = apiData;
  // console.log(apiWhat?.data?.upgradeModel);
  const navigate = useNavigate();
  const [limitexceed, setLimitExceeded] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [subscriptionended, setSubscriptionEnds] = useState(false);
  const [modalZoom2, setModalZoom2] = useState(false);
  const toggleZoom2 = () => {
    setModalZoom2(!modalZoom2);
    // if (!modalZoom2) {
    const now = new Date();
    const expires = new Date(now.getTime() + 1 * 60 * 60 * 1000);
    Cookies.set("isModalOpen", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    // }
  };
  const [subscriptionModal, setSubscriptionModal] = useState(true);
  const checkifLimit = () => {
    if (apiWhat?.data?.upgradeModel) {
      setLimitExceeded(true);
    }
  };
  const checkifEnds = () => {
    if (apiWhat?.data?.subscription_end) {
      setSubscriptionEnds(true);
      if (subscriptionModal) {
        setModalZoom2(true);
      }
    }
  };
  useEffect(() => {
    checkifEnds();
  }, [apiWhat?.data?.subscription_end]);

  const ishavingsubscription = () => {
    if (subscription) {
      setIsSubscription(true);
    }
  };
  useEffect(() => {
    checkifLimit();
    ishavingsubscription();
  }, [apiWhat?.data?.upgradeModel]);
  // console.log(subscription);
  //check if trial ends
  const [traildate, setTrialdate] = useState();
  const [countdownloader, setcountdownloader] = useState(false);
  const [alertTime, setAlertTime] = useState(true);
  const fourteenDayTrial = () => {
    if (apiWhat?.data?.trial_end_date) {
      setTrialdate(apiWhat?.data?.trial_end_date);
    }
  };
  useEffect(() => {
    fourteenDayTrial();
    // setAlertTime(true);
    setcountdownloader(true);
    setTimeout(() => {
      setcountdownloader(false);
    }, 1000);
    setTimeout(() => {
      setAlertTime(false);
    }, 8000);
  }, [apiWhat?.data?.trial_end_date]);
  // console.log("Trial Date"+traildate);
  const qrscan_type = [
    { value: "all", label: "All" },
    { value: "0", label: "STATIC" },
    { value: "1", label: "DYNAMIC" },
    { value: "3", label: "EXPIRED" },
  ];
  const qrtype = [
    { value: "all", label: "All" },
    { value: "url", label: "WEBSITE" },
    { value: "text", label: "TEXT" },
    { value: "contact", label: "CONTACT" },
    { value: "map", label: "MAP" },
    { value: "wifi", label: "WI-FI" },
    { value: "appstore", label: "APP STORE" },
    { value: "email", label: "EMAIL" },
    { value: "pdf", label: "PDF" },
  ];
  const sortby = [
    { value: "newest", label: "NEWEST" },
    { value: "oldest", label: "OLDEST" },
  ];
  const quantitySelect = [
    { value: "3", label: "3" },
    { value: "10", label: "10" },
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "", label: "ALL" },
  ];
  const [qrdata, setQrData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState(3);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [type, setType] = useState();
  const [sortBytype, setSortBy] = useState();
  const [scantype, setScantype] = useState();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { updateQrState } = useContext(QrCodeContext);
  const fetchData = async () => {
    try {
      setLoader(true);
      setLoadingProgress(50);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/fetch-qr`, {
        method: "POST",
        cache: "no-store",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken", null),
        },
        body: JSON.stringify({
          page: currentPage,
          searchquery: searchQuery,
          qr_type: type,
          sortby: sortBytype,
          paginate: pagination,
          qrscan_type: scantype,
        }),
      });
      const data = await response.json();
      // console.log(data);
      if (data.status) {
        setQrData(data.data.filesData);
        const total = data.data.recordsTotal;
        setTotalPages(Math.ceil(total / pagination));
        updateQrState();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingProgress(100);
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
    let subsmodal = Cookies.get("isModalOpen");
    if (subsmodal) {
      setSubscriptionModal(false);
    }
  }, [currentPage, searchQuery, type, sortBytype, pagination, scantype]);
  const handleSearchInputChange = debounce((event) => {
    setSearchQuery(event.target.value);
  }, 800);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // console.log(sortBytype);
  }, [pagination]);
  const setPaginationNumber = debounce((selectedOption) => {
    if (selectedOption) {
      setPagination(selectedOption.value);
    }
  }, 500);
  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = pagination;
    const totalVisiblePages = Math.min(maxVisiblePages, totalPages);

    let startPage = Math.max(1, currentPage - Math.floor(totalVisiblePages / 2));
    let endPage = startPage + totalVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalVisiblePages + 1);
    }

    if (startPage > 1) {
      items.push(
        <PaginationItem key="first" disabled={currentPage === 1}>
          <PaginationLink className="custom-page-link customfont" onClick={() => handlePageChange(1)}>
            1
          </PaginationLink>
        </PaginationItem>
      );

      if (startPage > 2) {
        items.push(
          // <PaginationItem key="ellipsis-prev" disabled>
          //   <PaginationLink>...</PaginationLink>
          // </PaginationItem>
          <PaginationItem>
            <PaginationLink tag="span" key="ellipsis-prev" disabled>
              <Icon name="more-h" />
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink className="custom-page-link customfont" onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(
          // <PaginationItem key="ellipsis-next" disabled>
          //   <PaginationLink>...</PaginationLink>
          // </PaginationItem>
          <PaginationItem>
            <PaginationLink tag="span" key="ellipsis-next" disabled>
              <Icon name="more-h" />
            </PaginationLink>
          </PaginationItem>
        );
      }

      items.push(
        <PaginationItem key="last" disabled={currentPage === totalPages}>
          <PaginationLink className="custom-page-link customfont" onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    items.unshift(
      <PaginationItem key="prev" disabled={currentPage === 1}>
        <PaginationLink
          className="custom-prev-link d-flex align-items-baseline"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <Icon name="chevrons-left" />
          <span className="customfont">Prev</span>
        </PaginationLink>
      </PaginationItem>
    );

    items.push(
      <PaginationItem key="next" disabled={currentPage === totalPages}>
        <PaginationLink
          className="custom-next-link d-flex align-items-baseline"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <span className="customfont">Next</span>
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    );

    return items;
  };

  // console.log(typeof qrdata);
  const loadingoptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  //doenload option
  const handleDownload = (base64Data, fileName) => {
    const blob = base64ToBlob(base64Data, "image/png");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const comingsoon = () => {
    toast.info("Folder Structure is coming soon");
  };
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleCheckboxChange = (event, itemId) => {
    const { checked } = event.target;
    if (itemId === "selectAll") {
      setSelectAllChecked(checked);
      const newCheckedItems = {};
      qrdata.forEach((item) => {
        newCheckedItems[item.id] = checked;
      });
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems((prevState) => ({
        ...prevState,
        [itemId]: checked,
      }));
    }
  };
  const handleDownloadall = () => {
    const selectedQrIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
    if (selectedQrIds.length === 0) {
      toast.info("No QR codes selected.");
      return;
    }
    const qrdataArray = Object.values(qrdata);
    const selectedQrData = qrdataArray.filter((item) => selectedQrIds.includes(String(item.id)));
    const zip = new JSZip();
    let deletedIds = [];
    const currentDateTime = new Date().toISOString().replace(/:/g, "-");
    selectedQrData.forEach((item) => {
      const base64Data = item.path.split(";base64,").pop();
      const deletedId = item.id;
      console.log("Downoading Id's are", deletedId);
      deletedIds.push(deletedId);
      const blob = base64ToBlob(base64Data, "image/png");
      zip.file(`${item.qr_name}.png`, blob);
    });
    console.log(deletedIds);
    zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, `SiloQr_${currentDateTime}.zip`));
  };
  const deleteAll = () => {
    const selectedQrIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
    if (selectedQrIds.length === 0) {
      toast.info("No QR codes selected.");
      return;
    }
    const qrdataArray = Object.values(qrdata);
    const selectedQrData = qrdataArray.filter((item) => selectedQrIds.includes(String(item.id)));
    let deletedIds = [];
    selectedQrData.forEach((item) => {
      const deletedId = item.id;
      deletedIds.push(deletedId);
    });
    console.log(deletedIds);
    deleteQr(null, deletedIds);
  };

  //delete QR

  const deleteQr = async (id, deleteIds) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e14954",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/delete-qr`, {
            method: "POST",
            cache: "no-store",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken", null),
            },
            body: JSON.stringify({
              qr_id: id,
              deleteIds: deleteIds ? deleteIds : null,
            }),
          });
          const data = await response.json();
          // console.log(data);
          if (data.status) {
            toast.success("Qr code was deleted successfully");
            fetchData();
          } else {
            toast.error(data.message);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setSelectedType = debounce((selectedOption) => {
    if (selectedOption) {
      setType(selectedOption.value);
    }
  }, 500);

  const scantypebox = debounce((selectedOption) => {
    if (selectedOption) {
      setScantype(selectedOption.value);
    }
  }, 500);
  useEffect(() => {
    // console.log(sortBytype);
  }, [sortBytype]);
  const setSortType = debounce((selectedOption) => {
    if (selectedOption) {
      setSortBy(selectedOption.value);
    }
    console.log(sortBytype);
  }, 500);

  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };
  const clearFilters = debounce(() => {
    setSearchQuery("");
    setType(qrtype[0].value);
    setSortBy("");
    setCurrentPage(1);
    window.location.reload();
  }, 500);
  //share button
  const handleShare = (base64Data) => {
    if (navigator.share) {
      const blob = base64ToBlob(base64Data);
      const file = new File([blob], "qr_image.png", { type: "image/png" });

      navigator.share({
        files: [file],
      });
    } else {
      console.log("Web Share API not supported.");
    }
  };

  const redirectPdf = (link) => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}pdf/${link}`;
  };
  const endDate = new Date(traildate); // Replace with your desired end date
  const { days, hours, minutes, seconds, expired } = FourteenDaysCountDown(endDate);
  // const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  const TrialEndModel = () => {
    if (apiWhat?.data?.trial_end) {
      // navigate("/");
    }
  };
  useEffect(() => {
    TrialEndModel();
  }, [apiWhat?.data?.trial_end]);
  const [dynamicExceed, setDynamicexceed] = useState(false);
  const isDynamicExceed = () => {
    if (apiWhat?.data?.isdynamicexceed) {
      setDynamicexceed(true);
    }
  };
  useEffect(() => {
    isDynamicExceed();
  }, [apiWhat?.data?.isdynamicexceed]);
  // console.log(dynamicExceed + "dynamicExceed");
  return (
    <React.Fragment>
      <Head title="Qr Collections" />
      {loader && (
        <>
          <LoadingBar progress={loadingProgress} color="#f11946" height={3} />
          <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
            <Lottie options={loadingoptions} height={200} width={200} />
          </div>
        </>
      )}
      {!subscription && traildate && (
        <div className="countdownalert w-25">
          <UncontrolledAlert
            className="alert-pro"
            color={`${expired ? "danger" : "warning"}`}
            fade={true}
            isOpen={alertTime}
          >
            <div className="alert-text">
              {countdownloader ? (
                <>
                  <h6 className="customfont">
                    <Skeleton width={200} />
                  </h6>
                  <p className="customfont">
                    <Skeleton width={100} />
                  </p>
                </>
              ) : expired ? (
                <>
                  <h6 className="customfont">Your Trial Ends</h6>
                  <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
                    <p className="customfont">Upgrade Your Package</p>
                  </Link>
                </>
              ) : (
                <>
                  <h6 className="customfont">Your Trial Will end in</h6>
                  <p className="customfont">{`${days}d ${hours}h ${minutes}m ${seconds}s`}</p>
                </>
              )}
            </div>
          </UncontrolledAlert>
        </div>
      )}
      {dynamicExceed && (
        <div className="countdownalert w-25">
          <UncontrolledAlert
            className="alert-pro"
            color="warning"
            fade={true}
            // isOpen={alertTime}
          >
            <div className="alert-text">
              <h6 className="customfont">Upgrade Needed: QR Code Limits Exceeded</h6>
              <p className="customfont">
                Your Dynamic qr limits are exceeded!{" "}
                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`} className="fw-bold">
                  Upgrade Now!
                </Link>
              </p>
            </div>
          </UncontrolledAlert>
        </div>
      )}
      <Modal isOpen={modalZoom2} toggle={toggleZoom2} modalClassName="zoom" size="md">
        <ModalHeader
          className="d-flex justify-end border-0 "
          toggle={toggleZoom2}
          close={
            <div>
              <button className="close" onClick={toggleZoom2}>
                <Icon name="cross" />
              </button>
            </div>
          }
        >
          {" "}
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <SubscriptionEnds />
            <h4 className="customfont mt-1">Subscription Expiration Notice</h4>
            <small className="text-justifys customfont fw-bold">
              Your subscription has expired. To continue accessing our QR code services, please renew your subscription
              promptly. Thank you for your continued support.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <h3 className="fs-1 customfont">My QR Codes</h3>
            </BlockHeadContent>
            <BlockHeadContent>
              {/* <Button
                color="primary"
                outline
                className="d-sm-inline-flex fs-6 me-2 headerbuttoncms"
                size="lg"
                onClick={comingsoon}
              >
                <Icon name="folder-plus"></Icon>
                <span className="customfont">New Folder</span>
              </Button> */}
              <Link to="/">
                <Button color="primary" className="p-2 fs-6 rounded" size="lg">
                  <Icon name="qr"></Icon>
                  <span className="customfont">Create QR Code</span>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="container-fluid mt-2">
            <Row className="p-3 bg-white rounded">
              <Col md="4" sm="12">
                <div className="form-group">
                  <small className="customfont fw-bolder">My QR Code</small>
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                      <Icon name="search" />
                    </div>
                    <input
                      className="form-control customfont searchqr fw-bold "
                      type="text"
                      id="default-2"
                      autoComplete="off"
                      placeholder="Search here"
                      autoFocus
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
              </Col>
              <Col md="2" sm="6">
                <div className="form-group">
                  <small className="customfont fw-bolder">QR Code Status</small>
                  <RSelect
                    options={qrscan_type}
                    defaultValue={qrscan_type[0]}
                    className="selectqr customfont"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      scantypebox(selectedOption);
                    }}
                  />
                </div>
              </Col>
              <Col md="2" sm="6">
                <div className="form-group">
                  <small className="customfont fw-bolder">QR Code Type</small>
                  <RSelect
                    options={qrtype}
                    defaultValue={qrtype[0]}
                    className="selectqr customfont"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setSelectedType(selectedOption);
                    }}
                  />
                </div>
              </Col>
              <Col md="2" sm="">
                <div className="form-group">
                  <small className="customfont fw-bolder">Sort by</small>
                  <RSelect
                    options={sortby}
                    defaultValue={sortby[0]}
                    className="selectqr customfont"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setSortType(selectedOption);
                    }}
                  />
                </div>
              </Col>
              <Col md="1" sm="3">
                <div className="form-group">
                  <small className="customfont fw-bolder">Quantity</small>
                  <RSelect
                    options={quantitySelect}
                    defaultValue={quantitySelect[0]}
                    className="selectqr customfont"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setPaginationNumber(selectedOption);
                    }}
                  />
                </div>
              </Col>
              <Col md="1" sm="3" className="d-flex align-items-center flex-column justify-content-end">
                <small className="customfont fw-bolder invisible">Quantity</small>
                <Button className="btn-icon bg-transparent" id="clearfilter" size="md" onClick={clearFilters}>
                  <Icon name="filter" className="fs-3" />
                </Button>
                {/* <Tooltip placement="bottom" isOpen={tooltipOpen} target="clearfilter" toggle={toggle}>
                  Clear Filters
                </Tooltip> */}
              </Col>
            </Row>
          </div>
          {qrdata && qrdata.length > 0 && (
            <div className="mt-2 d-flex justify-content-between align-items-center">
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="selectAll"
                  checked={selectAllChecked}
                  onChange={(event) => handleCheckboxChange(event, "selectAll")}
                />
                <label className="custom-control-label customfont fs-6 fw-bold " htmlFor="selectAll">
                  Select All
                </label>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={deleteAll}
                  className="d-sm-inline-flex fs-6 alldownloadbuton me-1"
                  size="lg"
                >
                  <Icon name="trash"></Icon>
                  <span className="customfont">Delete All</span>
                </Button>
                <Button
                  color="primary"
                  onClick={handleDownloadall}
                  outline
                  className="d-sm-inline-flex fs-6 alldownloadbuton"
                  size="lg"
                >
                  <Icon name="download-cloud"></Icon>
                  <span className="customfont">Download</span>
                </Button>
              </div>
            </div>
          )}
          {qrdata && qrdata.length > 0 ? (
            <>
              <div className="mt-2">
                {qrdata &&
                  qrdata.map((item, index) => (
                    <div className="selectedcheckbox" key={item.id}>
                      <div className="d-flex align-items-center mb-2">
                        <div className="custom-control custom-control-sm custom-checkbox me-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`qrcheck${item.id}`}
                            onChange={(event) => handleCheckboxChange(event, item.id)}
                            checked={checkedItems[item.id] || false}
                          />
                          <label
                            className="custom-control-label customfont fs-6 fw-bold"
                            htmlFor={`qrcheck${item.id}`}
                          ></label>
                        </div>
                        <div
                          className={`descriptcard bg-white p-1 card w-100 ${
                            checkedItems[item.id] ? "border border-danger" : ""
                          }`}
                        >
                          <Row>
                            <Col md="3" sm="6" className="mt-1">
                              <div className="d-flex">
                                {subscription ? (
                                  <Fancybox
                                    options={{
                                      Carousel: {
                                        infinite: false,
                                      },
                                    }}
                                  >
                                    <div
                                      className="f-carousel__slide cursor-pointers"
                                      style={{ width: "96.76px" }}
                                      data-fancybox="gallery"
                                      data-src={item.path}
                                      data-thumb-src={item.path}
                                    >
                                      <img alt="QrCode" src={item.path} className="qr-image" />
                                    </div>
                                  </Fancybox>
                                ) : (
                                  <>
                                    <img alt="QrCode" src={item.path} className="qr-image" />
                                  </>
                                )}
                                {/* <img src={item.path} alt="" className="qr-image" /> */}
                                <div className="d-flex flex-column justify-content-center ms-3 ">
                                  <small className="text-warning fw-bold customfont">
                                    {item.type === "URL" ? "WEBSITE" : item.type}
                                  </small>
                                  <h5 className="customfont text-dots">{item.qr_name}</h5>
                                  <span className="fw-bold text-muted customfont">
                                    <span>
                                      <Icon name="clock" />
                                    </span>{" "}
                                    <span>
                                      {new Date(item.timestamp).toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                      })}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col md="3" sm="6" className="d-flex mt-1 justify-content-center">
                              <div className="d-flex flex-column justify-content-around">
                                <small className="fw-bold customfont">
                                  <span>
                                    <Icon name="folder" className="fs-5" />
                                  </span>{" "}
                                  <span>Default Collection</span>
                                </small>
                                <EmbededCode qr_id={item.qrcode_id} />
                                <span className="fw-bold text-muted customfont">
                                  <span>
                                    <img src={silologo} alt="silologo" style={{ height: "18.4px" }} />
                                  </span>{" "}
                                  <span>Powered By SiloCloud</span>
                                </span>
                              </div>
                            </Col>
                            <Col md="3" sm="6" className="d-flex  mt-1 ">
                              {item.isactive === "1" ? (
                                <>
                                  {/* <div className="d-flex justify-content-center align-items-center">
                                    
                                    <h5 className="customfont scanstyle">No. Of Scans</h5>
                                    <UserAvatar
                                      className="ms-2 customfont md fw-bold fs-2"
                                      theme="primary-dim"
                                      text={item.scans ? item.scans : "0"}
                                    ></UserAvatar>
                                  </div> */}
                                  <div className="d-flex justify-content-center align-items-center">
                                    <h5
                                      className="fw-bold customfont cursor-pointers pdflinkredirect"
                                      onClick={() => redirectPdf(item.pdf_link ? item.pdf_link : "")}
                                    >
                                      <span>
                                        <Icon name="unlink-alt" className="fs-5" />
                                      </span>{" "}
                                      <span className="cursor-pointers">Dynamic QR Code</span>
                                    </h5>
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                  <h5 className="fw-bold customfont">
                                    <span>
                                      <Icon name="unlink-alt" className="fs-5" />
                                    </span>{" "}
                                    <span>Static/Non-Trackable</span>
                                  </h5>
                                </div>
                              )}
                            </Col>
                            <Col md="3" sm="6" className="d-flex mt-1 flex-row justify-content-center">
                              <div className="d-flex justify-content-center align-items-center">
                                <button
                                  className="qrdownloadlist customfont"
                                  onClick={() => handleDownload(item.downloadFile, item.qr_name)}
                                >
                                  Download
                                </button>
                              </div>
                              <div className="ms-2 d-flex justify-content-center align-items-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="dropdown-toggle btn btn-icon btn-primary text-white btn-light "
                                    style={{ padding: "10px 7px", borderRadius: 0 }}
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <ul className="link-list-plain no-bdr">
                                      <li className="cursor-pointer">
                                        <DropdownItem tag="a" onClick={() => deleteQr(item.id, null)}>
                                          <Icon name="trash"></Icon>
                                          <span className="customfont fw-bold">Delete</span>
                                        </DropdownItem>
                                      </li>
                                      <li className="cursor-pointer">
                                        <DropdownItem tag="a" onClick={() => handleShare(item.downloadFile)}>
                                          <Icon name="share"></Icon>
                                          <span className="customfont fw-bold">Share</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </Col>
                            {item.isactive === "1" && !subscription && (
                              <div className="d-flex justify-content-end position-absolute ">
                                <Badge color={new Date(traildate) < new Date() ? "danger" : "warning"}>
                                  {new Date(traildate) < new Date() ? "Expired" : "Active Until "}
                                  {new Date(traildate) >= new Date() && (
                                    <>
                                      {new Date(traildate).toLocaleDateString("en-GB", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </>
                                  )}
                                </Badge>
                              </div>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-end mt-2">
                <Pagination aria-label="Page navigation example" className="bg-white">
                  {renderPaginationItems()}
                </Pagination>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center ">
              <img src={NoResultFound} alt="" className="w-25" />
              {/* <NoResultComponent/> */}
            </div>
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default QrCodeCmsNew;
