import React, { useState, useEffect, useContext } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "../components/Component";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { productData } from "./pre-built/products/ProductData";
import { useForm } from "react-hook-form";
import ProductH from "../images/product/h.png";
import { Modal, ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../utils/Utils";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "./css/QrCodecms.css";
import { base64ToBlob } from "../utils/Utils";
import { SubscriptionContext } from "./SubscriptionContext";
import ProductsIcon from "../images/package_type/ProductsIcon";
import SiloMarketPlaceIcon from "../images/SiloMarketplaceLogo.png";
import SubscriptionEnds from "../images/package_type/SubscriptionEnds";
import FancyBox from "./components/FancyBox";
import debounce from "lodash.debounce";
import { QrCodeContext } from "./QrCodeContext";
const ProductList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { contextData, apiData } = useContext(SubscriptionContext);
  const [subscription] = contextData;
  const [modalZoom1, setModalZoom1] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { updateQrState } = useContext(QrCodeContext);
  const toggleZoom1 = () => {
    setModalZoom1(!modalZoom1);
    navigate("/products");
  };
  console.log("Subscription is" + subscription);
  const fetchProductQr = async () => {
    try {
      setLoading(true);
      setLoadingProgress(50);
      const formData = new FormData();
      formData.append("search", onSearchText);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-product-qr`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const data = await response.json();
      console.log(data.data.products);
      setData(data.data.products);
      updateQrState();
      if (!subscription) {
        // setModalZoom1(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingProgress(100);
    }
  };
  useEffect(() => {
    fetchProductQr();
  }, [onSearchText]);
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    img: null,
    sku: "",
    price: 0,
    salePrice: 0,
    stock: 0,
    category: [],
    fav: false,
    check: false,
  });
  const [editId, setEditedId] = useState();
  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);
  const [files, setFiles] = useState([]);

  //scroll off when sidebar shows
  useEffect(() => {
    view.add ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [view.add]);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = productData.filter((item) => {
        return item.name.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...productData]);
    }
  }, [onSearchText]);

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      name: "",
      img: null,
      sku: "",
      price: 0,
      salePrice: 0,
      stock: 0,
      category: [],
      fav: false,
      check: false,
    });
    reset({});
  };

  const onFormSubmit = (form) => {
    const { title, price, salePrice, sku, stock } = form;

    let submittedData = {
      id: data.length + 1,
      name: title,
      img: files.length > 0 ? files[0].preview : ProductH,
      sku: sku,
      price: price,
      salePrice: salePrice,
      stock: stock,
      category: formData.category,
      fav: false,
      check: false,
    };
    setData([submittedData, ...data]);
    setView({ open: false });
    setFiles([]);
    resetForm();
  };

  const onEditSubmit = () => {
    let submittedData;
    let newItems = data;
    let index = newItems.findIndex((item) => item.id === editId);

    newItems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: editId,
          name: formData.name,
          img: files.length > 0 ? files[0].preview : item.img,
          sku: formData.sku,
          price: formData.price,
          salePrice: formData.salePrice,
          stock: formData.stock,
          category: formData.category,
          fav: false,
          check: false,
        };
      }
    });
    newItems[index] = submittedData;
    //setData(newItems);
    resetForm();
    setView({ edit: false, add: false });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          name: item.name,
          img: item.img,
          sku: item.sku,
          price: item.price,
          salePrice: item.salePrice,
          stock: item.stock,
          category: item.category,
          fav: false,
          check: false,
        });
      }
    });
    setEditedId(id);
    setFiles([]);
    setView({ add: false, edit: true });
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  // selects all the products
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.check = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // selects one product
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].check = e.currentTarget.checked;
    setData([...newData]);
  };

  // onChange function for searching name
  const onFilterChange = debounce((e) => {
    setSearchText(e.target.value);
  }, 500);

  // function to delete a product
  // const deleteProduct = (id) => {
  //   let defaultData = data;
  //   defaultData = defaultData.filter((item) => item.id !== id);
  //   setData([...defaultData]);
  // };
  const deleteProduct = async (id) => {
    console.log(id);
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e14954",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/delete-qr`, {
            method: "POST",
            cache: "no-store",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken", null),
            },
            body: JSON.stringify({
              qr_id: id,
            }),
          });
          const data = await response.json();
          // console.log(data);
          if (data.status) {
            toast.success("Qr code was deleted successfully");
            fetchProductQr();
          } else {
            toast.error(data.message);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  // function to delete the seletected item
  const selectorDeleteProduct = () => {
    let newData;
    newData = data.filter((item) => item.check !== true);
    setData([...newData]);
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data && data.length > 0 ? data.slice(indexOfFirstItem, indexOfLastItem) : [];

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleDownload = (base64Data, fileName) => {
    const blob = base64ToBlob(base64Data, "image/png");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const [apiWhat] = apiData;
  console.log(apiWhat);
  const [subscriptionended, setSubscriptionEnds] = useState(false);
  const [modalZoom2, setModalZoom2] = useState(false);
  const toggleZoom2 = () => setModalZoom2(!modalZoom2);
  const checkifEnds = () => {
    if (apiWhat?.data?.subscription_end) {
      setSubscriptionEnds(true);
      setModalZoom2(true);
    }
  };
  useEffect(() => {
    checkifEnds();
  }, [apiWhat?.data?.subscription_end]);
  const [productExceed, setproductexceed] = useState(false);
  const isproductExceed = () => {
    if (apiWhat?.data?.isProductQrExceed) {
      setproductexceed(true);
    }
  };
  useEffect(() => {
    isproductExceed();
  }, [apiWhat?.data?.isProductQrExceed]);
  const navigateProduct = () => {
    if (!subscription) {
      // toast.error("Please upgrade your subscription to access this feature.");
      setModalZoom1(true);
    } else {
      navigate("/products/addproducts");
    }
  };
  return (
    <React.Fragment>
      <Head title="Product List"></Head>
      {loading && (
        <>
          <LoadingBar progress={loadingProgress} color="#f11946" height={3} />
          <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
            <div class="loader-new"></div>
          </div>
        </>
      )}
      {productExceed && (
        <div className="countdownalert w-25">
          <UncontrolledAlert
            className="alert-pro"
            color="warning"
            fade={true}
            // isOpen={alertTime}
          >
            <div className="alert-text">
              <h6 className="customfont">Upgrade Needed: Product QR Code Limits Exceeded</h6>
              <p className="customfont">
                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`} className="fw-bold">
                  Upgrade Now!
                </Link>
              </p>
            </div>
          </UncontrolledAlert>
        </div>
      )}
      {!subscription && (
        <Modal isOpen={modalZoom1} toggle={toggleZoom1} modalClassName="zoom" size="md" backdrop="static">
          <ModalHeader
            toggle={toggleZoom1}
            className="border-0"
            close={
              <button className="close" onClick={toggleZoom1}>
                <Icon name="cross" />
              </button>
            }
          >
            {/* <span className="customfont">Upgrade Now</span> */}
          </ModalHeader>
          <ModalBody>
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center">
                <ProductsIcon /> <Icon name="plus" className="fs-1 ms-2 me-2" /> <img src={SiloMarketPlaceIcon} />
              </div>
              <h4 className="customfont mt-1">Create Product QR Codes and List on SiloMarketPlace</h4>
              <small className="text-justifys customfont fw-bold">
                Enhance your product visibility by upgrading your plan. Generate QR codes with your product URL and
                easily add your products to SiloMarketPlace. Upgrade today to streamline your marketing and sales
                efforts.
              </small>
            </div>
            <div className="text-center mt-1">
              <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
                <Button color="danger">
                  <span className="customfont">Upgrade Now</span>
                </Button>
              </Link>
            </div>
          </ModalBody>
        </Modal>
      )}
      {/* <Modal isOpen={modalZoom2} toggle={toggleZoom2} modalClassName="zoom" size="md" backdrop="static">
        <ModalBody>
          <div className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <ProductsIcon /> <Icon name="plus" className="fs-1 ms-2 me-2" /> <img src={SiloMarketPlaceIcon} />
            </div>
            <h4 className="customfont mt-1">Create Product QR Codes and List on SiloMarketPlace</h4>
            <small className="text-justifys customfont fw-bold">
              Your subscription has expired. To continue accessing our QR code services, please renew your subscription
              promptly. Thank you for your continued support.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal> */}
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <h3 className="customfont">Products</h3>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: smOption ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control customfont"
                          id="default-04"
                          placeholder="Search by product name"
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>

                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      {/* <Link to="/products/addproducts"> */}{" "}
                      <Button
                        className="toggle d-none d-md-inline-flex productheadercms"
                        color="primary"
                        onClick={navigateProduct}
                      >
                        <Icon name="plus"></Icon>
                        <span className="customfont fw-bold">Add Product</span>
                      </Button>
                      {/* </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          {/* <div className="d-flex justify-content-center">
            <Button className="btn-dim mx-3 " outline color="primary">
              Silomarketplace
            </Button>
            <Link to="/collections">
              {" "}
              <Button className="btn-dim mx-3" outline color="primary">
                Other Product
              </Button>
            </Link>
          </div> */}
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <DataTableBody>
                  <DataTableHead>
                    {/* <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="uid_1"
                          onChange={(e) => selectorCheck(e)}
                        />
                        <label className="custom-control-label" htmlFor="uid_1"></label>
                      </div>
                    </DataTableRow> */}
                    <DataTableRow size="sm">
                      <span className="customfont fs-6  fw-bold">Product QR</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="customfont fs-6  fw-bold"> Product Name</span>
                      {/* <span>
                        <Icon name="swap-v" className="fs-5 productsortable cursor-pointers ms-2" />
                      </span> */}
                    </DataTableRow>
                    <DataTableRow>
                      <span className="customfont fs-6 fw-bold">Price</span>
                      {/* <span>
                        <Icon name="swap-v" className="fs-5 productsortable cursor-pointers ms-2" />
                      </span> */}
                    </DataTableRow>
                    <DataTableRow>
                      <span className="customfont fs-6  fw-bold">Stock</span>
                      {/* <span>
                        <Icon name="swap-v" className="fs-5 productsortable cursor-pointers ms-2" />
                      </span> */}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="customfont fs-6  fw-bold">Category</span>
                      {/* <span>
                        <Icon name="swap-v" className="fs-5 productsortable cursor-pointers ms-2" />
                      </span> */}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="customfont fs-6  fw-bold">Actions</span>
                    </DataTableRow>
                    {/* <DataTableRow className="nk-tb-col-tools">
                      <ul className=" gx-1 my-n1">
                        <li className="me-n1">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              href="#toggle"
                              onClick={(ev) => ev.preventDefault()}
                              className="dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      selectorDeleteProduct();
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Selected</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow> */}
                  </DataTableHead>
                  {currentItems.length > 0
                    ? currentItems.map((item) => {
                        const categoryList = [];
                        item.category.forEach((currentElement) => {
                          categoryList.push(currentElement.label);
                        });
                        return (
                          <DataTableItem key={item.id}>
                            {/* <DataTableRow className="nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  defaultChecked={item.check}
                                  id={item.id + "uid1"}
                                  key={Math.random()}
                                  onChange={(e) => onSelectChange(e, item.id)}
                                />
                                <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                              </div>
                            </DataTableRow> */}
                            <DataTableRow>
                              <span className="tb-product">
                                <img src={item.img ? item.img : ProductH} alt="product" className="thumb" />
                              </span>
                            </DataTableRow>
                            <DataTableRow size="sm">
                              <span className="title fw-bold">{item.name}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item.price}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item.stock}</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="fw-bold">{categoryList.join(", ")}</span>
                            </DataTableRow>
                            <DataTableRow className="">
                              <div className=" d-flex ">
                                {/* <div
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    onEditClick(item.id);
                                    toggle("edit");
                                  }}
                                >
                                  <Icon className="fs-6 mx-1" name="edit"></Icon>
                                </div> */}
                                <div
                                  tag="a"
                                  href="#view"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    onEditClick(item.id);
                                    toggle("details");
                                  }}
                                >
                                  <Icon className="fs-5 mx-1 cursor-pointers text-danger" name="eye"></Icon>
                                </div>
                                <div
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    deleteProduct(item.id);
                                  }}
                                >
                                  <Icon className="fs-5 mx-1 cursor-pointers text-danger" name="trash"></Icon>
                                </div>
                                <div
                                  onClick={() => handleDownload(item.downloadable_image, item.name)}
                                  className="cursor-pointers"
                                >
                                  <Icon className="fs-5 mx-1 text-danger " name="download" />
                                </div>
                              </div>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
                <div className="card-inner">
                  {data && data.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No products found</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>
        <Modal isOpen={view.details} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            {/* <div className="nk-modal-head">
              <h4 className="nk-modal-title title">
                Product <small className="text-primary">#{Math.floor(Math.random() * 8889) + 1111}</small>
              </h4>
              <img src={formData.img} alt="" />
            </div>
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Product Name</span>
                  <span className="caption-text">{formData.name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Product Price</span>
                  <span className="caption-text">{formData.price}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Product Category</span>
                  <span className="caption-text">
                    {formData.category.map((item) => (
                      <Badge className="me-1" color="secondary">
                        {item.value}
                      </Badge>
                    ))}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Stock</span>
                  <span className="caption-text"> {formData.stock}</span>
                </Col>
              </Row>
            </div> */}
            <Row>
              <Col md="6" className="d-flex align-items-center">
                <img src={formData.img} alt="Qr Code" style={{ height: "230px" }} />
              </Col>
              <Col md="6">
                <div className="d-flex flex-column">
                  <div className="mt-1">
                    <span className="sub-text">Product Name</span>
                    <span className="caption-text">{formData.name}</span>
                  </div>
                  <div className="mt-1">
                    <span className="sub-text">Product Price</span>
                    <span className="caption-text">{formData.price}</span>
                  </div>
                  <div className="mt-1">
                    <span className="sub-text">Product Category</span>
                    <span className="caption-text">
                      {formData.category.map((item) => (
                        <Badge className="me-1" color="secondary">
                          {item.value}
                        </Badge>
                      ))}
                    </span>
                  </div>
                  <div className="mt-1">
                    <span className="sub-text">Stock</span>
                    <span className="caption-text"> {formData.stock}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ProductList;
