import React from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";

const Layout = ({ title, ...props }) => {
  return (
    <>
      <Head title={!title && "Loading"} />
      <Outlet />
    </>
  );
};
export default Layout;
