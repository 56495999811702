import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Menu from "../menu/Menu";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  PopoverHeader,
  Progress,
  UncontrolledAlert,
  UncontrolledDropdown,
} from "reactstrap";
import { getCookie } from "../../utils/Utils";
import debounce from "lodash.debounce";
import { Icon, UserAvatar } from "../../components/Component";
import GoldIcon from "../../images/package_icons/GoldIcon";
import SilverIcon from "../../images/package_icons/SilverIcon";
import BronzeIcon from "../../images/package_icons/BronzeIcon";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import qrsvg from "../../images/SubScriptionImages/qrsvg.svg";
import pdfsvg from "../../images/SubScriptionImages/pdfsvg.svg";
import peoductqrsvg from "../../images/SubScriptionImages/peoductqrsvg.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  freePackage,
  expired,
  upgradeNow,
  bronzePackage,
  silverPackage,
  goldPackage,
} from "../../images/QrSubscriptionImages";
import { QrCodeContext } from "../../pages/QrCodeContext";
const ProgressBox = () => {
  const [qrCount, setQRCount] = useState(0);
  const [qrstore, setQrStorage] = useState(10);
  const [servicename, setServiceName] = useState("");
  const { qrState } = useContext(QrCodeContext);
  console.log("qrState is" + qrState);

  useEffect(() => {
    const fetchCountQr = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-count`, {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken", null),
          },
        });
        const data = await response.json();
        setQRCount(data.data.count);
      } catch (error) {
        console.error("Error fetching QR count:", error);
      }
    };

    const debouncedFetchCountQr = debounce(fetchCountQr, 500);

    debouncedFetchCountQr();

    return () => {
      debouncedFetchCountQr.cancel();
    };
  }, [qrState]);

  // Qr code facts
  const [facts, setFacts] = useState("");

  useEffect(() => {
    const factsqr = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-qr-facts`, {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken", null),
          },
        });
        const data = await response.json();
        setFacts(data.data.message);
      } catch (error) {
        console.error(error);
      }
    };

    const debouncedFactsqr = debounce(factsqr, 500);

    debouncedFactsqr();

    return () => {
      debouncedFactsqr.cancel();
    };
  }, []);

  const [subscription, setSubscription] = useState(null);
  const [trialend, setTrialend] = useState(null);
  const [soonexpire, setSoonexpire] = useState(false);
  const [subscriptionend, setSubscriptionEnd] = useState(false);
  const checkSubscription = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-qr-subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken", null),
      },
    });
    const data = await response.json();
    if (data.data.trial_end) {
      setTrialend(data.data.trial_end);
    }
    if (data.data.soon_expire) {
      setSoonexpire(data.data.soon_expire);
    }
    if (data.data.subscription_end) {
      setSubscriptionEnd(data.data.subscription_end);
    }
    const subscriptions = JSON.parse(data.data.subscription.service_plan_data);
    setSubscription(JSON.parse(data.data.subscription.service_plan_data));
    console.log(data.data.trial_end);
    if (data.status) {
      const featuresObject = JSON.parse(subscriptions.features); // Parse the features string here
      const qrFeature = featuresObject.QR;
      const qrValue = data.data.subscription.QR_value;
      setQrStorage(parseInt(qrValue));
      setServiceName(data?.data?.subscription?.service_name);
    } else if (data.data.upgradeModel) {
      const qrValue = data.data.subscription.QR_value;
      console.log("qrValue: " + qrValue);
      setQrStorage(parseInt(qrValue));
      setServiceName(data?.data?.subscription?.service_name);
    } else if (data.data.deleteSome) {
      const qrValue = data.data.subscription.QR_value;
      setQrStorage(parseInt(qrValue));
    } else {
      setQrStorage(10);
    }
  };
  console.log(qrstore);
  console.log(trialend);

  useEffect(() => {
    const debouncedCheckSubscription = debounce(checkSubscription, 500);

    debouncedCheckSubscription();

    return () => {
      debouncedCheckSubscription.cancel();
    };
  }, []);

  const redirectPackage = (packageName, currentService) => {
    if (
      (currentService === "Gold" && (packageName === "Bronze" || packageName === "Silver")) ||
      (currentService === "Silver" && packageName === "Bronze")
    ) {
      toast.error("You can't downgrade your plan");
      return;
    }
    const packageUrls = {
      Bronze: `${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`,
      Silver: `${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`,
      Gold: `${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`,
    };
    window.location.href = packageUrls[packageName] || packageUrls.Gold;
  };
  //popover
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const popoverenter = () => {
    setPopoverOpen(true);
    whatSubscription();
  };
  const popoverleave = () => {
    setPopoverOpen(false);
  };
  const handleClickOutside = (event) => {
    if (!event.target.closest("#Popover1")) {
      setPopoverOpen(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // what subscription contains counts
  const [staticqr, setStaticqr] = useState(null);
  const [dynamicqr, setDynamicqr] = useState(null);
  const [productqr, setproductqr] = useState(null);
  const [imp_message, setImp_message] = useState(null);
  // what subscription contains percentage
  const [staticqrpercentage, setStaticqrpercentage] = useState(0);
  const [dynamicqrpercentage, setDynamicqrpercentage] = useState(0);
  const [productqrpercentage, setproductqrpercentage] = useState(0);
  const [popoverloader, setpopoverloader] = useState(false);
  const whatSubscription = async () => {
    try {
      setpopoverloader(false);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-sub-details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken", null),
        },
      });
      if (response.status) {
        const responseData = await response.json();
        setStaticqr(responseData?.data?.static_qr);
        setDynamicqr(responseData?.data?.dynamic_qr);
        setproductqr(responseData?.data?.product_qr);
        setImp_message(responseData?.data?.imp_message);
        setStaticqrpercentage(responseData?.data?.static_qr_percentage);
        setDynamicqrpercentage(responseData?.data?.dynamic_qr_percentage);
        setproductqrpercentage(responseData?.data?.product_qr_percentage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setpopoverloader(false);
    }
  };
  const getsubColor = (value) => {
    if (value >= 0 && value <= 40) {
      return "green";
    } else if (value >= 41 && value <= 80) {
      return "#f4bd0e";
    } else {
      return "#e85347";
    }
  };
  const GoToSubscribe = () => {
    return (
      <Link to={process.env.REACT_APP_ACCOUNT_URL + "subscriptions/service-plan/buy/3"}>
        <a className="link link-primary link-sm">Upgrade Package</a>
      </Link>
    );
  };
  return (
    <>
      {facts && (
        <div className="qr-faq mb-1">
          <UncontrolledAlert className="alert-icon p-1 bg-info-dim" fade={false}>
            <div className="">
              <h6 className="customfont">Did you know!</h6>
            </div>
            <div className="mt-1">
              <p className="text-dark">{facts}</p>
            </div>
          </UncontrolledAlert>
        </div>
      )}
      {/* {subscription && ( */}
      <div className="card card-bordered rounded p-1">
        <div className="d-flex justify-content-between customfont">
          <small className="fw-bold">Qr Generated</small>
          <small className="fw-bold">
            {qrCount}/{qrstore}{" "}
            {subscription && (
              <>
                <span className="packageinfoicon cursor-pointers ms-1" id="Popover1" onMouseEnter={popoverenter}>
                  <Icon name="info" className="fs-6" />
                </span>

                <Popover isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                  <PopoverHeader className="customfont">What Your Subscription Contain?</PopoverHeader>
                  <PopoverBody>
                    {popoverloader ? (
                      <p>Loading...</p>
                    ) : (
                      <div className="d-flex flex-wrap">
                        <div className="align-items-center card d-flex flex-row justify-content-between p-0 border-1 p-1 shadow border-dark mt-1 w-100">
                          <div>
                            <UserAvatar image={qrsvg} className="sq sm bg-body-secondary"></UserAvatar>
                          </div>
                          <div className="customfont ms-1 fw-bold">{staticqr} All QR Codes</div>
                          <div className="subscriptionbytype">
                            <CircularProgressbar
                              value={staticqrpercentage}
                              text={`${staticqrpercentage}%`}
                              styles={buildStyles({
                                textColor: getsubColor(staticqrpercentage),
                                pathColor: getsubColor(staticqrpercentage),
                              })}
                            />
                          </div>
                        </div>
                        <small className="text-muted" style={{ fontSize: "10px" }}>
                          {imp_message}
                        </small>
                        <div className="align-items-center card d-flex flex-row justify-content-between p-0 border-1 p-1 shadow border-dark mt-1 w-100">
                          <div>
                            <UserAvatar image={pdfsvg} className="sq sm bg-body-secondary"></UserAvatar>
                          </div>
                          <div className="customfont ms-1 fw-bold">{dynamicqr} Dynamic QR Codes</div>
                          <div className="subscriptionbytype">
                            <CircularProgressbar
                              value={dynamicqrpercentage}
                              text={`${dynamicqrpercentage}%`}
                              styles={buildStyles({
                                textColor: getsubColor(dynamicqrpercentage),
                                pathColor: getsubColor(dynamicqrpercentage),
                              })}
                            />
                          </div>
                        </div>
                        <div className="align-items-center card d-flex flex-row justify-content-between p-0 border-1 p-1 shadow border-dark mt-1 w-100">
                          <div>
                            <UserAvatar image={peoductqrsvg} className="sq sm bg-body-secondary"></UserAvatar>
                          </div>
                          <div className="customfont ms-1 fw-bold">{productqr} Product QR Codes</div>
                          <div className="subscriptionbytype">
                            <CircularProgressbar
                              value={productqrpercentage}
                              text={`${productqrpercentage}%`}
                              styles={buildStyles({
                                textColor: getsubColor(productqrpercentage),
                                pathColor: getsubColor(productqrpercentage),
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </PopoverBody>
                </Popover>
              </>
            )}
          </small>
        </div>
        <div className="mt-1">
          <Progress color="danger" value={(qrCount / qrstore) * 100} />
        </div>
        <div className="d-flex justify-content-end customfont">
          {/* <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}> */}
          {qrCount < qrstore ? (
            // <small className="fw-bolder text-danger">Upgrade to generate more</small>
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="dropdown-toggle fw-bolder text-primary" tag="span">
                  Upgrade to generate more
                </DropdownToggle>
                <DropdownMenu>
                  <ul className="link-check">
                    <li>
                      <span>Select Your Plan</span>
                    </li>
                    <li
                      className="d-flex justify-content-between align-items-center selectPackageIcon"
                      onClick={() => redirectPackage("Gold", servicename)}
                    >
                      <a onClick={(ev) => ev.preventDefault()}>
                        <GoldIcon className="me-2" />
                        <span className="fw-bold">Gold</span>
                      </a>
                      <div className={`me-2 ${servicename === "Gold" ? "" : "d-none"}`}>
                        <Badge className="badge-dim" color="danger">
                          Current
                        </Badge>
                      </div>
                    </li>
                    <li
                      className="d-flex justify-content-between align-items-center selectPackageIcon"
                      onClick={() => redirectPackage("Silver", servicename)}
                    >
                      <a onClick={(ev) => ev.preventDefault()}>
                        <SilverIcon className="me-2" />
                        <span className="fw-bold">Silver</span>
                      </a>
                      <div className={`me-2 ${servicename === "Silver" ? "" : "d-none"}`}>
                        <Badge className="badge-dim" color="danger">
                          Current
                        </Badge>
                      </div>
                    </li>
                    <li
                      className="d-flex justify-content-between align-items-center selectPackageIcon"
                      onClick={() => redirectPackage("Bronze", servicename)}
                    >
                      <a onClick={(ev) => ev.preventDefault()}>
                        <BronzeIcon className="me-2" />
                        <span className="fw-bold">Bronze</span>
                      </a>
                      <div className={`me-2 ${servicename === "Bronze" ? "" : "d-none"}`}>
                        <Badge className="badge-dim" color="danger">
                          Current
                        </Badge>
                      </div>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ) : (
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <small className="fw-bolder text-success">You've reached the limit</small>
            </Link>
          )}
          {/* </Link> */}
        </div>
      </div>
      {/* )} */}
      <hr className="mt-1" />
      {/* subscription cards */}
      <>
        {subscription && subscription.name === "Bronze" && !soonexpire && !subscriptionend && (
          // <div className="card d-flex justify-center border flex-row p-1 align-items-center bronzecard mt-1">
          //   <div>
          //     <BronzePackage className="" />
          //   </div>
          //   <div className="customfont fw-bold ms-1 text-white">You Are Bronze Member</div>
          // </div>
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={bronzePackage} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Bronze Package
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
        {subscription && subscription.name === "Silver" && !soonexpire && !subscriptionend && (
          // <div className="card d-flex justify-center border flex-row p-1 align-items-center silvercard mt-1">
          //   <div>
          //     <SilverPackage className="me-2" />
          //   </div>
          //   <div className="customfont fw-bold ms-1">You Are Silver Member</div>
          // </div>
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={silverPackage} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Silver Package
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
        {subscription && subscription.name === "Gold" && !soonexpire && !subscriptionend && (
          // <div className="card d-flex justify-center border flex-row p-1 align-items-center goldcard mt-1">
          //   <div>
          //     <GoldPackages className="me-2" />
          //   </div>
          //   <div className="customfont fw-bold ms-1">You Are Gold Member</div>
          // </div>
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={goldPackage} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Gold Package
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
        {!subscription && !trialend && (
          // <div className="card d-flex justify-center border flex-row p-1 align-items-center mt-1 bg-warning text-white">
          //   <div>
          //     <FreeTrial className="me-1" />
          //   </div>
          //   <div className="customfont fw-bold ms-1">You Are On Trial Period</div>
          // </div>
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={freePackage} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Free Trial
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
        {(trialend || subscriptionend) && (
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={expired} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Expired
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
        {soonexpire && (
          <div className="card bg-transparent shadow-none">
            <div className="card-inner d-flex align-items-center card-body" style={{ padding: 5 }}>
              <div className="subscription-asset">
                <img src={upgradeNow} style={{ height: "55px" }} />
              </div>
              <div className="subscription-info ms-3">
                <p className="text-uppercase fw-bold mb-0" style={{ fontSize: "0.7rem" }}>
                  Expired Soon
                </p>
                <GoToSubscribe />
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};
const Sidebar = ({ fixed, className }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className,
  });
  return (
    <>
      <div className={classes}>
        <SimpleBar className="nk-sidebar-inner">
          <Menu />
          <hr />
          {/* <UserFolder/> */}
        </SimpleBar>
        <div className="p-2 position-absolute w-100 bottom-0">
          <ProgressBox />
        </div>
      </div>
      {theme.sidebarVisibility && <div onClick={themeUpdate.sidebarVisibility} className="nk-sidebar-overlay"></div>}
    </>
  );
};
export default Sidebar;
