const menu = [
  {
    heading: "MY QR CODES",
  },
  {
    icon: "qr",
    text: "My QR codes",
    link: "/collections",
    dashboard: true,
  },
  {
    heading: "PRODUCTS",
  },
  {
    icon: "bag",
    text: "My products",
    link: "/products",
    dashboard: true,
  },
];
export default menu;
