import React, { useState } from 'react';
import Barcode from 'react-barcode';

const Barcodes = () => {
    const [inputValue, setInputValue] = useState('');

    // Handle input changes
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
      // Determine the value to be used for the barcode
      const barcodeValue = inputValue || 'default-barcode';
 
    return (
   
            <div className="row justify-content-center  my-5">
              
                <div className="col-md-6">
                <div className="shadow p-4 bg-white rounded">
                    <label htmlFor="barcodeInput" className="form-label">Enter value</label>
                    <input
                        type="text"
                        id="barcodeInput"
                        className="form-control"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Enter product code"
                    />
                </div>
                </div>

                {/* Second column with barcode */}
                <div className="col-md-6">
                <div className="shadow p-3 bg-white rounded h-100 d-flex justify-content-center">
                    <Barcode value={barcodeValue} className="" />
                </div>
                </div>
            </div>
    );
};
export default Barcodes;

