import React, { useState } from "react";
import { Button, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function SKU() {
  const [productName, setProductName] = useState("");
  const [store, setStore] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [productLink, setProductLink] = useState("");
  const [files2, setFiles2] = useState([]);
  const [applyBorder, setApplyBorder] = useState(false);
  const [applyText, setApplyText] = useState(false);
  const [additionalText, setAdditionalText] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateFields = () => {
    const newErrors = {};
    if (!productName) newErrors.productName = "Product name is required.";
    if (!price) newErrors.price = "Price is required.";
    if (!quantity) newErrors.quantity = "Quantity is required.";
    if (quantity > 10000) newErrors.quantity = "Quantity cannot exceed 10,000.";
    if (productLink && !/^(ftp|http|https):\/\/[^ "]+$/.test(productLink)) {
      newErrors.productLink = "Invalid URL format.";
    }
    return newErrors;
  };

  const handleGenerateQR = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      toast.success("QR Code Generated Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/pdf", {
        state: {
          productName,
          store,
          description,
          quantity,
          price,
          productLink,
          applyBorder,
          applyText,
          additionalText,
        },
      });
    }
  };

  return (
    <div className="card bg-white shadow my-3">
      <ToastContainer />
      <div className="row"> 

      <div className="col-6"></div>
      <div className="col-6"> 
      <div className="row py-3 d-flex justify-content-center">
        <div className="col-4">
          <Label htmlFor="namecontact" className="form-label">
            Product Name*
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
            {errors.productName && (
              <div className="text-danger">{errors.productName}</div>
            )}
          </div>
        </div>
        <div className="col-4">
          <Label htmlFor="namecontact" className="form-label">
            Store
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Product Store"
              value={store}
              onChange={(e) => setStore(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row form-group d-flex justify-content-center">
        <div className="col-8">
          <Label htmlFor="phonecontact" className="form-label">
            Product Description
          </Label>
          <div className="form-control-wrap">
            <textarea
              className="form-control"
              placeholder="Enter product Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-4">
          <Label htmlFor="namecontact" className="form-label">
            Quantity*
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              type="number"
              placeholder="Enter Product Quantity"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
                if (e.target.value > 10000) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    quantity: "Quantity cannot exceed 10,000."
                  }));
                } else {
                  setErrors((prevErrors) => {
                    const { quantity, ...rest } = prevErrors;
                    return rest;
                  });
                }
              }}
            />
            {errors.quantity && (
              <div className="text-danger">{errors.quantity}</div>
            )}
          </div>

          <Label htmlFor="namecontact" className="form-label mt-2">
            Price*
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              type="number"
              placeholder="Enter Product Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            {errors.price && (
              <div className="text-danger">{errors.price}</div>
            )}
          </div>
        </div>
        <div className="col-4">
          <Label htmlFor="namecontact" className="form-label">
            Image
          </Label>
          <Dropzone onDrop={(acceptedFiles) => setFiles2(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                  <input {...getInputProps()} />
                  {files2.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">Drag and drop file</span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files2.map((file) => (
                    <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                      <div className="dz-image">
                        <img src={URL.createObjectURL(file)} alt="preview" />
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      <div className="row form-group  d-flex justify-content-center">
        <div className="col-8">
          <Label htmlFor="namecontact" className="form-label">
            Product Link
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              placeholder="Enter Product Link"
              value={productLink}
              onChange={(e) => setProductLink(e.target.value)}
            />
            {errors.productLink && (
              <div className="text-danger">{errors.productLink}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-4">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              checked={applyBorder}
              onChange={(e) => setApplyBorder(e.target.checked)}
            />
            <label className="custom-control-label form-label" htmlFor="customCheck1">
              Apply Border
            </label>
          </div>
        </div>
        <div className="col-4">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck2"
              checked={applyText}
              onChange={(e) => setApplyText(e.target.checked)}
            />
            <label className="custom-control-label form-label" htmlFor="customCheck2">
              Apply Text
            </label>
          </div>
        </div>
      </div>
      {applyText && (
        <div className="row form-group py-3 d-flex justify-content-center">
          <div className="col-8">
            <Label htmlFor="additionalText" className="form-label">
              Additional Text
            </Label>
            <div className="form-control-wrap">
              <input
                className="form-control d-flex justify-content-center"
                type="text"
                placeholder="Enter additional text"
                value={additionalText}
                onChange={(e) => setAdditionalText(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center py-5">
        <Button
          color="transparent"
          className="border-1 border-danger text-danger fw-bold custom-button"
          onClick={handleGenerateQR}
        >
          <span>Generate QR</span>
        </Button>
      </div>
      </div>
    </div>
    </div>
  );
}
