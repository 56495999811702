import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCookie } from "../utils/Utils";

function QrCodeGenerator(props) {
  const [qrData, setQrData] = useState("");
  const { userid } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-image`, {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken", null),
          },
          body: JSON.stringify({ qr_id: userid }),
        });
        const responseData = await response.json();
        if (responseData.status) {
          setQrData(responseData.data.base_64);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userid]);

  const qrImageSrc = qrData ? `data:image/png;base64,${qrData}` : ""; // Base64 image source

  const iframeCode = `
  &lt;iframe
      title="QR Code"
      srcDoc='<img src="${qrImageSrc}" alt="qr_image" style="width: 100%; height: 100%;" />'
      width="300"
      height="300"
      frameBorder="0"
      scrolling="no"
      &gt;&lt;/iframe&gt;
  `;
  return iframeCode;
}

export default QrCodeGenerator;
