import React from "react";
// import { Search } from "lucide-react";
import logo from "./components/css/silocloud logo500x500 1.png";
import "./components/css/searchpage.css";
import { Icon } from "../components/Component";
export default function SeachHoempage() {
  return (
    <>
      <div className="container my-5 d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="logo-container mb-3">
              <img src={logo} alt="Logo" />
            </div>

            <div className="input-group google-search-bar mt-3"  style={{width:"30rem"}}>
              <span className="input-group-text bg-white border-0" id="search-addon">
                {/* <Search size={24} className="text-muted" /> */}
                <Icon name="search" className="fs-5"></Icon>
              </span>
              <input
                type="text"
                className="form-control border-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
               
              />
              <div className="" style={{width:"30rem"}}>
                <div className="d-flex">
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
