import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useParams } from "react-router";
import NoResultFound from "../images/EmptyFolder.svg";
import "./css/Qrcode.css";
import { Button, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import FourteenDays from "../images/package_type/FourteenDays";
export default function PDFViewer() {
  const { id } = useParams();
  const [pdfContent, setPdfContent] = useState(null);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalZoom1, setModalZoom1] = useState(false);
  const toggleZoom1 = () => setModalZoom1(!modalZoom1);
  useEffect(() => {
    const fetchPdfContent = async () => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("key", id);
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-filepdf`, {
          method: "POST",
          cache: "no-store",
          body: formData,
          // mode: 'no-cors'
        });
        const responseData = await response.json();
        if (responseData.status) {
          const pdfcontent = responseData.data.pdfContent;
          setPdfContent(pdfcontent);
        } else if (responseData.data.limit) {
          // alert("Limit exceeded");
          setModalZoom1(true);
        } else {
          setError(true);
        }
      } catch (e) {
        console.error(e.message);
        setError(true);
      } finally {
        setLoader(false);
      }
    };
    fetchPdfContent();
  }, [id]);

  // useEffect(() => {
  //   const setScans = async () => {
  //     try {
  //       const url = window.location.href;
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/set-qr-scan`, {
  //         method: "POST",
  //         cache: "no-store",
  //         body: JSON.stringify({
  //           qr_id: url,
  //         }),
  //       });
  //       const responseData = await response.json();
  //       if (responseData.status) {
  //         // console.log(responseData.data);
  //       } else {
  //         setError(true);
  //       }
  //     } catch (e) {
  //       console.error(e.message);
  //     }
  //   };
  //   setScans();
  // }, [id]);

  if (error) {
    return <img src={NoResultFound} alt="No Result Found" />;
  }
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <Modal isOpen={modalZoom1} toggle={toggleZoom1} modalClassName="zoom" size="md" backdrop="static">
        <ModalBody>
          <div className="text-center">
            <FourteenDays />
            <h4 className="customfont mt-1">Limit Exceeded: Renewing Your 14-Day Plan</h4>
            <small className="text-justifys customfont fw-bold">
              Your 14-day scanning plan has ended, preventing PDF scans. To regain full access and continue managing
              documents, renew your plan. Don't miss out on seamless document handling—renew now and resume
              uninterrupted scanning.
            </small>
          </div>
          <div className="text-center mt-1">
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/3`}>
              <Button color="danger">
                <span className="customfont">Upgrade Now</span>
              </Button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      {loader && (
        <>
          <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
            <div className="loader-new"></div>
          </div>
        </>
      )}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          style={{
            height: "100%",
          }}
        >
          {pdfContent && <Viewer fileUrl={pdfContent} plugins={[defaultLayoutPluginInstance]} />}
        </div>
      </Worker>
    </>
  );
}
