import * as React from "react";
const UpgradePackage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    x={0}
    y={0}
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <circle r={16} cx={16} cy={16} fill="#ffebca" shape="circle" />
    <g transform="matrix(0.7,0,0,0.7,4.80000177025795,4.800075101852418)">
      <g fill="#ffb743">
        <path
          d="M2.837 20.977 1.012 9.115c-.135-.876.863-1.474 1.572-.942l5.686 4.264a1.359 1.359 0 0 0 1.945-.333l4.734-7.1c.5-.75 1.602-.75 2.102 0l4.734 7.1a1.359 1.359 0 0 0 1.945.333l5.686-4.264c.71-.532 1.707.066 1.572.942l-1.825 11.862zM27.79 27.559H4.21a1.373 1.373 0 0 1-1.373-1.373v-3.015h26.326v3.015c0 .758-.615 1.373-1.373 1.373z"
          fill="#ffb743"
          opacity={1}
          data-original="#ffb743"
          className=""
        />
      </g>
    </g>
  </svg>
);
export default UpgradePackage;
