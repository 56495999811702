import ProductA from "../../../images/product/a.png";


export const productData = [
  // {
  //   id: 2,
  //   name: "Pink Fitness Tracker",
  //   img: ProductA,
  //   sku: "",
  //   price: "",
  //   stock: "",
  //   category: [
  //     { label: "Fitbit", value: "Fitbit" },
  //     { label: "Tracker", value: "Tracker" },
  //   ],
  //   fav: false,
  //   check: false,
  // },
];

export const categoryOptions = [
  {
    value: "Gadget",
    label: "Gadget",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    label: "Watch",
    value: "Watch",
  },
  {
    label: "Tracker",
    value: "Tracker",
  },
  {
    label: "Fitbit",
    value: "Fitbit",
  },
  {
    label: "Men",
    value: "Men",
  },
  {
    label: "Holder",
    value: "Holder",
  },
  {
    label: "Speaker",
    value: "Speaker",
  },
  {
    label: "Headphones",
    value: "Headphones",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
];
