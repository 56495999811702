import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { QrCodeProvider } from "../pages/QrCodeContext";
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const [authChecking, setAuthChecking] = useState(true);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);
  if (authChecking) return <>Redirecting to authentication</>;

  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
        <QrCodeProvider>
          <Head title={!title && "Loading"} />
          <AppRoot className="npc-apps">
            <AppMain>
              <Sidebar fixed />
              <AppWrap>
                <Header fixed />
                <ToastContainer hideProgressBar autoClose={3000} />
                <Outlet />
                <Footer fixed />
              </AppWrap>
            </AppMain>
          </AppRoot>
        </QrCodeProvider>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
