import React, { useEffect } from "react";
import { Button } from "reactstrap";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Icon } from "../components/Component";
import silocloudblack from "../images/applogos/white_theme/silocloud_white.png"; // path logo image

export default function DownloadPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state || {
    productName: "Default Product",
    store: "Default Store",
    description: "Default Description",
    quantity: "",
    price: "",
    productLink: "",
    applyBorder: false,
    applyText: false,
    additionalText: "",
  };
  const { productName, store, description, quantity, price, productLink, applyBorder, applyText, additionalText } =
    state;

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, [location.state, navigate]);

  const generatePDF = () => {
    const pdf = new jsPDF();
    const qrSize = 25; // Size of each QR code
    const margin = 15; // Margin between QR codes
    let x = margin;
    let y = 40; // Starting position adjusted to start below header
    let pageCount = 1;
    const logoSize = 20;
    const headerHeight = 40;
    const rowsPerPage = 4;
  
    const addPageHeader = () => {
      // header (logo and additional text) to each page
      pdf.addImage(silocloudblack, "PNG", 10, 10, logoSize, logoSize);
      pdf.setFontSize(10);
      pdf.text("generated using qr.silocloud.io", 10, pdf.internal.pageSize.height - 20);
      pdf.text(`Page ${pageCount}`, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 20);
  
      // Horizontal line below the logo
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.1);
      pdf.line(10, 10 + logoSize + 4, pdf.internal.pageSize.width - 10, 10 + logoSize + 4); // position and length
  
      // Horizontal line above footer text and page number
      pdf.line(10, pdf.internal.pageSize.height - 25, pdf.internal.pageSize.width - 10, pdf.internal.pageSize.height - 25); // position and length
    };
  
    const addQrCodes = () => {
      for (let i = 0; i < quantity; i++) {
        const qrCodeURL = document.getElementById(`qrCode${i}`).toDataURL();
        if (applyBorder) {
          pdf.setLineWidth(0.3);
          pdf.setDrawColor(0, 0, 0);
          const textHeight = applyText && additionalText ? 10 : 0;
          pdf.roundedRect(x - 4, y - 4, qrSize + 8, qrSize + 8 + textHeight, 2, 2);
        }
        pdf.addImage(qrCodeURL, "JPEG", x, y, qrSize, qrSize);
  
        if (applyText && additionalText) {
          pdf.setFontSize(14);
          const textWidth = pdf.getTextWidth(additionalText);
          const textX = x + (qrSize - textWidth) / 2; // Center the text below the QR code
          const textY = y + qrSize + 10; // Y position to be inside the border
          pdf.text(additionalText, textX, textY);
        }
  
        x += qrSize + margin;
        if ((i + 1) % 5 === 0) {
          x = margin;
          y += qrSize + margin + (applyText && additionalText ? 15 : 0); //  text spacing
  
          if ((y - headerHeight) / (qrSize + margin + (applyText && additionalText ? 15 : 0)) >= rowsPerPage) {
            pageCount++;
            pdf.addPage();
            y = 50; // Reset y position and space for header
          }
        }
      }
    };
  
    addPageHeader(); // Add header to the first page
    addQrCodes(); // Add QR codes
  
    pdf.save(`${productName}.pdf`);
  };
  
  


  const generateZIP = () => {
    const zip = new JSZip();
    const logoSize = 25;
    const headerHeight = 40;
    const qrSize = 25; // Size of each QR code
    const margin = 15; // Margin between QR codes
    const rowsPerPage = 4;
  
    // Adjust startY to provide enough space between the header and QR codes
    const startY = logoSize + headerHeight; // Adjust as needed
    const startX = 10;
  
    for (let pageNum = 1; pageNum <= Math.ceil(quantity / (rowsPerPage * 5)); pageNum++) {
      const pdf = new jsPDF();
  
      // Horizontal line below the logo
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.1);
      pdf.line(10, 10 + logoSize + 4, pdf.internal.pageSize.width - 10, 10 + logoSize + 4); // position and length
  
      // Calculate which QR codes to include on this page
      const startIndex = (pageNum - 1) * rowsPerPage * 5;
      const endIndex = Math.min(quantity, pageNum * rowsPerPage * 5);
  
      // Add QR codes
      let x = startX;
      let y = startY;
      for (let i = startIndex; i < endIndex; i++) {
        const qrCodeURL = document.getElementById(`qrCode${i}`).toDataURL();
        if (applyBorder) {
          pdf.setLineWidth(0.3);
          pdf.setDrawColor(0, 0, 0);
          const textHeight = applyText && additionalText ? 10 : 0;
          pdf.roundedRect(x - 4, y - 4, qrSize + 8, qrSize + 8 + textHeight, 2, 2);
        }
        pdf.addImage(qrCodeURL, "JPEG", x, y, qrSize, qrSize);
  
        if (applyText && additionalText) {
          pdf.setFontSize(14);
          const textWidth = pdf.getTextWidth(additionalText);
          const textX = x + (qrSize - textWidth) / 2; // Center the text below the QR code
          const textY = y + qrSize + 10; // Y position to be inside the border
          pdf.text(additionalText, textX, textY);
        }
  
        x += qrSize + margin;
        if ((i + 1 - startIndex) % 5 === 0) {
          x = startX;
          y += qrSize + margin + (applyText && additionalText ? 15 : 0); // text spacing
        }
      }
  
      // Add footer and logo
      pdf.addImage(silocloudblack, "PNG", 10, 10, logoSize, logoSize);
      pdf.setFontSize(10);
      pdf.text("generated using QR.Silocloud.io", 10, pdf.internal.pageSize.height - 10);
      pdf.text(`Page ${pageNum}`, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 10);
  
      // Horizontal line below the logo in each PDF
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.1);
      pdf.line(10, 10 + logoSize + 4, pdf.internal.pageSize.width - 10, 10 + logoSize + 4); // position and length
  
      // Horizontal line above footer text and page number in each PDF
      pdf.line(10, pdf.internal.pageSize.height - 15, pdf.internal.pageSize.width - 10, pdf.internal.pageSize.height - 15); // position and length
  
      const pdfBlob = pdf.output("blob");
      zip.file(`${productName}-Page${pageNum}.pdf`, pdfBlob);
    }
  
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${productName}.zip`);
    });
  };
  
  const renderQRCodes = () => {
    const qrCodes = [];
    for (let i = 0; i < quantity; i++) {
      const qrValue =
        productLink ||
        JSON.stringify({
          productName,
          store,
          description,
          price,
        });
      qrCodes.push(<QRCode key={i} id={`qrCode${i}`} value={qrValue} size={180} style={{ display: "none" }} />);
    }
    return qrCodes;
  };

  return (
    <>
      <div className="d-flex justify-content-end m-3">
        <Link to="/sku">
          <Button className="toggle d-none d-md-inline-flex productheadercms" color="primary">
            <Icon name="qr"></Icon>
            <span className="customfont fw-bold ">Create QR</span>
          </Button>
        </Link>
      </div>
      <div className="container card bg-white shadow my-3 w-50">
        <h2 className="d-flex justify-content-center py-3">Thank You</h2>
        <div className="d-flex justify-content-center py-3">
          <Button
            color="transparent"
            className="border-1 border-danger text-danger fw-bold custom-button"
            onClick={generatePDF}
          >
            <span>Download PDF</span>
          </Button>
          <Button
            color="transparent"
            className="border-1 border-danger text-danger fw-bold custom-button ms-3"
            onClick={generateZIP}
          >
            <span>Download ZIP</span>
          </Button>
        </div>
        <div style={{ display: "none" }}>{renderQRCodes()}</div>
      </div>
    </>
  );
}
