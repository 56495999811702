import React, { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

export default function Website() {
  const { id } = useParams();
  const getOriginalUrl = async () => {
    try {
      const formData = new FormData();
      formData.append("short_code", id);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-original-url`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        window.location.href = data.data.original_url;
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getOriginalUrl();
  }, []);
  return (
    <>
      <div></div>
    </>
  );
}
