import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const authToken = Cookies.get("authToken");

      if (!authToken) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const tokenPayload = jwtDecode(authToken);

        const isExpired = tokenPayload.exp * 1000 < Date.now();
        setIsAuthenticated(!isExpired);
      } catch (error) {
        console.error("Failed to decode token:", error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  return isAuthenticated;
};

export default useAuth;
