import React, { useState, useRef, useCallback } from "react";
import { Button, Label, Card } from "reactstrap";
import { Icon } from "../components/Component";
import { QRCode } from "@jackybaby/react-custom-qrcode";
import Barcode from "react-barcode";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { formatFileSize, getCookie } from "../utils/Utils";
import { toPng, toSvg } from "html-to-image";
import debounce from "lodash.debounce";

export default function Amazon() {
  const [productURL, setProductURL] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  // Function to handle input changes and update the state variable
  const isValidHttpsUrl = (url) => {
    const regex = /^(https:\/\/)[^\s/$.?#].[^\s]*$/;
    return regex.test(url);
  };

  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    if (!isValidHttpsUrl(inputValue)) {
      toast.error("Enter a valid URL");
      return false;
    }

    if (inputValue.trim() !== "" && isValidHttpsUrl(inputValue)) {
      try {
        setLoader(true);
        const formData = new FormData();
        formData.append("url", inputValue);
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/short-url-qr`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (data.status) {
          setProductURL(process.env.REACT_APP_PUBLIC_URL + "website/" + data.data.shortened_url);
          setInputValue(process.env.REACT_APP_PUBLIC_URL + "website/" + data.data.shortened_url);
        } else {
          toast.error(data.message);
        }
        // setProductURL(inputValue);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const debouncedHandleInputChange = useCallback(debounce(handleInputChange, 500), []);

  // Determine the value to be used for the barcode
  const barcodeValue = inputValue || "default-barcode";

  const downloadContainerRef = useRef(null);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [activeIconTabText, setActiveIconTabText] = useState("URL");

  const addtocollection = async () => {
    Swal.fire({
      title: "Save as",
      input: "text",
      inputPlaceholder: "Enter your product name",
      inputAttributes: {
        maxlength: 20, // Set your desired maximum length here
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const userInput = result.value;
        const container = downloadContainerRef.current;
        if (!container) {
          console.error("Container not found");
          return;
        }
        try {
          setLoading(true);
          let formData = new FormData();
          const pngDataUrl = await toPng(container);
          formData.append("qrcode_type", "productqr");
          formData.append("base64_data", pngDataUrl);
          formData.append("qrcode_data", inputValue);
          formData.append("qr_name", userInput);
          console.log();
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/add-qr`, {
            headers: {
              authToken: getCookie("authToken"),
            },
            method: "POST",
            body: formData,
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const responseData = await response.json();
          if (responseData.status) {
            toast.success(responseData.message);
            navigate("/products");
          } else {
            // toast.error("Something went wrong!");
            console.error(responseData.message);
            toast.error(responseData.message);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <div className="row my-5">
        <div className=" col-lg-6">
          <div className="shadow p-4 bg-white rounded h-100">
            <Label htmlFor="namecontact" className="form-label">
              Product URL
            </Label>
            {/* <div className="form-control-wrap">
                <input
                  className="form-control"
                  type="text"
                  id="lastnamecontact"
                  placeholder="Enter Product URL"
                  value={productURL}
                  onChange={handleInputChange}
                />
              </div> */}
            <div className="form-control-wrap">
              {loader && (
                <div className="form-icon form-icon-right">
                  <Icon name="loader" className="input-loader" />
                </div>
              )}
              <input
                className="form-control"
                type="text"
                id="lastnamecontact"
                placeholder="Enter Product URL"
                // value={inputValue}
                onChange={debouncedHandleInputChange}
                autoComplete="off"
              />
              {/* <span className="form-note text-success">Specify the name of your website.</span> */}
            </div>

            {/* <div className="d-flex justify-content-center">
              <Button color="transperent" className="border-1 border-danger text-danger fw-bold custom-button">
                <span>Generate QR</span>
              </Button>
            </div> */}
          </div>
        </div>

        <div className="col-lg-6">
          <div className=" shadow p-3 bg-white rounded">
            <div className="text-center d-flex justify-content-center align-items-center p-3">
              <div ref={downloadContainerRef}>
                <QRCode value={productURL} size={200} />
              </div>
            </div>

            <div className="d-flex justify-content-center py-3 ">
              <Button
                color="primary"
                outline
                className="d-sm-inline-flex bg-primary p-2 fs-6 text-white headerbuttoncms rounded"
                size="sm"
                onClick={addtocollection}
              >
                <Icon name="save"></Icon>
                <span className="">Add To Products</span>
              </Button>
            </div>

            {/* <div className="d-flex justify-content-center my-5">
          <Barcode value={barcodeValue} />
        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
