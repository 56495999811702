import siloCoin from "../../images/applogos/coin_d.png";

export const linkItems = [
  {
    link: `https://silocloud.com/enumblockchain-explorer`,
    img: siloCoin,
    text: "BlockChain",
    oldsite:true,
  },
  {
    link: `https://silocloud.com/wallet-dashboard`,
    icon: "wallet",
    text: "Silo Wallet",
  },
  {
    link: `${process.env.REACT_APP_ACCOUNT_URL}Account Setting`,
    icon: "setting-alt",
    text: "Account Settings",
  },
];
