import React, { useState, createContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookie } from "../utils/Utils";

export const SubscriptionContext = createContext();

export const SubscriptionContextProvider = (props) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [apidata, setApidata] = useState();
  const checkSubscription = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/get-qr-subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken", null),
      },
    });
    const data = await response.json();
    setApidata(data);
    const subscriptions = JSON.parse(data.data.subscription.service_plan_data);
    const limit = data.data.upgradeModel;
    // console.log(data);
    setSubscription(subscriptions);
  };

  useEffect(() => {
    checkSubscription();
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        contextData: [subscription, setSubscription],
        apiData: [apidata, setApidata],
      }}
    >
      <Outlet />
    </SubscriptionContext.Provider>
  );
};
