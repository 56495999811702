import React, { useRef, useState } from "react";
import Existingproduct from "./Existingproduct";
import Newproduct from "./Newproduct";
import { GenericQrCode } from "./GenericQrCode";
import { Card, Button } from "reactstrap";
import { QRCode } from "@jackybaby/react-custom-qrcode";
import Barcode from "react-barcode";
import { Icon } from "../components/Component";
import { toPng } from "html-to-image";
import { getCookie } from "../utils/Utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
const QrComponent = ({ qrCodeData, barcodeValue, existingproduct, existingprice, existingstock }) => {
  const [loading, setLoading] = useState(false);
  const downloadContainerRef = useRef(null);
  const navigate = useNavigate();
  const addtocollection = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      const container = downloadContainerRef.current;
      const pngDataUrl = await toPng(container);
      formData.append("qrcode_type", "siloproductqr");
      formData.append("base64_data", pngDataUrl);
      formData.append("qrcode_data", qrCodeData);
      formData.append("qr_name", existingproduct);
      formData.append("product_price", existingprice);
      formData.append("product_stock", existingstock);
      console.log();
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/qr-code/add-qr`, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseData = await response.json();
      if (responseData.status) {
        toast.success(responseData.message);
        navigate("/products");
      } else {
        // toast.error("Something went wrong!");
        console.error(responseData.message);
        toast.error(responseData.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="shadow p-3 h-100 bg-white rounded ">
      <div className="QrCodeDiv text-center d-flex justify-content-center align-items-center">
        <div className="p-3">
          <div ref={downloadContainerRef}>
            <QRCode value={qrCodeData ? qrCodeData : "Silo Market Place"} size={200} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center py-3 ">
        <Button
          color="primary"
          outline
          className="d-sm-inline-flex bg-primary p-2 fs-6 text-white headerbuttoncms rounded"
          size="sm"
          onClick={addtocollection}
        >
          <Icon name="save"></Icon>
          <span className="">Add To Collections</span>
        </Button>
      </div>

      {/* <div className="d-flex justify-content-center my-5">
          <Barcode value={barcodeValue} />
        </div> */}
    </div>
  );
};
export default function Silomarketplace() {
  const [toggle, setToggle] = useState(1);
  const [qrText, setQrText] = useState("");
  const [existingText, setExistingtext] = useState("");
  const [BarCodeText, setBarCodeText] = useState("default-barcode");
  const [priceText, setPriceText] = useState("");
  const [stock, setStock] = useState("");
  console.log(toggle);
  return (
    <>
      <div className="my-5 h-100">
        <GenericQrCode
          setToggle={setToggle}
          FormComponent={
            toggle == 1 ? (
              <Existingproduct
                barCodeText={(text) => setBarCodeText(text)}
                qrText={(text) => setQrText(text)}
                existingproductname={(text) => setExistingtext(text)}
                priceText={(text) => setPriceText(text)}
                stockText={(text) => setStock(text)}
              />
            ) : (
              <Newproduct barCodeText={(text) => setBarCodeText(text)} qrText={(text) => setQrText(text)} />
            )
          }
          qrText={qrText}
          BarCodeText={BarCodeText}
          CommonQrComponent={
            <QrComponent
              qrCodeData={qrText}
              barcodeValue={BarCodeText}
              existingproduct={existingText}
              existingprice={priceText}
              existingstock={stock}
            />
          }
        />
      </div>
    </>
  );
}
