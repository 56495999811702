import React, { useState} from "react";
import { Button, Label, Card } from "reactstrap";
import Dropzone from "react-dropzone";

export default function Newproduct({ barCodeText, qrText }) {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [ProductQuantity, setProductQuantity] = useState("");
  const [inputValue, setInputValue] = useState("");
  // Function to handle input changes and update state variables
  const handleInputChange = (event, setState) => {
    setState(event.target.value);
    setInputValue(event.target.value);
    barCodeText(event.target.value);
    qrText(`Product Name: ${productName}\nDescription: ${productDescription}\nPrice: ${productPrice}`);
  };

  // Combine product information into a single string for QR code generation
  const qrCodeData = `Product Name: ${productName}\nDescription: ${productDescription}\nPrice: ${productPrice}`;

  // Determine the value to be used for the barcode
  const barcodeValue = inputValue || "default-barcode";

  const [files1, setFiles1] = useState([]);
  
  const [files2, setFiles2] = useState([]);

  const handleDropChange = (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  return (
    <>
      <div className="p-4">
        <div className="form-group">
          <div>
            <Label htmlFor="namecontact" className="form-label">
              Product Name
            </Label>
            <div className="form-control-wrap">
              <input
                className="form-control"
                type="text"
                id="lastnamecontact"
                placeholder="Enter Product name"
                value={productName}
                onChange={(e) => handleInputChange(e, setProductName)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Label htmlFor="namecontact" className="form-label">
              Product Price
            </Label>
            <div className="form-control-wrap">
              <input
                className="form-control"
                type="text"
                id="lastnamecontact"
                placeholder="Enter Product price"
                value={productPrice}
                onChange={(e) => handleInputChange(e, setProductPrice)}
              />
            </div>
          </div>
          <div className="col-6">
            <Label htmlFor="namecontact" className="form-label">
              Discount
            </Label>
            <div className="form-control-wrap">
              <input
                className="form-control"
                type="text"
                id="lastnamecontact"
                placeholder="Enter Product Discount %"
                value={ProductQuantity}
                onChange={(e) => handleInputChange(e, setProductQuantity)}
              />
            </div>
          </div>
        </div>
        <div className="form-group my-2">
          <Label htmlFor="phonecontact" className="form-label">
            Product Description
          </Label>
          <div className="form-control-wrap">
            <textarea
              className="form-control"
              placeholder="Enter product Description"
              value={productDescription}
              onChange={(e) => handleInputChange(e, setProductDescription)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 sm=6">
            <label className="form-label">Image</label>
            <Dropzone
              onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles1)}
              accept={[".jpg", ".png", ".svg"]}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                    <input {...getInputProps()} />
                    {files1.length === 0 && (
                      <div className="dz-message">
                        <span className="dz-message-text">Drag and drop file</span>
                        <span className="dz-message-or">or</span>
                        <Button color="primary">SELECT</Button>
                      </div>
                    )}
                    {files1.map((file) => (
                      <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                        <div className="dz-image">
                          <img src={file.preview} alt="preview" />
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className="col-6 sm=6">
            <label className="form-label">Thumbnail</label>
            <Dropzone
              onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles2)}
              accept={[".jpg", ".png", ".svg"]}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                    <input {...getInputProps()} />
                    {files2.length === 0 && (
                      <div className="dz-message">
                        <span className="dz-message-text">Drag and drop file</span>
                        <span className="dz-message-or">or</span>
                        <Button color="primary">SELECT</Button>
                      </div>
                    )}
                    {files2.map((file) => (
                      <div key={file.name} className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                        <div className="dz-image">
                          <img src={file.preview} alt="preview" />
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        <div className="d-flex justify-content-center my-4">
          <Button color="transperent" className="border-1 border-danger text-danger fw-bold custom-button">
            <span>Generate QR</span>
          </Button>
        </div>
      </div>
    </>
  );
}
