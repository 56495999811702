import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Label } from "reactstrap";
import { UserAvatar } from "../components/Component";
import debounce from "lodash/debounce";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export default function Amazon({ qrText, barCodeText, existingproductname, priceText, stockText }) {
  const [productURL, setProductURL] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [products, setProducts] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const downloadContainerRef = useRef(null);
  // Function to handle input changes and update the state variable
  // const handleInputChange = (event) => {
  //   setProductURL(event.target.value);
  //   qrText(event.target.value);
  //   barCodeText(event.target.value);
  //   setInputValue(event.target.value);
  // };

  // Determine the value to be used for the barcode
  const barcodeValue = inputValue || "default-barcode";
  const fetchProduct = async (query) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_MARKETPLACE}api/v1/marketplace/get-products?search=${query}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      if (data.status && data.data.products && data.type !== "error") {
        setProducts(data.data.products);
        setError(data.length === 0 ? "No Results Found" : null);
      } else {
        setError("No Products Found");
      }
    } catch (error) {
      console.error(error);
      setError("Error fetching products");
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchProducts = useCallback(debounce(fetchProduct, 300), []);

  useEffect(() => {
    if (productURL) {
      setShowResults(true);
      debouncedFetchProducts(productURL);
    } else {
      setShowResults(false);
      setProducts([]);
    }
  }, [productURL, debouncedFetchProducts]);

  const handleInputChange = (e) => {
    setProductURL(e.target.value);
  };
  const generateExistingQr = (id, name, price, stock) => {
    const productId = id;
    const productName = encodeURI(name);
    const url = `https://store.silocloud.io/${productId}/${productName}`;
    qrText(url);
    setProductURL(name);
    existingproductname(name);
    setShowResults(false);
    priceText(price);
    stockText(stock);
  };
  return (
    <>
      <div className=" p-4 " style={{ height: "266px" }}>
        <div className="">
          <Label htmlFor="namecontact" className="form-label">
            Search Product
          </Label>
          <div className="form-control-wrap">
            <input
              className="form-control"
              type="text"
              autoComplete="off"
              id="lastnamecontact"
              placeholder="Enter Product Name"
              value={productURL}
              onChange={handleInputChange}
            />
            {showResults && (
              <div
                className="bg-white shadow border d-flex flex-column searchbox-content"
                style={{
                  borderBottomLeftRadius: "0.625rem",
                  borderBottomRightRadius: "0.625rem",
                  gap: "0.625rem",
                }}
              >
                <div
                  className="mt-2 d-flex flex-column cursor-pointer searchbox-content-wrapper"
                  style={{
                    gap: "0.925rem",
                    paddingBottom: "1rem",
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "auto",
                  }}
                >
                  {loading ? (
                    <div className="px-2">
                      <div className="row">
                        <div className="align-items-center col-md-2 d-flex justify-content-end">
                          <UserAvatar theme="dark" className="sq shadow p-1 md" />
                        </div>
                        <div className="col-md-8 d-flex flex-start fw-bold customfont flex-column">
                          <p className="m-0">
                            <Skeleton width="75%" />
                          </p>
                          <p className="m-0">
                            <Skeleton width="25%" />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : error ? (
                    <div className="px-2 text-center text-danger">{error}</div>
                  ) : (
                    products.map((product) => (
                      <div
                        key={product.id}
                        className="px-2"
                        onClick={() =>
                          generateExistingQr(product.id, product.product_name, product.price, product.stock)
                        }
                      >
                        <div className="row">
                          <div className="align-items-center col-md-2 d-flex justify-content-end">
                            <UserAvatar
                              theme="dark"
                              className="sq shadow md product_images"
                              image={`${product.thumbnail}`}
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-start fw-bold customfont flex-column">
                            <p className="m-0">{product.product_name}</p>
                            <p className="m-0">
                              <Badge color="secondary">{product.brand_name}</Badge>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="d-flex justify-content-center my-4">
          <Button color="transperent" className="border-1 border-danger text-danger fw-bold custom-button">
            <span>Generate QR</span>
          </Button>
        </div> */}
      </div>
    </>
  );
}
