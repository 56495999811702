import * as React from "react";
const FileUploads = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={70}
    height={70}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path
        fill="#8a96bf"
        d="M82.495 66.679h301.866c8.729 0 15.815 7.087 15.815 15.815v402.69c0 8.729-7.087 15.816-15.816 15.816H82.495c-8.729 0-15.815-7.087-15.815-15.815V82.495c0-8.729 7.087-15.816 15.816-15.816z"
        opacity={1}
        data-original="#8a96bf"
        className=""
      />
      <g fillRule="evenodd">
        <path
          fill="#a8b4db"
          d="M66.679 485.185V164.698c0-6.805 2.426-12.661 7.237-17.472l73.31-73.309c4.812-4.812 10.668-7.238 17.472-7.238H384.36c5.508 0 10 4.492 10 10v406.505c0 6.609-5.391 12-12 12H76.679c-5.506 0-10-4.492-10-10z"
          opacity={1}
          data-original="#a8b4db"
          className=""
        />
        <path
          fill="#8a96bf"
          d="M11 429.506V109.019c0-6.805 2.426-12.661 7.237-17.472l73.31-73.31C96.359 13.425 102.215 11 109.019 11h219.662c8.711 0 15.816 7.104 15.816 15.815v402.691c0 8.711-7.105 15.815-15.816 15.815H26.816c-8.711 0-15.816-7.105-15.816-15.815z"
          opacity={1}
          data-original="#8a96bf"
          className=""
        />
        <path
          fill="#d2dbfa"
          d="M11 429.506V109.019c0-6.805 2.426-12.661 7.237-17.472l73.31-73.31C96.359 13.425 102.215 11 109.019 11h219.662c5.508 0 10 4.492 10 10v406.505c0 6.609-5.391 12-12 12H21c-5.506 0-10-4.492-10-10z"
          opacity={1}
          data-original="#d2dbfa"
          className=""
        />
        <path
          fill="#8a96bf"
          d="M91.547 18.237C96.359 13.425 102.215 11 109.019 11h6.272c-8.989 3.708-15.359 12.579-15.359 22.867V84.12c0 8.709-7.103 15.813-15.812 15.813H33.867c-10.286 0-19.158 6.37-22.867 15.359v-6.272c0-6.805 2.426-12.661 7.237-17.472z"
          opacity={1}
          data-original="#8a96bf"
          className=""
        />
        <path
          fill="#a8b4db"
          d="M91.547 18.237C96.359 13.425 102.215 11 109.019 11h6.272c-7.646 1.416-20.757 8.731-20.757 22.867V84.12c0 5.73-4.685 10.414-10.414 10.414H33.867c-6.807 0-18.704 2.786-22.867 20.757v-6.272c0-6.805 2.426-12.661 7.237-17.472z"
          opacity={1}
          data-original="#a8b4db"
          className=""
        />
        <path
          fill="#8a96bf"
          d="M61.459 146.011a8 8 0 0 1 0-16h133.21a8 8 0 0 1 0 16zm81.75-44.476a8 8 0 0 1 0-16h69.552a8 8 0 0 1 0 16zm93.281 0a8 8 0 0 1 0-16h10.306a8 8 0 0 1 0 16zm34.034 0a8 8 0 0 1 0-16h23.514a8 8 0 0 1 0 16zM61.459 370.785a8 8 0 0 1 0-16h25.789a8 8 0 0 1 0 16zm49.518 0a8 8 0 0 1 0-16h62.124a8 8 0 0 1 0 16zM61.459 325.83a8 8 0 0 1 0-16h65.298a8 8 0 0 1 0 16zm89.026 0a8 8 0 0 1 0-16h30.678a8 8 0 0 1 0 16zm-89.026-44.956a8 8 0 0 1 0-16h50.802a8 8 0 0 1 0 16zm74.531 0a8 8 0 0 1 0-16h10.306a8 8 0 0 1 0 16zm34.034 0a8 8 0 0 1 0-16h29.889a8 8 0 0 1 0 16zM61.46 235.919a8 8 0 0 1 0-16h25.789a8 8 0 0 1 0 16zm49.518 0a8 8 0 0 1 0-16h118.561a8 8 0 0 1 0 16H110.977zM61.46 190.964a8 8 0 0 1 0-16h185.297a8 8 0 0 1 0 16H61.459zm209.026 0a8 8 0 0 1 0-16h23.553a8 8 0 0 1 0 16zm-52.088-44.955a8 8 0 0 1 0-16h.452a8 8 0 0 1 0 16zm24.181 0a8 8 0 0 1 0-16h51.46a8 8 0 0 1 0 16z"
          opacity={1}
          data-original="#8a96bf"
          className=""
        />
      </g>
      <circle
        cx={363.085}
        cy={363.085}
        r={137.915}
        fill="#de1149"
        transform="rotate(-45 363.046 363.179)"
        opacity={1}
        data-original="#3254d1"
        className=""
      />
      <path
        fill="#de1149"
        fillRule="evenodd"
        d="M291.806 481.172c-39.938-24.159-66.636-68.005-66.636-118.087s26.698-93.928 66.636-118.087c19.531-10.944 42.049-17.192 66.029-17.192 74.713 0 135.28 60.567 135.28 135.28s-60.567 135.279-135.28 135.279c-23.98 0-46.498-6.248-66.029-17.192z"
        opacity={1}
        data-original="#476ef7"
        className=""
      />
      <path
        fill="#edeff3"
        fillRule="evenodd"
        d="M307.119 383.183v21.671c0 6.62 5.38 12 12.002 12h87.929c6.622 0 12.002-5.38 12.002-12v-21.671c0-4.41 3.578-8 8.001-8s7.996 3.589 7.996 8v21.671c0 15.439-12.561 28-27.999 28h-87.929c-15.438 0-27.999-12.561-27.999-28v-21.671c0-4.41 3.578-8 8.001-8s7.996 3.589 7.996 8zm55.964 12.9a7.996 7.996 0 0 0 7.996-8v-67.45l8.482 8.479c1.561 1.561 3.608 2.341 5.66 2.341s4.089-.78 5.66-2.341a8.01 8.01 0 0 0 0-11.318l-22.143-22.131a7.98 7.98 0 0 0-11.305 0l-22.143 22.131a8.01 8.01 0 0 0 0 11.318 8.014 8.014 0 0 0 11.319 0l8.472-8.479v67.45c0 4.42 3.588 8 8.001 8z"
        opacity={1}
        data-original="#edeff3"
        className=""
      />
    </g>
  </svg>
);
export default FileUploads;
